import {
  FETCH_COST_TYPES_REQUEST,
  FETCH_COST_TYPES_SUCCESS,
  FETCH_COST_TYPES_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  costTypes: [],
  error: '',
}, action) => {
  switch (action.type) {
    case FETCH_COST_TYPES_REQUEST:
      return state;
    case FETCH_COST_TYPES_SUCCESS:
      return Object.assign({}, state, {
        costTypes: [...action.payload],
      });
    case FETCH_COST_TYPES_FAILURE:
      return Object.assign({}, state, {
        error: action.payload,
      });
    default:
      return state;
  }
}