import React from 'react';
import {
  FlatList,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';

class OfficesContainer extends React.Component {
  render() {
    const { offices } = this.props;

    if (!offices.items.length) {
      return null;
    }

    const listData = offices.items.map((item, index) => {
      return {
        key: index,
        id: item.id,
        title: item.title ? item.title.rendered : '',
        subtitle: item.street,
      }
    });

    return (
      <FlatList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => `${item.key}`}
        data={listData}
        renderItem={this._renderItem}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={item.title}
        subtitle={item.subtitle}
        onPress={this._onPress(item.id, item.title)}
      />
    );
  }

  _onPress = (id, title) => () => {
    this.props.navigation.push('Office', { id, title });
  }
}

const mapState = state => {
  const { offices } = state;

  return {
    loading: offices.fetching,
    fetchError: offices.fetchError,
    offices,
  };
};

export default compose(
  connect(mapState),
  withLoader,
  withFetchError
)(OfficesContainer);
