import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import TabBarLabel from '../components/TabBarLabel';
import HomeScreen from '../screens/HomeScreen';
import TimeReportScreen from '../screens/TimeReportScreen';
import ReportTimeScreen from '../screens/ReportTimeScreen';
import OngoingJobsScreen from '../screens/OngoingJobsScreen';
import OngoingJobScreen from '../screens/OngoingJobScreen';
import JobsScreen from '../screens/JobsScreen';
import JobScreen from '../screens/JobScreen';
import JobProfileServicesScreen from '../screens/JobProfileServicesScreen';
import MessagesScreen from '../screens/MessagesScreen';
import MessageScreen from '../screens/MessageScreen';
import ProfileScreen from '../screens/ProfileScreen';
import PayslipsScreen from '../screens/PayslipsScreen';
import OfficeScreen from '../screens/OfficeScreen';
import OfficesScreen from '../screens/OfficesScreen';
import JobProfileScreen from '../screens/JobProfileScreen';
import JobProfileVehiclesScreen from '../screens/JobProfileVehiclesScreen';
import JobProfileTagGroupScreen from '../screens/JobProfileTagGroupScreen';
import ContactDetailsScreen from '../screens/ContactDetailsScreen';
import NoteInputScreen from '../screens/NoteInputScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import LogOutScreen from '../screens/LogOutScreen';
import InformationPagesScreen from '../screens/InformationPagesScreen';
import InformationPageScreen from '../screens/InformationPageScreen';
import TabBarIconContainer from '../containers/TabBarIconContainer';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import NavigationHeaderStyle from '../constants/NavigationHeaderStyle';

const BottomTabStack = createBottomTabNavigator();

const HomeStack = createStackNavigator();
const JobStack = createStackNavigator();
const OngoingJobStack = createStackNavigator();
const MessagesStack = createStackNavigator();
const ProfileStack = createStackNavigator();

function HomeStackNavigation() {
  return (
    <HomeStack.Navigator
      screenOptions={{...NavigationHeaderStyle}}
    >
      <HomeStack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Hem',
        }}
      />
    </HomeStack.Navigator>
  )
}

function JobStackNavigation() {

  return (
    <JobStack.Navigator
      screenOptions={{...NavigationHeaderStyle}}
    >
      <JobStack.Screen
        name="Jobs"
        component={JobsScreen}
        options={{
          title: 'Uppdrag',
        }}
      />
      <JobStack.Screen
        name="Job"
        component={JobScreen}
        options={({ route }) => {
          let title = route.params?.title ?? '';

          if (title.length > 14) {
            title = 'Uppdrag';
          }
      
          return {
            title,
          };
        }}
      />
    </JobStack.Navigator>
  )
}

function OngoingJobStackNavigation() {

  return (
    <OngoingJobStack.Navigator
      screenOptions={{...NavigationHeaderStyle}}
    >
      <OngoingJobStack.Screen
        name="OngoingJobs"
        component={OngoingJobsScreen}
        options={{ title: 'Mina uppdrag' }}
      />
      <OngoingJobStack.Screen
        name="OngoingJob"
        component={OngoingJobScreen}
        options={({ route }) => ({ title: route.params?.title ?? ''})}
      />
      <OngoingJobStack.Screen
        name="ReportTime"
        component={ReportTimeScreen}
        options={{ title: 'Rapportera tid' }}
      />
      <OngoingJobStack.Screen
        name="TimeReport"
        component={TimeReportScreen}
        options={{ title: 'Tidrapport' }}
      />
    </OngoingJobStack.Navigator>
  )
}

function MessagesStackNavigation() {

  return (
    <MessagesStack.Navigator
      screenOptions={{...NavigationHeaderStyle}}
    >
      <MessagesStack.Screen
        name="Messages"
        component={MessagesScreen}
        options={{ title: 'Inkorg' }}
      />
      <MessagesStack.Screen
        name="Message"
        component={MessageScreen}
        options={({ route }) => {
          let title = route.params?.title ?? '';
      
          if (title.length > 14) {
            title = 'Meddelande';
          }
      
          return {
            title,
          };
        }}
      />
    </MessagesStack.Navigator>
  )
}

function ProfileStackNavigation() {

  return (
    <ProfileStack.Navigator
      screenOptions={{...NavigationHeaderStyle}}
    >
      <ProfileStack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ title: 'Min profil' }}
      />
      <ProfileStack.Screen
        name="Payslips"
        component={PayslipsScreen}
        options={{ title: 'Lönespecifikationer' }}
      />
      <ProfileStack.Screen
        name="Office"
        component={OfficeScreen}
        options={({ route }) => {
          let title = route.params?.title ?? '';
      
          if (title.length > 14) {
            title = 'Lokalkontor';
          }
      
          return {
            title,
          };
        }}
      />
      <ProfileStack.Screen
        name="Offices"
        component={OfficesScreen}
        options={{ title: 'Mina lokalkontor' }}
      />
      <ProfileStack.Screen
        name="JobProfile"
        component={JobProfileScreen}
        options={{ title: 'Min jobbprofil' }}
      />
      <ProfileStack.Screen
        name="JobProfileServices"
        component={JobProfileServicesScreen}
        options={{ title: 'Mina tjänster' }}
      />
      <ProfileStack.Screen
        name="JobProfileVehicles"
        component={JobProfileVehiclesScreen}
        options={{ title: 'Fordon' }}
      />
      <ProfileStack.Screen
        name="JobProfileTagGroup"
        component={JobProfileTagGroupScreen}
        options={({ route }) => {
          let title = route.params?.title ?? '';
      
          // if (title.length > 14) {
          //   title = '';
          // }
      
          return {
            title,
          };
        }}
      />
      <ProfileStack.Screen
        name="ContactDetails"
        component={ContactDetailsScreen}
        options={{ title: 'Kontaktuppgifter' }}
      />
      <ProfileStack.Screen
        name="NoteInput"
        component={NoteInputScreen}
        options={{ title: 'Frånvaro/Ledighet' }}
      />
      { Platform.OS !== 'web' && <ProfileStack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{ title: 'Notiser' }}
      /> }
      <ProfileStack.Screen
        name="InformationPages"
        component={InformationPagesScreen}
        options={{ title: "Information och arbetsmiljö" }}
      />
      <ProfileStack.Screen
        name="InformationPage"
        component={InformationPageScreen}
        options={{ title: 'Informationssida' }}
      />
      <ProfileStack.Screen
        name="LogOut"
        component={LogOutScreen}
        options={{ title: 'Logga ut' }}
      />
    </ProfileStack.Navigator>
  )
}

export default function BottomTabNavigator() {
  return (
    <BottomTabStack.Navigator
      tabBarOptions={{
        adaptive: false,
      }}
    >
      <BottomTabStack.Screen 
        name="Home" 
        component={HomeStackNavigation}
        options={{
          tabBarLabel: ({ focused }) => <TabBarLabel title='Hem' focused={focused} />,
          tabBarIcon: ({ focused, horizontal }) => (
            <TabBarIconContainer
              focused={focused}
              horizontal={horizontal}
              name={Platform.OS === 'ios' ? 'ios-home' : 'md-home'}
              size={26}
            />
          ),
        }}
      />
      <BottomTabStack.Screen 
        name="Jobs" 
        component={JobStackNavigation}
        options={{
          tabBarLabel: ({ focused }) => <TabBarLabel title='Sök uppdrag' focused={focused} />,
          tabBarIcon: ({ focused, horizontal }) => (
            <TabBarIconContainer
              focused={focused}
              horizontal={horizontal}
              name={Platform.OS === 'ios' ? 'ios-briefcase' : 'md-briefcase'}
              size={26}
              notificationResourceType={NotificationResourceTypes.Job}
            />
          ),
        }}
      />
      <BottomTabStack.Screen 
        name="OngoingJobs" 
        component={OngoingJobStackNavigation}
        options={{
          tabBarLabel: ({ focused }) => <TabBarLabel title='Mina uppdrag' focused={focused} />,
          tabBarIcon: ({ focused, horizontal }) => (
            <TabBarIconContainer
              focused={focused}
              horizontal={horizontal}
              name={Platform.OS === 'ios' ? 'ios-time' : 'md-time'}
              size={26}
              notificationResourceType={NotificationResourceTypes.Workorder}
            />
          ),
        }}
      />
      <BottomTabStack.Screen 
        name="Messages" 
        component={MessagesStackNavigation}
        options={{
          tabBarLabel: ({ focused }) => <TabBarLabel title='Inkorg' focused={focused} />,
          tabBarIcon: ({ focused, horizontal }) => (
            <TabBarIconContainer focused={focused}
              horizontal={horizontal}
              name={Platform.OS === 'ios' ? 'ios-mail' : 'md-mail'}
              size={Platform.OS === 'ios' ? 28 : 26}
              notificationResourceType={NotificationResourceTypes.Message}
            />
          ),
        }}
      />
      <BottomTabStack.Screen 
        name="Profile" 
        component={ProfileStackNavigation}
        options={{
          tabBarLabel: ({ focused }) => <TabBarLabel title='Min profil' focused={focused} />,
          tabBarIcon: ({ focused, horizontal }) => (
            <TabBarIconContainer
              focused={focused}
              horizontal={horizontal}
              name={Platform.OS === 'ios' ? 'ios-person' : 'md-person'}
              size={Platform.OS === 'ios' ? 28 : 26}
              notificationResourceType={NotificationResourceTypes.Payslip}
            />
          ),
        }}
      />
    </BottomTabStack.Navigator>
  )
}
