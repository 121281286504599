import {
  BANKID_APP_LAUNCHED,
  BANKID_APP_RETURNED,
  BANKID_APP_FINISHED,
} from '../constants/ActionTypes';

export default (state = {
  launched: false,
  returned: false,
}, action) => {
  switch (action.type) {
    case BANKID_APP_LAUNCHED:
      return Object.assign({}, state, {
        launched: true,
        returned: false,
      });
    case BANKID_APP_RETURNED:
      return Object.assign({}, state, {
        returned: true,
      });
    case BANKID_APP_FINISHED:
      return Object.assign({}, state, {
        returned: false,
        launched: false,
      });
    default:
      return state;
  }
}
