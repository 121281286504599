import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/root';

export default (preloadedState) => {
  const store = createStore(rootReducer, preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
      ),
    )
  );

  return store;
};
