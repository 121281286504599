import React from 'react';
import { View, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

type Props = {
  checked: boolean;
}

export default (props: Props) => {
  const { checked } = props;
  return (
    <View style={ styles.radioOuter }>
      { !!checked &&
        <View style={ styles.radioInner } ></View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  radioOuter: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    borderColor: Colors.border,
    borderWidth: 1,
  },
  radioInner: {
    backgroundColor: Colors.primaryBackground,
    width: 14,
    height: 14,
    borderRadius: 7,
  },
})