import React from 'react';
import { KeyboardAvoidingView, Platform } from "react-native";

export default function Container({ children, behaviour }) {
  if (Platform.OS === 'web') {
    return children;
  } else {
    return (
      <KeyboardAvoidingView behavior={ behaviour }>
        { children }
      </KeyboardAvoidingView>
    )
  }
}