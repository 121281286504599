import {
  GET_PUSH_TOKEN_REQUEST,
  GET_PUSH_TOKEN_SUCCESS,
  GET_PUSH_TOKEN_FAILURE,
  UPDATE_PUSH_TOKEN_REQUEST,
  UPDATE_PUSH_TOKEN_SUCCESS,
  UPDATE_PUSH_TOKEN_FAILURE,
  CLEAR_PUSH_TOKEN,
} from '../constants/ActionTypes';

export default (state = {
  asked: false,
  granted: false,
  token: null,
  updated: false,
  updating: false,
}, action) => {
  switch (action.type) {
    case GET_PUSH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        asked: true,
        granted: false,
        updated: false,
      });
    case GET_PUSH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        granted: true,
        token: action.payload.token,
        updated: false,
      });
    case GET_PUSH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        granted: false,
        updated: false,
      });
    case UPDATE_PUSH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        updating: true,
        updated: false,
      });
    case UPDATE_PUSH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        updated: true,
      });
    case UPDATE_PUSH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        updated: false,
      });
    case CLEAR_PUSH_TOKEN:
      return Object.assign({}, state, {
        granted: false,
        token: null,
        updated: false,
      });
    default:
      return state;
  }
}
