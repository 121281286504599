import * as Notifications from 'expo-notifications';
import {
  Platform,
} from 'react-native';

import {
  GET_PUSH_BADGE_NUMBER,
  EDIT_PUSH_BADGE_NUMBER,
} from '../constants/ActionTypes';

const getPushBadgeNumber = number => {
  return {
    type: GET_PUSH_BADGE_NUMBER,
    payload: {
      number,
    },
  };
};

const editPushBadgeNumber = number => {
  return {
    type: EDIT_PUSH_BADGE_NUMBER,
    payload: {
      number,
    },
  };
};

export const getBadgeNumber = () => async dispatch => {
  const number = await Notifications.getBadgeCountAsync();
  dispatch(getPushBadgeNumber(number));
};

export const setBadgeNumber = number => async dispatch => {
  if (Platform.OS === 'ios') {
    await Notifications.setBadgeCountAsync(number);
  }

  dispatch(editPushBadgeNumber(number))
};
