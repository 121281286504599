import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../components/Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

type Props = {
  id: number;
  title: string;
  subTitle: string;
}

const JobDisplay =  ({ id, title, subTitle }: Props) => (
  <View style={ styles.content }>
    <Text style={ styles.id }>
      { id }
    </Text>
    <Text style={ styles.title }>
      { title }
    </Text>
    <Text style={ styles.subTitle }>
      { subTitle }
    </Text>
  </View>
);

export default JobDisplay;

const styles = StyleSheet.create({
  content: {
    marginBottom: 24,
  },
  id: {
    fontFamily: Fonts.medium,
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
    marginBottom: 8,
  },
  subTitle: {
    color: Colors.secondaryText,
  },
});