import React from 'react';

import ProfileContainer from '../containers/ProfileContainer';
import ScreenView from '../components/ScreenView';

export default class ProfileScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <ProfileContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
