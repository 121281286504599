import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Text from './Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export default ({ image, name, employeeNumber }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text adjustsFontSizeToFit numberOfLines={1} style={styles.name}>{name}</Text>
        <Text style={styles.employeeNumber}>Anställningsnummer: {employeeNumber}</Text>
      </View>
      {image &&
        <View style={styles.rightContent}>
          <Image
            style={styles.image}
            source={{ uri: image }}
          />
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 18,
  },
  name: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
  },
  employeeNumber: {
    color: Colors.secondaryText,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 60/2,
  },
  content: {
    flex: 1,
  },
  rightContent: {
    alignItems: 'flex-end',
  },
});
