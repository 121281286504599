import React from 'react';

import JobProfileVehiclesContainer from '../containers/JobProfileVehiclesContainer';
import ScreenView from '../components/ScreenView';

export default class JobProfileVehiclesScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <JobProfileVehiclesContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
