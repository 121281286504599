import {
  GET_PUSH_BADGE_NUMBER,
  EDIT_PUSH_BADGE_NUMBER,
} from '../constants/ActionTypes';

export default (state = {
  number: 0,
}, action) => {
  switch (action.type) {
    case GET_PUSH_BADGE_NUMBER:
      return Object.assign({}, state, {
        number: action.payload.number,
      });
    case EDIT_PUSH_BADGE_NUMBER:
      return Object.assign({}, state, {
        number: action.payload.number,
      });
    default:
      return state;
  }
}
