import React from 'react';
import { StyleSheet } from 'react-native';
import Colors from '../constants/Colors';
import { CheckBox } from 'react-native-elements';

type Props = {
  value: string;
  checked: boolean;
  onChange: (item: string) => void;
}
export default (props: Props) => {
  const {
    checked,
    onChange,
    value,
  } = props;

  return (
    <CheckBox
      checked={ checked }
      onPress={ () => onChange(value) }
      checkedIcon={ 'check' }
      uncheckedIcon={ 'square' }
      uncheckedColor={ Colors.veryWhite }
      checkedColor={ Colors.veryWhite }
      containerStyle={ [styles.checkbox, {
        backgroundColor: checked ? Colors.primaryBackground : Colors.veryWhite,
      }] }
      size={ 18 }
    />
  );
}

const styles = StyleSheet.create({
  checkbox: {
    height: 24,
    width: 24,
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.border,
  },
})