import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle, TouchableOpacity } from 'react-native';
import Text from './Text';
import * as Icon from '@expo/vector-icons';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

type Props = {
  label: string;
  value: string;
  onPress: () => void;
  iconName: any;
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  cancelText?: string;
  onCancel?: () => void;
}
//TODO Make Icon lib generic
export default ({ label, value, onPress, iconName, style, labelStyle, cancelText, onCancel }: Props) => {
  return (
    <View style={ [styles.container, style] }>
      {cancelText === undefined &&
      <Text style={ [styles.label, labelStyle] }>
        {label}
      </Text>
      }
      {cancelText !== undefined &&
      <View style={ { flexDirection: 'row', justifyContent: 'space-between' } }>
        <Text style={ [styles.label, labelStyle] }>
          {label}
        </Text>
        <TouchableOpacity onPress={ onCancel }>
          <View  style={ { flexDirection: 'row' } }>
            <View style={ styles.iconContainer }>
              <Icon.FontAwesome
                name={ 'times-circle' }
                size={ 22 }
                style={ styles.closeIcon }
              />
            </View>
            <Text style={ styles.closeText }>
              { cancelText }
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      }

      <TouchableOpacity onPress={ onPress }>
        <View style={ styles.inputContainer }>
          <Text style={ styles.value }>
            { value }
          </Text>
          <View style={ styles.iconContainer }>
            <Icon.FontAwesome
              name={ iconName }
              size={ 22 }
              style={ styles.icon }
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    
  },
  label: {
    fontFamily: Fonts.medium,
    marginBottom: 12,
  },
  inputContainer: {
    padding: 24,
    borderWidth: 1,
    borderColor: Colors.border,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  value: {
    fontSize: 20,
  },
  iconContainer: {
    width: 22,
    alignItems: 'center',
  },
  icon: {
    color: Colors.iconInput,
  },
  closeIcon: {
    color: Colors.errorText,
    paddingRight: 10,
  },
  closeText: {
    fontFamily: Fonts.medium,
    fontSize: 14,
    color: Colors.errorText,
  },
});