import {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  REFRESH_MESSAGES,
  MESSAGES_CURRENT_PAGE,
  UPDATE_MESSAGE_ATTENDING_REQUEST,
  UPDATE_MESSAGE_ATTENDING_SUCCESS,
  UPDATE_MESSAGE_ATTENDING_FAILURE,
} from '../constants/ActionTypes';
import { GET, PUT } from '../services/Api';
import { minutesPassed } from '../helpers/time';
import { reportRequestError, reportResponsePayloadError } from '../helpers/request-errors';

const fetchMessagesRequest = {
  type: FETCH_MESSAGES_REQUEST,
};

const fetchMessagesSuccess = (items, pagination) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: {
      pagination,
      items,
      fetchedAt: Date.now(),
    },
  };
}

const fetchMessagesFailure = {
  type: FETCH_MESSAGES_FAILURE,
};

export const fetchMessages = () => async (dispatch, getState) => {
  dispatch(fetchMessagesRequest);

  try {
    const params = {
      page: getState().messages.pagination.current_page || 1,
      per_page: 20,
    };
    const res = await GET('/messages/me', params);
    if (!res.ok) throw res;

    try {
      const { data, ...pagination } = res.json.result;
      dispatch(fetchMessagesSuccess(data, pagination));
    } catch (err) {
      reportResponsePayloadError(err, res);
    }
  } catch (res) {
    dispatch(fetchMessagesFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchMessages = state => {
  const messages = state.messages;

  if (!messages) {
    return true;
  } else if (messages.fetching) {
    return false;
  } else if (!messages.fetchedAt) {
    return true;
  } else if (messages.fetchError) {
    return true;
  } else {
    return minutesPassed(15, messages.fetchedAt);
  }
}

export const fetchMessagesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchMessages(getState())) {
    return dispatch(fetchMessages());
  }
}

const refreshMessages = {
  type: REFRESH_MESSAGES,
};

const messagesCurrentPage = current_page => {
  return {
    type: MESSAGES_CURRENT_PAGE,
    payload: { current_page }
  };
};

export const refreshFetchMessages = () => dispatch => {
  dispatch(refreshMessages);
  dispatch(messagesCurrentPage(1));
  return dispatch(fetchMessages());
};

export const fetchMoreMessages = () => (dispatch, getState) => {
  const { pagination } = getState().messages;

  if (!pagination.last_page || pagination.last_page === pagination.current_page) {
    return;
  }

  dispatch(messagesCurrentPage(pagination.current_page + 1));
  return dispatch(fetchMessages());
};

const updateMessageAttendingRequest = {
  type: UPDATE_MESSAGE_ATTENDING_REQUEST,
};

const updateMessageAttendFailure = {
  type: UPDATE_MESSAGE_ATTENDING_FAILURE,
};

const updateMessageAttendingSuccess = (data) => {
  return {
    type: UPDATE_MESSAGE_ATTENDING_SUCCESS,
    payload: {
      data,
      fetchedAt: Date.now(),
    },
  }
}

export const updateMessageAttend = (id, attending, messsage) => async (dispatch) => {
  dispatch(updateMessageAttendingRequest);
 
  try {
    const params = {
      employee_attend: attending,
      employee_message: messsage,
    };
    const res = await PUT(`/messages/me/${id}`, params);
    
    if (!res.ok) throw res;
    try {
      dispatch(updateMessageAttendingSuccess(res.json.result));
    } catch (err) {
      reportResponsePayloadError(err, res);
    }
    
  } catch (res) {
    dispatch(updateMessageAttendFailure);
    reportRequestError(res, { showAlert: false });
  }
};