import * as React from 'react';
import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Platform } from 'react-native';
import Constants from 'expo-constants';

class TimeReportContainer extends React.Component {
  render() {
    const uri = this.getIframeUrl();

    return (
        Platform.OS === 'web' ?
          <iframe src={uri} height="100%" width="100%"/> :
          <WebView
            source={{ uri }}
          />
    );
  }

  getIframeUrl() {
    const { userData } = this.props;
    const endpoint = '/tidrapport/';
    const wpUrl = Constants.expoConfig.extra.WP_URL;
    const data = {
      iframe: true,
      employeeNumber: userData.number,
      email: userData.email,
    };
    const queryParams = `?${queryString.stringify(data)}`;

    return `${wpUrl}${endpoint}${queryParams}`;
  }
}

const mapState = state => {
  const { user } = state;

  return {
    userData: user.data,
  };
};

export default connect(mapState)(TimeReportContainer);
