import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  REMOVE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  updating: false,
  updateError: false,
  items: [],
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        items: [
          ...action.payload.items,
        ],
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case REMOVE_NOTIFICATION:
      return Object.assign({}, state, {
        items: state.items.filter(i => i.id !== action.payload.id),
      });
    case UPDATE_NOTIFICATION_REQUEST:
      return Object.assign({}, state, {
        updating: true,
        updateError: false,
      });
    case UPDATE_NOTIFICATION_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        updateError: false,
      });
    case UPDATE_NOTIFICATION_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        updateError: true,
      });
    case REMOVE_NOTIFICATIONS:
      return Object.assign({}, state, {
        items: state.items.filter(i => !action.payload.ids.includes(i.id)),
      });
    case UPDATE_NOTIFICATIONS_REQUEST:
      return Object.assign({}, state, {
        updating: true,
        updateError: false,
      });
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        updateError: false,
      });
    case UPDATE_NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        updateError: true,
      });
    default:
      return state;
  }
}
