import { combineReducers } from 'redux';

import auth from './auth';
import bankidApp from './bankid-app';
import bankidCollect from './bankid-collect';
import bankidToken from './bankid-token';
import categories from './categories';
import connection from './connection';
import filters from './filters';
import jobs from './jobs';
import jobApplications from './job-applications';
import login from './login';
import messages from './messages';
import notifications from './notifications';
import pushSettings from './push-settings';
import offices from './offices';
import payslips from './payslips';
import pushBadge from './push-badge';
import pushNotifications from './push-notifications';
import pushToken from './push-token';
import services from './services';
import tags from './tags';
import timeReports from './time-reports';
import user from './user';


export default combineReducers({
  auth,
  bankidApp,
  bankidCollect,
  bankidToken,
  categories,
  connection,
  filters,
  jobApplications,
  jobs,
  login,
  messages,
  notifications,
  offices,
  payslips,
  pushBadge,
  pushNotifications,
  pushSettings,
  pushToken,
  services,
  tags,
  timeReports,
  user,
});
