import React from 'react';
import {
  FlatList,
  StyleSheet,
  View,
  TouchableOpacity } from 'react-native';
import * as Linking from 'expo-linking';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as Icon from '@expo/vector-icons';

import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import Text from '../components/Text';
import ListItemSeparator from '../components/ListItemSeparator';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import moment from 'moment';
import HTMLView from '../components/HTMLView';

const getTitle = (office) => (`Veteranpoolen ${office.title ? office.title.rendered : ''}`);

class OfficeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hoursExpanded: false };
  }

  render() {
    const { office, userData } = this.props;
    if (!office) {
      return (
        <View style={styles.emptyContainer}>
          <Icon.FontAwesome
            name={ 'times-circle' }
            size={ 22 }
            color={ Colors.primaryBackground }
            style={ { marginRight: 8, marginBottom: 3 } }
          />
          <Text style={styles.emptyText}>Inga kontor hittades</Text>
        </View>
      )
    } else {
      const listData = [
        {
          title: 'Adress',
          content: this._renderAddress(office),
        },
        {
          title: 'Telefon',
          content: this._renderPhone(office),
        },
        {
          title: 'E-post',
          content: this._renderEmail(office),
        },
        {
          title: 'Öppettider',
          content: this._renderHours(office),
        },
        {
          title: 'Visselblåsning',
          content: this._renderWhistleBlow(),
        },
      ];
      const latlng = {
        latitude: parseFloat(office.lat),
        longitude: parseFloat(office.lng),
      };
      return (
        <View style={styles.container}>
          <View style={styles.headerContainer}>
            <Text style={styles.header}>{ getTitle(office) }</Text>
          </View>
          <FlatList
            contentContainerStyle={{ paddingBottom: 24 }}
            keyExtractor={item => item.title}
            data={listData}
            renderItem={this._renderItem}
            ItemSeparatorComponent={ListItemSeparator}
          />
        </View>
      );
    }
  }

  _renderItem = ({ item }) => {
    return (
      <View style={styles.listItem}>
        <View style={styles.listItemContent}>
          <Text style={styles.listItemContentText}>
            {item.title}
          </Text>
        </View>
        <View style={styles.listItemContentRight}>
          {item.content}
        </View>
      </View>
    );
  }

  _renderAddress = office => (
    <View>
      <Text>
        {office.street}
      </Text>
      <Text>
        {office.zipcode} {office.city}
      </Text>
    </View>
  )

  _renderPhone = office => (
    <View>
      {office.phone.split(', ').map(p => (
        <Text key={p} style={styles.link} onPress={() => Linking.openURL(`tel:${p}`)}>{p}</Text>
      ))}
    </View>
  )

  _renderEmail = office => (
    <Text style={styles.link} onPress={() => Linking.openURL(`mailto:${office.mail}`)}>{office.mail}</Text>
  )

  _renderHours = office => {
    const today = moment().isoWeekday();
    const { hoursExpanded } = this.state;
    const hoursToday = office.opening_hours.find(oh => parseInt(oh.local_office_day, 10) === today);
    const hoursTodayOpens = hoursToday && hoursToday.local_office_day_opens;
    const hoursTodayCloses = hoursToday && hoursToday.local_office_day_closes;

    return (
      <View>
        <TouchableOpacity onPress={this._onHoursPress}>
          <View style={styles.hoursRow}>
            <Text style={styles.hoursDay}>
              {!!hoursTodayOpens ? 'Öppet idag:' : 'Stängt idag'}
            </Text>
            <Text style={{ flex: 1 }}>
              {hoursTodayOpens}-{hoursTodayCloses}
            </Text>
            <Icon.FontAwesome
              name={hoursExpanded ? 'angle-up' : 'angle-down'}
              size={32}
              style={{ marginLeft: 8, marginTop: -7, color: Colors.secondaryText }}
            />
          </View>
        </TouchableOpacity>
        {hoursExpanded && office.opening_hours.filter(oh => oh.local_office_day_opens).map(oh => (
          <View style={styles.hoursRow} key={oh.local_office_day}>
            <Text style={styles.hoursDay}>
              {oh.local_office_day_name.replace(/\w/, c => c.toUpperCase())}:
            </Text>
            <Text>
              {oh.local_office_day_opens}-{oh.local_office_day_closes}
            </Text>
          </View>
        ))}
        {hoursExpanded && !!office.opening_hours_exception &&
          <HTMLView value={office.opening_hours_exception} />
        }
        {!!office.opening_hours_vappen &&
          <View style={{ marginTop: 12 }}>
            <HTMLView value={office.opening_hours_vappen} />
          </View>
        }
      </View>
    );
  }

  _renderWhistleBlow = () => {
    const { navigation, userData } = this.props;
    return (
      <View style={ { flexDirection: 'row', justifyContent: 'flex-end' } }>
        <TouchableOpacity onPress={() => navigation.push('InformationPage', { url: 'https://veteranpoolen.se/information-page/visselblasning/', userData })}>
          <View style={ { flexDirection: 'row' } }>
            <Text>Rapportera</Text>
            <Icon.FontAwesome
              name={'angle-right'}
              size={32}
              style={{ marginLeft: 16, marginTop: -4, color: Colors.secondaryText }}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  _onHoursPress = () => {
    this.setState({ hoursExpanded: !this.state.hoursExpanded });
  }
}

const styles = StyleSheet.create({
  container: {
  },
  emptyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: 64,
  },
  emptyText: {
    fontWeight: 'bold',
    fontSize: 24,
    color: Colors.secondaryText,
    fontFamily: Fonts.medium,
  },
  link: {
    textDecorationLine: 'underline',
    color: Colors.secondaryText,
  },
  headerContainer: {
    backgroundColor: '#fff',
  },
  header: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  map: {
    width: '100%',
    height: 175,
  },
  listItem: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
    flexDirection: 'row',
  },
  listItemContent: {
    width: 120,
  },
  listItemContentText: {
    fontFamily: Fonts.medium,
  },
  listItemContentRight: {
    flex: 1,
  },
  hoursRow: {
    flex: 1,
    flexDirection: 'row',
  },
  hoursDay: {
    width: 90,
  },
});

const mapState = (state, ownProps) => {
  const { offices, user } = state;
  console.log('OFFICES', offices)

  return {
    loading: offices.fetching,
    fetchError: offices.fetchError,
    office: offices.items.find(m => m.id === ownProps.id),
    userData: user.data,
  };
};

export default compose(
  connect(mapState),
  withLoader,
  withFetchError
)(OfficeContainer);
