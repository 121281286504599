import {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  REFRESH_MESSAGES,
  MESSAGES_CURRENT_PAGE,
  UPDATE_MESSAGE_ATTENDING_REQUEST,
  UPDATE_MESSAGE_ATTENDING_SUCCESS,
  UPDATE_MESSAGE_ATTENDING_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  refreshing: false,
  updating: false,
  pagination: {},
  items: [],
  fetchedAt: null,
}, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        refreshing: false,
        pagination: action.payload.pagination,
        items: action.payload.pagination.current_page === 1 ?
          [...action.payload.items] :
          [...state.items, ...action.payload.items],
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_MESSAGES_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
        refreshing: false,
      });
    case REFRESH_MESSAGES:
      return Object.assign({}, state, {
        refreshing: true,
      });
    case MESSAGES_CURRENT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          current_page: action.payload.current_page,
        },
      });
    case UPDATE_MESSAGE_ATTENDING_REQUEST:
      return Object.assign({}, state, {
        updating: true,
        fetchError: false,
      });
    case UPDATE_MESSAGE_ATTENDING_SUCCESS:
      const idx = state.items.findIndex( (item) => {
        return item.id === action.payload.data.id;
      });

      let items = state.items;
      items.splice(idx, 1, action.payload.data);

      return Object.assign({}, state, {
        updating: false,
        items,
      });
    case UPDATE_MESSAGE_ATTENDING_FAILURE:
        return Object.assign({}, state, {
          updating: false,
          fetchError: true,
          refreshing: false,
        });
      return state;
    default:
      return state;
  }
}
