import React from 'react';
import {
  ActivityIndicator,
  FlatList,
  KeyboardAvoidingView,
  TextInput,
  StyleSheet,
  View,
} from 'react-native';
import { connect } from 'react-redux';

import Fonts from '../constants/Fonts';
import ListItemSeparator from '../components/ListItemSeparator';
import Text from '../components/Text';
import { PrimaryButton } from '../components/Button';
import { editUserDetails, updateUser } from '../actions/user';
import Colors from '../constants/Colors';
import StandardTextStyle from '../constants/StandardTextStyle';
import OfflineWarning from '../components/OfflineWarning';

const listData = [
  {
    prop: 'first_name',
    title: 'Förnamn',
    keyboardType: 'default',
    textContentType: 'givenName',
  },
  {
    prop: 'last_name',
    title: 'Efternamn',
    keyboardType: 'default',
    textContentType: 'familyName',
  },
  {
    prop: 'phone',
    title: 'Telefonnummer',
    keyboardType: 'phone-pad',
    textContentType: 'telephoneNumber',
  },
  {
    prop: 'email',
    title: 'E-post',
    keyboardType: 'email-address',
    textContentType: 'emailAddress',
  },
  {
    prop: 'address',
    title: 'Adress',
    keyboardType: 'default',
    textContentType: 'streetAddressLine1',
  },
  {
    prop: 'postal_code',
    title: 'Postnummer',
    keyboardType: 'numeric',
    textContentType: 'postalCode',
  },
  {
    prop: 'city',
    title: 'Ort',
    keyboardType: 'default',
    textContentType: 'addressCity',
  },
];

class ContactDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changeset: {} };
  }

  render() {
    return (
      <KeyboardAvoidingView behavior="padding">
        <FlatList
          contentContainerStyle={{ paddingBottom: 24 }}
          keyExtractor={item => item.prop}
          data={listData}
          renderItem={this._renderItem}
          ItemSeparatorComponent={ListItemSeparator}
          ListFooterComponent={this._renderFooter}
        />
      </KeyboardAvoidingView>
    );
  }

  _renderItem = ({ item, index, section }) => {
    const { userData, updateErrors } = this.props;
    const { changeset } = this.state;
    const value = changeset[item.prop] !== undefined ? changeset[item.prop] : userData[item.prop];

    return (
      <View style={styles.listItem}>
        <View style={styles.listItemContent}>
          <Text style={styles.listItemContentText}>
            {item.title}
          </Text>
          <View style={styles.listItemContentRight}>
            <TextInput
              value={value}
              placeholder={item.title}
              keyboardType={item.keyboardType}
              textContentType={item.textContentType}
              onChangeText={this._onChange(item.prop)}
              style={styles.listItemTextInput}
              underlineColorAndroid="transparent"
            />
          </View>
        </View>
        {updateErrors[item.prop] && updateErrors[item.prop].map((err, i) => (
          <Text key={i} style={styles.updateError}>{err}</Text>
        ))}
      </View>
    );
  }

  _renderFooter = () => {
    const { updating, isConnected } = this.props;

    return (
      <View style={styles.footer}>
        {isConnected ? (
          <PrimaryButton
            title={updating ? 'Sparar...' : 'Spara ändringar'}
            onPress={this._submitChangeset}
            icon={updating && <ActivityIndicator color="#fff" />}
            disabled={!this._isDirty()}
          />
        ) : (
          <OfflineWarning style={{ marginTop: 12 }} />
        )}
      </View>
    );
  }

  _isDirty = () => {
    return Object.keys(this.state.changeset).length !== 0;
  }

  _onChange = prop => value => {
    this.setState({
      changeset: {
        ...this.state.changeset,
        [prop]: value,
      }
    });
  }

  _submitChangeset = () => {
    const { changeset } = this.state;
    const { editUserDetails, updateUser, updating } = this.props;

    if (updating) {
      return;
    }

    if (!this._isDirty()) {
      return;
    }

    editUserDetails(changeset);
    updateUser({ haptic: true });
  }
}

const styles = StyleSheet.create({
  listItem: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
  },
  listItemContent: {
    flex: 1,
    flexDirection: 'row',
  },
  listItemContentText: {
    fontFamily: Fonts.medium,
  },
  listItemContentRight: {
    flex: 1,
    alignItems: 'flex-end',
  },
  listItemTextInput: {
    ...StandardTextStyle,
    lineHeight: 22, // TextInput content is not aligned properly..
    textAlign: 'right',
  },
  footer: {
    padding: 24,
  },
  updateError: {
    fontSize: 14,
    marginTop: 4,
    color: Colors.errorText,
  },
});

const mapState = (state) => {
  const { user, connection } = state;

  return {
    userData: user.data,
    updating: user.updating,
    updateErrors: user.updateErrors,
    isConnected: connection.isConnected,
  };
};

const mapDispatch = {
  updateUser,
  editUserDetails,
};

export default connect(mapState, mapDispatch)(ContactDetailsContainer);
