import {
  AUTH_CREATE,
  AUTH_DESTROY,
} from '../constants/ActionTypes';
import { rootLogout } from './root';
import { setToken, DELETE } from '../services/Api';
import NavigationService from '../services/NavigationService';
import { clearUpdatePushToken } from './push-token';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const authCreate = ({ accessToken, accessTokenExpiresAt } = {}) => {
  return {
    type: AUTH_CREATE,
    payload: {
      accessToken,
      accessTokenExpiresAt,
    },
  };
}

const authDestroy = {
  type: AUTH_DESTROY,
};

export const checkLoggedIn = () => async dispatch => {
  const accessToken = await AsyncStorage.getItem('accessToken');
  const accessTokenExpiresAt = await AsyncStorage.getItem('accessTokenExpiresAt');

  if (accessToken) {
    setToken(accessToken);
    dispatch(authCreate({ accessToken, accessTokenExpiresAt }));
  }
}

export const logIn = auth => async dispatch => {
  if (Platform.OS === 'web') {
    await AsyncStorage.setItem('accessToken', auth.accessToken)
    await AsyncStorage.setItem('accessTokenExpiresAt', auth.accessTokenExpiresAt)
  }
  setToken(auth.accessToken);
  dispatch(authCreate(auth));
  NavigationService.navigate('Main');
};

export const logOut = () => async dispatch => {
  await AsyncStorage.removeItem('accessToken');
  await AsyncStorage.removeItem('accessTokenExpiresAt');

  await dispatch(clearUpdatePushToken());
  await DELETE('/auth/logout');
  dispatch(destroySession());
};

export const destroySession = () => async dispatch => {
  dispatch(authDestroy);
  dispatch(rootLogout);
  NavigationService.navigate('Auth');
};