import React from 'react';
import { Platform } from 'react-native';
import WebView from 'react-native-webview';

export default class RegisterContainer extends React.Component {
  render() {
    const uri = 'https://veteranpoolen1.teamtailor.com/jobs/188193-intresseanmalan-for-veteranpoolen/applications/new?secret=cc6f1177-45ea-465a-962d-60d2abb5e700';

    return (
      Platform.OS === 'web' ?
        <iframe src={uri} height="100%" width="100%"/> :
        <WebView
          source={{ uri }}
        />
    );
  }
}
