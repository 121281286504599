import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Fonts from '../constants/Fonts';
import { fetchServicesIfNeeded } from '../actions/services';
import { fetchTagsIfNeeded } from '../actions/tags';
import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import withLoader from '../components/withLoader';
import { groupBy } from '../helpers/array';
import withFetchError from '../components/withFetchError';
import withFetch from '../components/withFetch';

const getServicesHint = (services, selectedServices) => {
  const count = selectedServices.length;

  if (count === 0) {
    return null;
  } if (count === 1) {
    const serviceNames = services.filter(s => selectedServices.includes(s.id)).map(s => s.name).join(', ');
    return serviceNames;
  } else {
    return `${count} val`;
  }
};

const getVehiclesHint = (userData) => {
  const selectedLicensesCount = userData ? userData.driver_licenses.split(',').length : 0;
  const selectedCarCount = userData.car ? 1 : 0;
  const count = selectedLicensesCount + selectedCarCount;

  return `${count} val`;
};

const getTagGroupHint = (tags, tagGroup, selectedTags) => {
  const tagsByTagGroup = groupBy(tags, 'taggroup_id');
  const groupTags = tagsByTagGroup[tagGroup.id];

  if (!groupTags) {
    return null;
  }

  const count = groupTags.filter(t => selectedTags.includes(t.id)).length;

  if (count === 0) {
    return null;
  } if (count === 1) {
    const tagNames = groupTags.filter(s => selectedTags.includes(s.id)).map(s => s.name).join(', ');
    return tagNames;
  } else {
    return `${count} val`;
  }
};

class JobProfileContainer extends React.Component {
  render() {
    const {
      services,
      selectedServices,
      tagGroups,
      selectedTags,
      tags,
      userData,
    } = this.props;
    const listData = [
      {
        title: 'Mina tjänster',
        hint: getServicesHint(services, selectedServices),
        navigateTo: 'JobProfileServices',
      },
      // {
      //   title: 'Fordon',
      //   hint: getVehiclesHint(userData),
      //   navigateTo: 'JobProfileVehicles',
      // },
      ...tagGroups.map(tagGroup => ({
        title: tagGroup.name,
        hint: getTagGroupHint(tags, tagGroup, selectedTags),
        navigateTo: 'JobProfileTagGroup',
        navigateToProps: { id: tagGroup.id, title: tagGroup.name },
      })),
    ];

    return (
      <FlatList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => item.title}
        data={listData}
        renderItem={this._renderItem}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={item.title}
        hint={item.hint}
        onPress={this._onPress(item)}
      />
    );
  }

  _onPress = item => () => {
    this.props.navigation.push(item.navigateTo, item.navigateToProps);
  }
}

const styles = StyleSheet.create({
  listItem: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemContent: {
    flex: 1,
  },
  listItemContentText: {
    fontFamily: Fonts.medium,
  },
  listItemContentRight: {
    alignItems: 'flex-end',
  },
});

const mapState = state => {
  const { services, tags, user } = state;

  return {
    loading: services.fetching || tags.fetching,
    fetchError: services.fetchError || tags.fetchError,
    services: services.items,
    selectedServices: services.selectedItems,
    tagGroups: tags.itemGroups,
    selectedTags: tags.selectedItems,
    tags: tags.items,
    userData: user.data,
  };
};

const mapDispatch = {};

export default compose(
  connect(mapState, mapDispatch),
  withLoader,
  withFetchError
)(JobProfileContainer);
