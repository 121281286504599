import React from 'react';
import * as SplashScreen from 'expo-splash-screen';

export type AppLoadingProps =
  | {
      startAsync: () => Promise<void>;
      onError: (error: Error) => void;
      onFinish: () => void;
      autoHideSplash?: boolean;
    }
  | {
      autoHideSplash?: boolean;
    };

type Props = {
  autoHideSplash?: boolean;
};

class AppLoadingWrapper extends React.Component<Props> {
  static defaultProps = {
    autoHideSplash: true,
  };

  constructor(props: Props) {
    super(props);
    SplashScreen.preventAutoHideAsync();
  }

  componentWillUnmount() {
    if (this.props.autoHideSplash === false) {
      return;
    }
    // @ts-ignore
    if (global.__E2E__) {
      SplashScreen.hideAsync();
    } else {
      setTimeout(SplashScreen.hideAsync, 200);
    }
  }

  render() {
    return null;
  }
}

class AppLoading extends React.Component<AppLoadingProps> {
  _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;

    this.startLoadingAppResourcesAsync().catch((error) => {
      console.error(`AppLoading threw an unexpected error when loading:\n${error}`);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private async startLoadingAppResourcesAsync() {
    if (!('startAsync' in this.props)) {
      return;
    }

    if (!('onFinish' in this.props)) {
      throw new Error('AppLoading onFinish prop is required if startAsync is provided');
    }

    if (!('onError' in this.props)) {
      throw new Error('AppLoading onError prop is required if startAsync is provided');
    }

    try {
      await this.props.startAsync();
    } catch (e) {
      if (!this._isMounted) {
        return;
      }
      this.props.onError(e);
    } finally {
      if (!this._isMounted) {
        return;
      }
      this.props.onFinish();
    }
  }

  render() {
    return <AppLoadingWrapper {...this.props} />;
  }
}

export default AppLoading;