import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { BANKID_APP_LAUNCHED, BANKID_APP_RETURNED, BANKID_APP_FINISHED } from '../constants/ActionTypes';
import * as IntentLauncher from 'expo-intent-launcher';

const bankidAppLaunched = {
  type: BANKID_APP_LAUNCHED,
};

const bankidAppReturned = {
  type: BANKID_APP_RETURNED,
};

const bankidAppFinished = {
  type: BANKID_APP_FINISHED,
}

export const openBankidApp = () => (dispatch, getState) => {
  const autoStartToken = getState().bankidToken.autoStartToken;

  let url;


  if (Platform.OS === 'ios') {
    const redirectUrl = Linking.makeUrl('login/bankid', { bankidSuccess: true });
    url = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(redirectUrl)}`;
    dispatch(bankidAppLaunched);
    Linking.openURL(url);
  } else {
    url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;

    Linking.canOpenURL(url).then(supported => {
      if (!supported) {
        alert("Vappen kräver Mobilt BankID på enheten för att logga in.");
      }
    }).catch(err => console.error('An error occurred', err));

    dispatch(bankidAppLaunched);
    if (Platform.OS === 'android') {
      IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
        data: url,
        packageName: 'com.bankid.bus',
      });
    } else if (Platform.OS === 'web') {
      Linking.openURL(url);
    }
  }
};

export const returnFromBankidApp = () => dispatch => {
  dispatch(bankidAppReturned);
};

export const finishBankidApp = () => dispatch => {
  dispatch(bankidAppFinished);
};
