import {
  UPDATE_APPLY_JOB_REQUEST,
  UPDATE_APPLY_JOB_SUCCESS,
  UPDATE_APPLY_JOB_FAILURE,
  UPDATE_UNAPPLY_JOB_REQUEST,
  UPDATE_UNAPPLY_JOB_SUCCESS,
  UPDATE_UNAPPLY_JOB_FAILURE
} from '../constants/ActionTypes';

export default (state = {
  items: [],
  updating: false,
}, action) => {
  switch (action.type) {
    case UPDATE_APPLY_JOB_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      });
    case UPDATE_APPLY_JOB_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        items: [...state.items, action.payload.id],
      });
    case UPDATE_APPLY_JOB_FAILURE:
      return Object.assign({}, state, {
        updating: false,
      });
    case UPDATE_UNAPPLY_JOB_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      });
    case UPDATE_UNAPPLY_JOB_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        items: [...state.items, action.payload.id],
      });
    case UPDATE_UNAPPLY_JOB_FAILURE:
      return Object.assign({}, state, {
        updating: false
      });
    default:
      return state;
  }
}
