import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Text from './Text';
import Colors from '../constants/Colors';
import * as Icon from '@expo/vector-icons';
import Fonts from '../constants/Fonts';
import { LocalAttachment } from '../constants/types';

type Props = {
  items: LocalAttachment[] & {
    id: number;
    type?: { title: string; type?: number; unit?: string };
    title?: string;
    cost?: string;
    url?: string;
  }[],
  onDelete?: (id: number) => void;
}

export default ({ items, onDelete }: Props) => {
  return (
    <View style={ styles.list }>
      { [...items].map((item, i) =>
        <View key={ item.id } style={ [styles.listRow, i !== (items.length - 1) && { marginBottom: 12 }] }>
          <View style={ [styles.listRowCells] }><Text>{ `${item.type ? item.type.title : item.title || item.url }` }</Text></View>
          { (!!item.cost && !!item.type) && <View style={ [styles.listRowCells, styles.listRowCellsCenter] }><Text style={ styles.listRowCellsCenterText }>{ `${item.cost} ${item.type.unit}` }</Text></View>}
          { !!onDelete && <View>
            <TouchableOpacity onPress={ () => onDelete(item.id) }>
              <Icon.AntDesign
                size={ 22 }
                color={ Colors.primaryBackground }
                name={ 'closecircle' }
              />
            </TouchableOpacity>
          </View> }
        </View>
      ) }
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    paddingVertical: 16,
    paddingHorizontal: 12,
  },
  listRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: Colors.appBackground,
    borderRadius: 6,
  },
  listRowCells: {
    flex: 1,
  },
  listRowCellsCenter: {
    alignItems: 'flex-end',
    paddingRight: 16,
  },
  listRowCellsCenterText: {
    fontFamily: Fonts.medium,
  }
})