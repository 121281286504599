import * as Haptics from 'expo-haptics';
import { notification as hapticNotification } from '../helpers/haptic';

import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  EDIT_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../constants/ActionTypes';
import { logOut } from './auth';
import { GET, PUT } from '../services/Api';
import { minutesPassed } from '../helpers/time';
import { reportRequestError } from '../helpers/request-errors';

const getUserDetailFieldsFromResult = (result) => {
  return {
    id: result.id,
    car: result.car,
    driver_licenses: result.driver_licenses,
    number: result.number,
    first_name: result.first_name,
    last_name: result.last_name,
    phone: result.phone,
    email: result.email,
    address: result.address,
    postal_code: String(result.postal_code),
    city: result.city,
    user_note: result.user_note,
    has_read_policy_information: result.has_read_policy_information,
  };
};

const fetchUserRequest = {
  type: FETCH_USER_REQUEST,
};

const fetchUserSuccess = data => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: {
      data,
      fetchedAt: Date.now(),
    },
  };
};

const fetchUserFailure = {
  type: FETCH_USER_FAILURE,
};

const shouldFetchUser = state => {
  const user = state.user;

  if (!user) {
    return true;
  } else if (user.fetching) {
    return false;
  } else if (!user.fetchedAt) {
    return true;
  } else if (user.fetchError) {
    return true;
  } else {
    return minutesPassed(60, user.fetchedAt);
  }
};

const fetchUser = () => async dispatch => {
  dispatch(fetchUserRequest);

  try {
    const res = await GET('/employee/me');
    if (!res.ok) throw res;

    const data = getUserDetailFieldsFromResult(res.json.result);
    dispatch(fetchUserSuccess(data));
  } catch (res) {
    dispatch(fetchUserFailure);
    dispatch(logOut());
    reportRequestError(res, { showAlert: false });
  }
};

export const fetchUserIfNeeded = () => async (dispatch, getState) => {
  if (shouldFetchUser(getState())) {
    return dispatch(fetchUser());
  }
};

export const updateUserPolicyConsent = () => async (dispatch, getState) => {

  try {
    const res = await PUT('/employee/me/policy-information');
    if (!res.ok) throw res;

    const user = getState().user.data;

    dispatch(editUser({
      ...user,
      has_read_policy_information: true,
    }));
  } catch (res) {
    dispatch(updateUserFailure);
    reportRequestError(res, { showAlert: false });
  }
};

const editUser = changeset => {
  return {
    type: EDIT_USER,
    payload: { changeset },
  };
};

export const editUserDetails = changeset => dispatch => {
  dispatch(editUser(changeset));
};

const updateUserRequest = {
  type: UPDATE_USER_REQUEST,
};

const updateUserSuccess = data => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: { data },
  };
};

const updateUserFailure = (errors) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      errors,
    },
  };
};

export const updateUser = ({ haptic } = {}) => async (dispatch, getState) => {
  const user = getState().user.data;

  dispatch(updateUserRequest);

  try {
    const res = await PUT('/employee/me', user);
    if (!res.ok) throw res;

    const data = getUserDetailFieldsFromResult(res.json.result);
    dispatch(updateUserSuccess(data));
    haptic && hapticNotification(Haptics.NotificationFeedbackType.Success);

    return data;
  } catch (res) {
    if (res.status === 422) {
      dispatch(updateUserFailure(res.json.errors));
      haptic && hapticNotification(Haptics.NotificationFeedbackType.Error);
    } else {
      dispatch(updateUserFailure());
      reportRequestError(res);
    }

    return false;
  }
};

export const updateLocalUser = (note) => async (dispatch) => {
  dispatch(updateUserRequest);

  try {
    const res = await PUT('/employee/me/profile', {
      note,
    });
    if (!res.ok) throw res;

    const data = getUserDetailFieldsFromResult(res.json.result);
    dispatch(updateUserSuccess(data));

    return data;
  } catch (res) {
    if (res.status === 422) {
      dispatch(updateUserFailure(res.json.errors));
    } else {
      dispatch(updateUserFailure());
      reportRequestError(res);
    }

    return false;
  }
};
