import React from 'react';
import {
  KeyboardAvoidingView,
  TextInput,
  StyleSheet,
  View,
} from 'react-native';
import { connect } from 'react-redux';

import Fonts from '../constants/Fonts';
import Text from '../components/Text';
import { PrimaryButton } from '../components/Button';
import { editUserDetails, updateLocalUser, updateUser } from '../actions/user';
import Colors from '../constants/Colors';

class NoteInputContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { note: '', inputState: 'initial' };
  }

  _onChangeDescription = (input) => {
    this.setState({ note: input, inputState: 'dirty' });
  }

  _submitInput = async () => {
    const { updateLocalUser } = this.props;
    const { note } = this.state;
    await updateLocalUser(note);

    this.setState({ inputState: 'saved' });
  }

  componentDidMount = () => {
    const { userData } = this.props;
    this.setState({ note: userData?.user_note || '' });
  }

  render() {
    const { note, inputState } = this.state;
    let ctaString = '';
    switch (inputState) {
      case 'initial': ctaString = 'Inga ändringar'; break;
      case 'saved': ctaString = 'Sparad'; break;
      case 'dirty': ctaString = 'Spara'; break;
      default: ctaString = 'Inga ändringar'; break;
    }


    return (
      <KeyboardAvoidingView behavior={ 'padding' }>
        <View style={ styles.container }>
          <Text style={ styles.heading }>
            Frånvaro/Ledighet
          </Text>
          <Text style={ styles.subHeading }>
            Anteckna om det är något ditt kontor behöver veta i samband med tilldelning av uppdrag, till exempel om du ska vara ledig, ha semester eller ej kan jobba för tillfället.
          </Text>
          <View style={ styles.noteInput }>
            <TextInput
              style={ { flex: 1 } }
              multiline={ true }
              placeholder={ 'Skriv här...' }
              placeholderTextColor={ Colors.borderDark }
              value={ note }
              onChangeText={ this._onChangeDescription }
            />
          </View>
          <PrimaryButton
            onPress={ this._submitInput }
            title={ ctaString }
            disabled={ inputState === 'initial' || inputState === 'saved' }
          />

        </View>
      </KeyboardAvoidingView>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingTop: 16,
  },
  heading: {
    marginVertical: 8,
    fontFamily: Fonts.medium,
    marginLeft: 4,
  },
  subHeading: {
    marginVertical: 8,
    fontFamily: Fonts.medium,
    marginLeft: 4,
    fontSize: 14,
  },
  noteInput: {
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border,
    padding: 16,
    height: 100,
    backgroundColor: Colors.veryWhite,
  },
});

const mapState = (state) => {
  const { user, connection } = state;

  return {
    userData: user.data,
    updating: user.updating,
    updateErrors: user.updateErrors,
    isConnected: connection.isConnected,
  };
};

const mapDispatch = {
  updateUser,
  editUserDetails,
  updateLocalUser,
};

export default connect(mapState, mapDispatch)(NoteInputContainer);
