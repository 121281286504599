import React, { useEffect, useRef, ReactElement } from 'react'
import { TextInput } from 'react-native'
import { format } from 'date-fns'

interface Props {
  minimumDate?: Date;
  maximumDate?: Date;
  value: string;
  onChange: (value: any) => void;
  // ...more
}

function DateInput(props: Props): ReactElement {
  const { minimumDate, maximumDate, ...moreProps } = props

  const inputRef = useRef<TextInput>(null)

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.setNativeProps({
        type: 'date',
        min: minimumDate && format(minimumDate, 'yyyy-MM-dd'),
        max: maximumDate && format(maximumDate, 'yyyy-MM-dd'),
        pattern: 'd{4}-d{2}-d{2}',
      })
    }
  }, [inputRef?.current])

  return <TextInput ref={inputRef} type="date" {...moreProps} />
}

export default DateInput