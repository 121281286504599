import React from "react";
import { Provider } from "react-redux";
import AppLoading from "./components/AppLoading";
import { Asset } from "expo-asset";
import * as Icon from "@expo/vector-icons";
import * as Font from "expo-font";
import ErrorBoundary from "react-error-boundary";
import * as Sentry from "sentry-expo";
import moment from "moment";
import "moment/locale/sv";

import configureStore from "./configureStore";
import AppContainer from "./containers/AppContainer";
import { Platform } from "react-native";

const SentryInstance = Platform.OS === 'web' ? Sentry.Browser : Sentry.Native;

moment.locale("sv");

const store = configureStore();

Sentry.init({
  dsn: "https://5b3579555cd54bc6b627f3a3d0ad2cdd@sentry.io/1314467",
  debug: true,
});

export default class App extends React.Component {
  state = {
    isLoadingComplete: false,
  };

  render() {
    if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
      return (
        <AppLoading
          startAsync={this._loadResourcesAsync}
          onError={this._handleLoadingError}
          onFinish={this._handleFinishLoading}
        />
      );
    } else {
      return (
        <Provider store={store}>
          <ErrorBoundary onError={this._onComponentError}>
            <AppContainer />
          </ErrorBoundary>
        </Provider>
      );
    }
  }

  _onComponentError = (error, componentStack) => {
    SentryInstance.captureException(error, { extra: componentStack });
  };

  _loadResourcesAsync = async () => {
    return Promise.all([
      Asset.loadAsync([
        require("./assets/images/logo-white.png"),
        require("./assets/images/bankid-logo.png"),
        require("./assets/images/login-video.mp4"),
        require("./assets/images/checkbox-checked.png"),
        require("./assets/images/checkbox-unchecked.png"),
      ]),
      Font.loadAsync({
        ...Icon.FontAwesome.font,
        ...Icon.Ionicons.font,
        "cent-goth-bold": require("./assets/fonts/CentGothWGL-Bold.ttf"),
        "gt-america-regular": require("./assets/fonts/GT-America-Standard-Regular.ttf"),
        "gt-america-medium": require("./assets/fonts/GT-America-Standard-Medium.ttf"),
        "gt-america-bold": require("./assets/fonts/GT-America-Standard-Bold.ttf"),
      }),
    ]);
  };

  _handleLoadingError = (error) => {
    console.warn(error);
    SentryInstance.captureException(error);
  };

  _handleFinishLoading = () => {
    this.setState({ isLoadingComplete: true });
  };
}
