import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  categories: [],
  error: '',
}, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return state;
    case FETCH_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: [...action.payload],
      });
    case FETCH_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        error: action.payload,
      });
    default:
      return state;
  }
}
