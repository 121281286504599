import React from 'react';

import ScrollView from '../components/ScrollView';
import NoteInputContainer from '../containers/NoteInputContainer';

export default class ContactDetailsScreen extends React.Component {

  render() {
    return (
      <ScrollView>
        <NoteInputContainer navigation={ this.props.navigation } />
      </ScrollView>
    );
  }

}
