import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import withNoItems from '../components/withNoItems';

class JobsListHeaderStatusContainer extends React.Component {
  render() {
    return null;
  }
}

const mapState = state => {
  const { jobs } = state;

  return {
    loading: jobs.fetching && !jobs.refreshing,
    noItems: jobs.items.length === 0 && !jobs.fetching,
    fetchError: jobs.fetchError,
  };
};

const mapDispatch = {
};

export default compose(
  connect(mapState, mapDispatch),
  withLoader,
  withFetchError,
  withNoItems({
    title: "Hittade inga uppdrag",
    text: "Prova att ändra din sökning"
  })
)(JobsListHeaderStatusContainer);
