import React, { ReactElement } from 'react';
import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import LineItemCheckbox from './LineItemCheckbox';
import Colors from '../constants/Colors';
import Text from './Text';
import LineItemRadioButton from './LineItemRadioButton';

type Props = {
  checked: boolean;
  label: string;
  onChange: (value: any) => void;
  value: any;
  containerStyles?: StyleProp<ViewStyle>;
}

type WrapperProps = {
  children?: ReactElement;
} & Props

const LineItemBase = (props: WrapperProps) => {
  const {
    label,
    onChange,
    value,
    children,
    containerStyles,
  } = props;

  return (
    <TouchableOpacity onPress={ () => onChange(value) }>
      <View style={ [styles.container, containerStyles] }>
        <View>
          <Text>
            { label }
          </Text>
        </View>
        { children }
      </View>
    </TouchableOpacity>
  );
};

export default {
  Checkbox: (props: Props) => (
    <LineItemBase { ...props }>
      <LineItemCheckbox value={ props.value } onChange={ props.onChange }
        checked={ props.checked }
      />
    </LineItemBase>
  ),
  Radio: (props: Props) => (
    <LineItemBase { ...props }>
      <LineItemRadioButton checked={ props.checked } />
    </LineItemBase>
  ),
  Simple: (props: Props) => (
    <LineItemBase { ...props } />
  ),
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderColor: Colors.border,
    paddingHorizontal: 32,
    paddingVertical: 24,
    justifyContent: 'space-between',
  },
});
