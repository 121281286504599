import {
  FETCH_BANKID_TOKEN_REQUEST,
  FETCH_BANKID_TOKEN_SUCCESS,
  FETCH_BANKID_TOKEN_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  testMode: false,
  fetching: false,
  fetchError: false,
  orderRef: null,
  autoStartToken: null,
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_BANKID_TOKEN_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_BANKID_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        orderRef: action.payload.orderRef,
        autoStartToken: action.payload.autoStartToken,
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_BANKID_TOKEN_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
        orderRef: null,
        autoStartToken: null,
      });
    default:
      return state;
  }
}
