import React from 'react';
import { SectionList } from 'react-native';
import { connect } from 'react-redux';
import debounce from 'debounce';

import ListItemCheckbox from '../components/ListItemCheckbox';
import ListItemSeparator from '../components/ListItemSeparator';
import SectionHeader from '../components/SectionHeader';
import VehicleTypes from '../constants/VehicleTypes';
import DriversLicenseTypes from '../constants/DriversLicenseTypes';
import { editUserDetails, updateUser } from '../actions/user';

const vehicleOptions = [
  {
    type: VehicleTypes.Car,
    name: 'Ja',
  },
  {
    type: VehicleTypes.CarWithTowbar,
    name: 'Ja, med släp',
  },
  {
    type: VehicleTypes.NoCar,
    name: 'Nej',
  },
];

const driversLicenseOptions = [
  {
    type: DriversLicenseTypes.A,
    name: 'A'
  },
  {
    type: DriversLicenseTypes.B,
    name: 'B'
  },
  {
    type: DriversLicenseTypes.C,
    name: 'C'
  },
  {
    type: DriversLicenseTypes.D,
    name: 'D'
  },
  {
    type: DriversLicenseTypes.A1,
    name: 'A1'
  },
  {
    type: DriversLicenseTypes.BE,
    name: 'BE'
  },
  {
    type: DriversLicenseTypes.CE,
    name: 'CE'
  },
  {
    type: DriversLicenseTypes.DE,
    name: 'DE'
  },
  {
    type: DriversLicenseTypes.AM,
    name: 'AM'
  },
];

const valueTypes = {
  Single: 1,
  CommaSeparated: 2,
};

class JobProfileVehiclesContainer extends React.Component {
  constructor(props) {
    super(props);

    const { car, driver_licenses } = props.userData;

    this.state = { 
      changeset: {},
      previousValue: {
        car,
        driver_licenses,
      },
    };

    this._updateUserDebounced = debounce(this._updateUser, 3000);
  }

  render() {
    const { changeset } = this.state;
    const { userData } = this.props;
    const { car, driver_licenses } = userData;
    const driverLicensesArray = driver_licenses.split(',');
    const changesetDriverLicensesArray = changeset['driver_licenses'] && changeset['driver_licenses'].split(',');
    const changesetCar = changeset['car'] !== undefined && changeset['car'];

    const listSections = [
      {
        title: 'Har du bil?',
        userProp: 'car',
        valueType: valueTypes.Single,
        currentValue: car,
        data: vehicleOptions.map(o => ({
          type: o.type,
          name: o.name,
          selected: changesetCar ? changesetCar === o.type : car === o.type,
        })),
      },
      {
        title: 'Typ av körkort',
        userProp: 'driver_licenses',
        valueType: valueTypes.CommaSeparated,
        currentValue: driverLicensesArray,
        data: driversLicenseOptions.map(o => ({
          type: o.type,
          name: o.name,
          selected: driverLicensesArray.includes(o.type),
          selected: changesetDriverLicensesArray ? 
            changesetDriverLicensesArray.includes(o.type) : driverLicensesArray.includes(o.type),
        })),
      },
    ];

    return (
      <SectionList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => item.type}
        sections={listSections}
        renderItem={this._renderItem}
        renderSectionHeader={this._renderSectionHeader}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderSectionHeader = ({ section: { title } }) => {
    return (
      <SectionHeader title={title} />
    );
  }

  _renderItem = ({ item, index, section }) => {
    return (
      <ListItemCheckbox
        label={item.name}
        checked={item.selected}
        onChange={this._onChange(item, section)}
      />
    );
  }

  _onChange = (item, section) => ({ checked }) => {
    const { changeset } = this.state;
    const { editUserDetails } = this.props;
    const changesetCurrentValue = changeset[section.userProp];
    const currentValue = changesetCurrentValue === undefined ? section.currentValue : changesetCurrentValue.split(',');
    let value;

    if (section.valueType === valueTypes.Single) {
      value = item.type;
    } else if (section.valueType === valueTypes.CommaSeparated) {
      if (checked) {
        value = [...currentValue, item.type];
      } else {
        value = currentValue.filter(l => l !== item.type);
      }

      value = value.join(',');
    }

    const newChangeset = {
      ...changeset,
      [section.userProp]: value,
    };

    this.setState({
      changeset: newChangeset,
    });

    editUserDetails(newChangeset);
    this._updateUserDebounced();
  }

  _updateUser = () => {
    const { editUserDetails, updateUser } = this.props;

    updateUser().then(userData => {
      // success
      if (userData) {
        const { car, driver_licenses } = userData;
        this.setState({
          changeset: {},
          previousValue: { car, driver_licenses }
        });
      } else {
        this.setState({ changeset: {} });
        editUserDetails(this.state.previousValue);
      }
    });
  }
}

const mapState = (state, ownProps) => {
  const { user } = state;

  return {
    userData: user.data,
  };
};

export default connect(mapState, {
  editUserDetails,
  updateUser,
})(JobProfileVehiclesContainer);
