import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { markNotificationsReadByType } from '../actions/notifications';

export default type => WrappedComponent => {
  function NotificationClearing(props) {
    const _onWillBlur = () => {
      const { markNotificationsReadByType } = props;

      markNotificationsReadByType(type);
    }

    useEffect(() => {
      const willBlurSubscription = props.navigation.addListener('blur', _onWillBlur);
      return willBlurSubscription;
    }, [props.navigation])

    return <WrappedComponent {...props} />;
  };

  const mapDispatch = {
    markNotificationsReadByType,
  };

  return connect(null, mapDispatch)(NotificationClearing);
};
