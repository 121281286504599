import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  updating: false,
  items: [],
  itemGroups: [],
  selectedItems: [],
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_TAGS_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        items: action.payload.items,
        itemGroups: action.payload.itemGroups,
        selectedItems: action.payload.selectedItems,
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_TAGS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case ADD_TAG:
      return Object.assign({}, state, {
        selectedItems: [
          ...state.selectedItems,
          action.payload.id,
        ]
      });
    case REMOVE_TAG:
      return Object.assign({}, state, {
        selectedItems: state.selectedItems.filter(s => s !== action.payload.id),
      });
    case UPDATE_TAG_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      });
    case UPDATE_TAG_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
      });
    case UPDATE_TAG_FAILURE:
      return Object.assign({}, state, {
        updating: false,
      });
    default:
      return state;
  }
}
