import React from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import * as Icon from '@expo/vector-icons';
import { CheckBox } from 'react-native-elements';

type Props = {
  label: string;
  subOptions: any[];
  checkedSubOptions: any[];
  onChangeSubOption: (option: number) => void;
}

export default class LineItemFoldOut extends React.PureComponent<Props> {
  state = {
    expanded: false,
  }

  onPress = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const {
      label,
      subOptions,
      checkedSubOptions,
      onChangeSubOption,
    } = this.props;

    const {
      expanded,
    } = this.state;

    const amountOfCheckedSubOptions = subOptions.filter(subOption => checkedSubOptions.includes(subOption.id))

    return (
      <TouchableOpacity onPress={ this.onPress }>
        <View style={ styles.container }>
          <View style={ styles.lineHeader }>
            <Text style={ [styles.text, expanded && styles.highlightedText] }>{label}</Text>
            <View style={ styles.iconContainer }>
              { amountOfCheckedSubOptions.length > 0 && 
                <View style={ styles.filterIndicator }>
                  <Text style={ [styles.filterIndicatorText, Platform.OS === 'ios' && { lineHeight: 0 }] }>{amountOfCheckedSubOptions.length}</Text>
                </View>
              }
              <Icon.FontAwesome
                name={expanded ? 'angle-up' : 'angle-down'}
                size={32}
                style={{ marginLeft: 8, marginTop: -7, color: Colors.secondaryText }}
              />
            </View>
          </View>
          { !!expanded &&
            <View style={ styles.subOptionContainer}>
              { subOptions.map(option => (
                <TouchableOpacity key={ option.id } onPress={ () => onChangeSubOption(option.id) }>
                  <View style={ styles.subOptionRow }>
                    <CheckBox
                      checked={ checkedSubOptions.includes(option.id) }
                      onPress={ () => onChangeSubOption(option.id) }
                      checkedIcon={ 'check' }
                      uncheckedIcon={ 'square' }
                      uncheckedColor={ Colors.veryWhite }
                      checkedColor={ Colors.veryWhite }
                      containerStyle={ [styles.checkbox, {
                        backgroundColor: checkedSubOptions.includes(option.id) ? Colors.primaryBackground : Colors.veryWhite,
                      }] }
                      size={ 18 }
                    />
                    <Text style={ styles.text }>{option.description}</Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          }
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderColor: Colors.border,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  lineHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontFamily: Fonts.regular,
    fontSize: 16,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterIndicator: {
    height: 20,
    width: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primaryBackground,
    marginRight: 4,
  },
  filterIndicatorText: {
    fontFamily: Fonts.bold,
    fontSize: 14,
    color: Colors.veryWhite,
  },
  highlightedText: {
    fontFamily: Fonts.bold,
  },
  subOptionContainer: {
    marginTop: 32,
  },
  subOptionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 22,
  },
  checkbox: {
    marginRight: 24,
    height: 24,
    width: 24,
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.border,
  },
})