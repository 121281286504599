import React from 'react';
import {
  StyleSheet,
  ScrollView,
} from 'react-native';

import Colors from '../constants/Colors';

export default (props) => {
  return (
    <ScrollView {...props} style={[styles.container, props.style]} contentContainerStyle={styles.contentContainer}>
      {props.children}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.appBackground,
  },
  contentContainer: {
    paddingBottom: 24,
  },
});
