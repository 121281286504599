import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

import Loader from './Loader';

export default WrappedComponent => {
  return class withLoader extends React.PureComponent {
    render() {
      const { loading } = this.props;

      if (loading) {
        return <Loader />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 250,
  }
});
