import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import moment from 'moment';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import { PrimaryButton } from './Button';
import Text from './Text';

export default (props) => {
  const {
    header,
    title,
    text,
    image,
    date,
    actionTitle,
    onPress,
    headerStyles,
  } = props;

  return (
    <View style={styles.container}>
      <Text style={[styles.header, headerStyles]}>
        {header}
      </Text>
      {image &&
        <Image
          style={styles.image}
          source={{ uri: image }}
        />
      }
      <View style={styles.content}>
        <Text style={styles.title}>
          {title}
        </Text>
        <Text style={styles.text}>
          {text}
        </Text>
        { !!date &&
          <Text style={styles.date}>
            {moment(date).fromNow().toUpperCase()}
          </Text>
        }
        <PrimaryButton onPress={onPress} title={actionTitle} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    marginTop: 24,
    marginHorizontal: 16,
    borderRadius: 10,
    overflow: 'hidden',
  },
  header: {
    backgroundColor: Colors.tintColor,
    color: '#fff',
    textAlign: 'center',
    fontFamily: Fonts.emphasis,
    fontSize: 14,
    padding: 8,
  },
  image: {
    width: '100%',
    height: 160,
    resizeMode: 'cover',
  },
  content: {
    padding: 16,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: Colors.border,
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 18,
  },
  text: {
    color: Colors.secondaryText,
    marginBottom: 12,
  },
  date: {
    fontFamily: Fonts.regular,
    fontSize: 12,
    color: Colors.secondaryText,
    marginBottom: 16,
  },
});
