import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';

import {
  GET_PUSH_TOKEN_REQUEST,
  GET_PUSH_TOKEN_SUCCESS,
  GET_PUSH_TOKEN_FAILURE,
  UPDATE_PUSH_TOKEN_REQUEST,
  UPDATE_PUSH_TOKEN_SUCCESS,
  UPDATE_PUSH_TOKEN_FAILURE,
  CLEAR_PUSH_TOKEN,
} from '../constants/ActionTypes';
import { POST } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const getPushTokenRequest = {
  type: GET_PUSH_TOKEN_REQUEST,
};

const getPushTokenSuccess = token => {
  return {
    type: GET_PUSH_TOKEN_SUCCESS,
    payload: {
      token,
    },
  };
};

const getPushTokenFailure = {
  type: GET_PUSH_TOKEN_FAILURE,
};

const updatePushTokenRequest = {
  type: UPDATE_PUSH_TOKEN_REQUEST,
};

const updatePushTokenSuccess = token => {
  return {
    type: UPDATE_PUSH_TOKEN_SUCCESS,
    payload: {
      token,
    },
  };
};

const updatePushTokenFailure = {
  type: UPDATE_PUSH_TOKEN_FAILURE,
};

const clearPushToken = {
  type: CLEAR_PUSH_TOKEN,
};

export const getPushToken = () => async dispatch => {
  dispatch(getPushTokenRequest);

  const { status: existingStatus } = await Permissions.getAsync(
    Permissions.NOTIFICATIONS
  );
  let finalStatus = existingStatus;

  // only ask if permissions have not already been determined, because
  // iOS won't necessarily prompt the user a second time.
  if (existingStatus !== 'granted') {
    // Android remote notification permissions are granted during the app
    // install, so this will only ask on iOS
    const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
    finalStatus = status;
  }

  if (finalStatus !== 'granted') {
    dispatch(getPushTokenFailure);
    return false;
  }

  const token = await Notifications.getExpoPushTokenAsync();
  dispatch(getPushTokenSuccess(token));
  return true;
};

const updatePushToken = (add, token) => async dispatch => {
  dispatch(updatePushTokenRequest);

  try {
    const expo_token = token?.data;
    const endpoint = add ? 'subscribe' : 'unsubscribe';
    const res = await POST(`/exponent/devices/${endpoint}`, { expo_token });
    if (!res.ok) throw res;

    const resToken = res.json.deleted ? null : res.json.expo_token;

    dispatch(updatePushTokenSuccess(resToken));
  } catch (res) {
    dispatch(updatePushTokenFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldGetPushToken = state => {
  const { asked } = state.pushToken;
  return !asked;
}

const shouldUpdatePushToken = state => {
  const { token, granted, updated } = state.pushToken;
  return token && granted && !updated;
}

export const getUpdatePushTokenIfNeeded = () => async (dispatch, getState) => {
  if (shouldGetPushToken(getState())) {
    await dispatch(getPushToken());
    const { token, granted } = getState().pushToken;

    if (shouldUpdatePushToken(getState())) {
      dispatch(updatePushToken(true, token));
    }
  }
};

export const clearUpdatePushToken = () => async (dispatch, getState) => {
  const { token } = getState().pushToken;

  if (token) {
    dispatch(updatePushToken(false, token));
  }

  dispatch(clearPushToken);
};

