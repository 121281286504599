import React from 'react';
import { SectionList } from 'react-native';
import { connect } from 'react-redux';

import SectionHeader from '../components/SectionHeader';
import ListItemCheckbox from '../components/ListItemCheckbox';
import ListItemSeparator from '../components/ListItemSeparator';
import { addService, removeService, updateService } from '../actions/services';
import { groupedToKVArray, flattenCategory, groupBy } from '../helpers/array';
import Text from '../components/Text';
import HelpText from '../components/HelpText';

class JobProfileServicesContainer extends React.Component {
  render() {
    const { items, selectedItems } = this.props;

    const listSections = items.map(({ key, value }) => {
      return {
        title: key,
        data: value.map(item => ({
          id: item.id,
          name: item.name,
          selected: selectedItems.includes(item.id),
        })),
      };
    });

    return (
      <SectionList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => `${item.id}`}
        sections={listSections}
        ListHeaderComponent={<HelpText text={'Nedan kan du välja de tjänster du känner dig bekväm med att utföra. Dina val används av Veteranpoolen för att välja ut lämpliga uppdrag åt dig.'} />}
        renderItem={this._renderItem}
        renderSectionHeader={this._renderSectionHeader}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderSectionHeader = ({ section: { title } }) => {
    return (
      <SectionHeader title={title} />
    );
  }

  _renderItem = ({ item, index, section }) => {
    return (
      <ListItemCheckbox
        label={item.name}
        checked={item.selected}
        onChange={this._onChange(item)}
      />
    );
  }

  _onChange = ({ id, name }) => ({ checked }) => {
    const { addService, removeService, updateService } = this.props;
    const service = {
      id,
      name,
    };

    if (checked) {
      addService(service);
    } else {
      removeService(service);
    }

    updateService(service, checked);
  }
}

const mapState = state => {
  const { services } = state;
  const items = groupedToKVArray(
      groupBy(flattenCategory(services.items), 'category')
    ).sort((a, b) => a.key.localeCompare(b.key));

  return {
    items,
    selectedItems: services.selectedItems,
  };
};

export default connect(mapState, {
  addService,
  removeService,
  updateService,
})(JobProfileServicesContainer);
