import {
  FETCH_OFFICES_REQUEST,
  FETCH_OFFICES_SUCCESS,
  FETCH_OFFICES_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  items: [],
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_OFFICES_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_OFFICES_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        items: [
          ...action.payload.items,
        ],
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_OFFICES_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    default:
      return state;
  }
}
