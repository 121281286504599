import React, { useState } from 'react';
import { View, StyleSheet, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import * as Icon from '@expo/vector-icons';
import Colors from '../constants/Colors';
import InputButton from './InputButton';
import Text from './Text';
import Fonts from '../constants/Fonts';
import TextButton from './TextButton';
import Modal from './Modal';
import LineItem from './LineItem';
import { CostType } from '../constants/types';

type Props = {
  cost: string;
  description: string;
  onClose: () => void;
  costType: CostType;
  onChangeCost: (input: string) => void;
  onChangeDescription: (input: string) => void;
  onSave: (addNew: boolean) => void;
  onChangeType: (item: CostType) => void;
  costTypes: CostType[];
}

const ExpenseInput = ({ cost, description, onClose, costType, onChangeCost, onChangeDescription, onSave, onChangeType, costTypes }: Props) => {
  const [costTypeModalOpen, toggleCostTypeModal] = useState(false);

  const onChange = (item: CostType) => {
    toggleCostTypeModal(false);
    onChangeType(item);
  };

  return (
    <View style={ styles.container }>
      <InputButton
        labelStyle={ { marginLeft: 4 } }
        iconName={ 'sort' }
        label={ 'Typ' }
        onPress={ () => toggleCostTypeModal(true) }
        value={ costType.title }
      />
      { !!costType && <Text style={ styles.information }>
        { costType.message }
      </Text> }
      { !!costType.unit && 
        <>
          <Text style={ styles.label }>
            Kostnad
          </Text>
          <View style={ styles.costInputContainer }>
            <View style={ styles.costInput }>
              <TextInput 
                placeholder={ '0' } 
                placeholderTextColor={ Colors.borderDark } 
                value={ cost } 
                onChangeText={ onChangeCost }/>
            </View>
            <Text>
              { costType.unit }
            </Text>
          </View>
        </>
      }
      {
        costType.id !== 4 &&
        <>
          <Text style={ styles.label }>
            Beskriv utlägget
          </Text>
          <View style={ styles.descriptionInput }>
            <TextInput
              style={ { flex: 1 } }
              multiline={ true }
              placeholder={ 'Skriv här...' }
              placeholderTextColor={ Colors.borderDark }
              value={ description }
              onChangeText={ onChangeDescription }
            />
          </View>
        </>
      }
      <View style={ styles.addContainer }>
        <TextButton
          iconName={ 'plus-circle' }
          label={ 'Lägg till nytt utlägg' }
          labelColor={ Colors.primaryBackground }
          onPress={ () => onSave(true) }/>
      </View>
      <View style={ styles.closeButton }>
        <TouchableOpacity onPress={ onClose }>
          <Icon.AntDesign
            size={ 22 }
            color={ Colors.primaryBackground }
            name={ 'closecircle' }
          />
        </TouchableOpacity>
      </View>
      <Modal
        onClose={ () => toggleCostTypeModal(false) }
        visible={ costTypeModalOpen }
        title={ 'Utläggstyp' }
      >
        <ScrollView>
          { costTypes.map((option: CostType) => 
            <LineItem.Simple
              key={ option.id }
              label={ option.title }
              value={ option }
              checked={ costType.id === option.id }
              onChange={ onChange }
            />
          )}
        </ScrollView>
      </Modal>
    </View>
  )
}

export default ExpenseInput;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingTop: 32,
    borderWidth: 1,
    borderColor: Colors.border,
    marginHorizontal: -12,
    marginBottom: 24,
  },
  label: {
    marginVertical: 8,
    fontFamily: Fonts.medium,
    marginLeft: 4,
  },
  information: {
    marginTop: 8,
    fontSize: 14,
    color: Colors.secondaryText,
  },
  costInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  costInput: {
    width: 124,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border,
    marginRight: 12,
    marginBottom: 8,
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  descriptionInput: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border,
    padding: 16,
    height: 100,
  },
  addContainer: {
    marginTop: 24,
    marginHorizontal: -16,
    padding: 16,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});