import Colors from './Colors';
import Fonts from './Fonts';

export default {
  headerStyle: {
    backgroundColor: Colors.headerBackground,
  },
  headerTintColor: '#fff',
  headerTitleStyle: {
    marginTop: -2,
    fontFamily: Fonts.emphasis,
    fontWeight: '200', // https://github.com/react-navigation/react-navigation/issues/542#issuecomment-283663786
  },
  headerTruncatedBackTitle: 'Bakåt'
};
