import * as Haptics from 'expo-haptics';
import { notification as hapticNotification } from '../helpers/haptic';

import {
  UPDATE_APPLY_JOB_REQUEST,
  UPDATE_APPLY_JOB_SUCCESS,
  UPDATE_APPLY_JOB_FAILURE,
  UPDATE_UNAPPLY_JOB_REQUEST,
  UPDATE_UNAPPLY_JOB_SUCCESS,
  UPDATE_UNAPPLY_JOB_FAILURE,
} from '../constants/ActionTypes';
import { POST } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const updateApplyJobRequest = {
  type: UPDATE_APPLY_JOB_REQUEST,
};

const updateApplyJobSuccess = id => {
  return {
    type: UPDATE_APPLY_JOB_SUCCESS,
    payload: { id },
  };
};

const updateApplyJobFailure = {
  type: UPDATE_APPLY_JOB_FAILURE,
};

export const applyUpdateJob = id => async dispatch => {
  dispatch(updateApplyJobRequest);

  try {
    const res = await POST('/workorders/apply/me', { id });
    if (!res.ok) throw res;

    dispatch(updateApplyJobSuccess(id));
    hapticNotification(Haptics.NotificationFeedbackType.Success);
  } catch (res) {
    dispatch(updateApplyJobFailure);
    reportRequestError(res);
  }
};

const updateUnapplyJobRequest = {
  type: UPDATE_UNAPPLY_JOB_REQUEST,
};

const updateUnapplyJobSuccess = id => {
  return {
    type: UPDATE_UNAPPLY_JOB_SUCCESS,
    payload: { id }
  };
};

const updateUnapplyJobFailure = {
  type: UPDATE_UNAPPLY_JOB_FAILURE
};

export const unapplyUpdateJob = id => async dispatch => {
  dispatch(updateUnapplyJobRequest);

  try {
    const res = await POST('/workorders/unapply/me', { id });
    if (!res.ok) throw res;

    dispatch(updateUnapplyJobSuccess(id));
    hapticNotification(Haptics.NotificationFeedbackType.Success);
  } catch (res) {
    dispatch(updateUnapplyJobFailure);
    reportRequestError(res);
  }
}
