import {
  FETCH_PAYSLIPS_REQUEST,
  FETCH_PAYSLIPS_SUCCESS,
  FETCH_PAYSLIPS_FAILURE,
  FETCH_PAYSLIP_REQUEST,
  FETCH_PAYSLIP_SUCCESS,
  FETCH_PAYSLIP_FAILURE,
  REFRESH_PAYSLIPS,
  PAYSLIPS_CURRENT_PAGE,
} from '../constants/ActionTypes';
import { GET } from '../services/Api';
import { minutesPassed } from '../helpers/time';
import { reportRequestError, reportResponsePayloadError } from '../helpers/request-errors';

const fetchPayslipsRequest = {
  type: FETCH_PAYSLIPS_REQUEST,
};

const fetchPayslipsSuccess = (items, pagination) => {
  return {
    type: FETCH_PAYSLIPS_SUCCESS,
    payload: {
      pagination,
      items,
      fetchedAt: Date.now(),
    },
  };
}

const fetchPayslipsFailure = {
  type: FETCH_PAYSLIPS_FAILURE,
};

const fetchPayslipRequest = {
  type: FETCH_PAYSLIP_REQUEST,
};

const fetchPayslipSuccess = item => {
  return {
    type: FETCH_PAYSLIP_SUCCESS,
    payload: {
      fetchedAt: Date.now(),
      item,
    },
  };
}

const fetchPayslipFailure = {
  type: FETCH_PAYSLIP_FAILURE,
};

export const fetchPayslips = () => async (dispatch, getState) => {
  dispatch(fetchPayslipsRequest);

  try {
    const params = {
      page: getState().payslips.pagination.current_page || 1,
      per_page: 20,
    };
    const res = await GET('/employee/me/payslips', params);
    if (!res.ok) throw res;

    try {
      const { data, ...pagination } = res.json.result;
      dispatch(fetchPayslipsSuccess(data, pagination));
    } catch (err) {
      reportResponsePayloadError(err, res);
    }
  } catch (res) {
    dispatch(fetchPayslipsFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchPayslips = state => {
  const payslips = state.payslips;

  if (!payslips) {
    return true;
  } else if (payslips.fetching) {
    return false;
  } else if (!payslips.fetchedAt) {
    return true;
  } else if (payslips.fetchError) {
    return true;
  } else {
    return false;
  }
}

export const fetchPayslipsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchPayslips(getState())) {
    return dispatch(fetchPayslips());
  }
}

export const fetchPayslip = (id) => async (dispatch, getState) => {
  dispatch(fetchPayslipRequest);

  try {
    const res = await GET(`/employee/me/payslips/${id}`);
    if (!res.ok) throw res;

    const payslip = res.json.result;
    dispatch(fetchPayslipSuccess(payslip));
    return payslip;
  } catch (res) {
    dispatch(fetchPayslipFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchPayslip = (id, state) => {
  const { payslips } = state;
  const payslip = payslips.items.find(p => p.id === id);
  const itemFetchedAt = payslip && payslips.itemsFetchedAt[payslip.id];

  if (!payslip) {
    return true;
  } else if (!payslip.temp_url) {
    return true;
  } else if (itemFetchedAt && minutesPassed(10, itemFetchedAt)) {
    return true;
  } else {
    return false;
  }
}

export const fetchPayslipIfNeeded = (id) => async (dispatch, getState) => {
  const { payslips } = getState();

  if (shouldFetchPayslip(id, getState())) {
    return dispatch(fetchPayslip(id));
  } else {
    return payslips.items.find(p => p.id === id);
  }
}

const refreshPayslips = {
  type: REFRESH_PAYSLIPS,
};

const payslipsCurrentPage = current_page => {
  return {
    type: PAYSLIPS_CURRENT_PAGE,
    payload: { current_page }
  };
};

export const refreshFetchPayslips = () => dispatch => {
  dispatch(refreshPayslips);
  dispatch(payslipsCurrentPage(1));
  return dispatch(fetchPayslips());
};

export const fetchMorePayslips = () => (dispatch, getState) => {
  const { pagination } = getState().payslips;

  if (!pagination.last_page || pagination.last_page === pagination.current_page) {
    return;
  }

  dispatch(payslipsCurrentPage(pagination.current_page + 1));
  return dispatch(fetchPayslips());
};
