import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  ADD_SERVICE,
  REMOVE_SERVICE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  updating: false,
  items: [],
  selectedItems: [],
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_SERVICES_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        items: action.payload.items,
        selectedItems: action.payload.selectedItems,
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_SERVICES_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case ADD_SERVICE:
      return Object.assign({}, state, {
        selectedItems: [
          ...state.selectedItems,
          action.payload.id,
        ]
      });
    case REMOVE_SERVICE:
      return Object.assign({}, state, {
        selectedItems: state.selectedItems.filter(s => s !== action.payload.id),
      });
    case UPDATE_SERVICE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      });
    case UPDATE_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
      });
    case UPDATE_SERVICE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
      });
    default:
      return state;
  }
}
