import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import Text from './Text';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

const buttonTypes = {
  Primary: 1,
  Secondary: 2,
  Inverted: 3,
};

export const PrimaryButton = (props) => {
  return (
    <Button {...props} type={buttonTypes.Primary}></Button>
  );
};

export const SecondaryButton = (props) => {
  return (
    <Button {...props} type={buttonTypes.Secondary}></Button>
  );
};

export const InvertedButton = (props) => {
  return (
    <Button {...props} type={buttonTypes.Inverted}></Button>
  );
};

export const Button = (props) => {
  const {
    accessibilityLabel,
    hasTVPreferredFocus,
    type,
    onPress,
    title,
    disabled,
    testID,
    style,
    icon,
    activeOpacity,
  } = props;

  const buttonStyles = [styles.button];
  const textStyles = [styles.text];
  const accessibilityStates = [];

  if (type === buttonTypes.Primary) {
    buttonStyles.push(primaryStyles.button);
    textStyles.push(primaryStyles.text);
  } else if (type === buttonTypes.Secondary) {
    buttonStyles.push(secondaryStyles.button);
    textStyles.push(secondaryStyles.text);
  } else if (type === buttonTypes.Inverted) {
    buttonStyles.push(invertedStyles.button);
    textStyles.push(invertedStyles.text);
  }

  if (disabled) {
    buttonStyles.push(disabledStyles.button);
    textStyles.push(disabledStyles.text);
    accessibilityStates.push('disabled');
  }

  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      accessibilityStates={accessibilityStates}
      hasTVPreferredFocus={hasTVPreferredFocus}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      style={styles.container}
      activeOpacity={activeOpacity}>
      <View style={[buttonStyles, style]}>
        <Text adjustsFontSizeToFit numberOfLines={1} style={textStyles} disabled={disabled}>
          {title}
        </Text>
        {icon && (
          <View style={styles.buttonIcon}>
            {icon}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginLeft: 6,
    minWidth: 26,
  },
  text: {
    padding: 10,
  },
});

const disabledStyles = StyleSheet.create({
  button: {
    opacity: 0.5,
  },
  text: {},
});

const primaryStyles = StyleSheet.create({
  button: {
    backgroundColor: Colors.primaryBackground,
    padding: 10,
    borderRadius: 10,
  },
  text: {
    color: Colors.primaryText,
    fontFamily: Fonts.emphasis,
  },
});

const secondaryStyles = StyleSheet.create({
  button: {},
  text: {
    color: Colors.secondaryText,
    textDecorationLine: 'underline',
  },
});

const invertedStyles = StyleSheet.create({
  button: {
    borderWidth: 2,
    borderColor: Colors.primaryBackground,
    backgroundColor: Colors.veryWhite,
    padding: 10,
    borderRadius: 10,
  },
  text: {
    color: Colors.primaryBackground,
    fontFamily: Fonts.emphasis,
    fontSize: 15,
  },
});
