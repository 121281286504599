import { CommonActions } from '@react-navigation/native';
let _navigator;
let _queuedNavigation;

const navigate = (routeName, params) => {
  if (!_navigator) {
    _queuedNavigation = { routeName, params };
    return;
  }
  _navigator.dispatch(
    CommonActions.navigate({
      name: routeName,
      params,
    })
  );
}

export default {
  navigate,
};
