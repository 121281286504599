import React from 'react';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import { Alert, View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import Modal from './Modal';
import Text from './Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

type Props = {
  selectItem: (item?: any) => void;
  visible: boolean;
  onClose: () => void;
}

export default ({ selectItem, visible, onClose }: Props) => {

  const getPermission = async () => {
    const permission = await Permissions.getAsync(Permissions.CAMERA);

    if (permission.status !== 'granted') {
      const status = await Permissions.askAsync(Permissions.CAMERA);

      if (status.status !== 'granted') return false;
    }

    const permissionCameraRoll = await Permissions.getAsync(Permissions.MEDIA_LIBRARY);

    if (permissionCameraRoll.status !== "granted") {
      const statusCameraRoll = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (statusCameraRoll.status !== "granted") return false;
    }
    return true;
  }

  const openFileSelector = async (isImage: boolean = false ) => {
    const res = await DocumentPicker.getDocumentAsync({
      type: isImage ? 'image/*' : 'application/pdf',
    });

    if (res && res.type === 'success') {
      selectItem(res)
    } else {
      selectItem()
    }
  }

  const openImagePicker = async (imagePickerType: Permissions.PermissionType) => {
    try {

      const permission = await getPermission();
      
      if (permission) {
        let res;
        
        if (imagePickerType === Permissions.CAMERA) {
          res = await ImagePicker.launchCameraAsync({
            allowsEditing: true, 
          })
        } else if (imagePickerType === Permissions.MEDIA_LIBRARY) {
          res = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true, 
          })
        }

        if (res && res.cancelled === false) {
          selectItem(res)
        } else {
          selectItem()
        }
      } else Alert.alert('Rättigheter nekade', 'För att kunna lägga in bilder måste Veteranpoolens app ha tillgång till ditt bildgalleri och din kamera')
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Modal
      title={ 'Lägg till fil/ta bild' }
      visible={ visible }
      onClose={ onClose }
      onSubmit={ onClose }
    >
      <View style={ styles.attachmentSelectList }>
      { Platform.OS !== 'web' && <TouchableOpacity onPress={ () => openImagePicker(Permissions.CAMERA) }>
          <View style={ styles.attachmentSelect }>
            <Text style={ styles.attachmentSelectText }>Ta bild</Text>
          </View>
        </TouchableOpacity> }
        <TouchableOpacity onPress={ () => Platform.OS !== 'web' ? openImagePicker(Permissions.MEDIA_LIBRARY) : openFileSelector(true) }>
          <View style={ styles.attachmentSelect }>
            <Text style={ styles.attachmentSelectText }>Välj bild från galleri</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={ () => openFileSelector() }>
          <View style={ styles.attachmentSelect }>
            <Text style={ styles.attachmentSelectText }>Välj fil</Text>
          </View>
        </TouchableOpacity>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  attachmentSelectList: {
  },
  attachmentSelect: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  attachmentSelectText: {
    fontFamily: Fonts.emphasis,
  }
})