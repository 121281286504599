import React, { useEffect } from 'react';

export default WrappedComponent => {
  return function WithFetchComponent(props) {
    
    const _onWillFocus = () => {
      props.fetch();
    }

    useEffect(() => {
      const willFocusSubscription = props.navigation.addListener('focus', _onWillFocus);
      return willFocusSubscription;
    }, [props.navigation]);

    return <WrappedComponent {...props} />;
  };
};
