import React from 'react';
import { View, StyleSheet, ScrollView, TextInput, ActivityIndicator, Alert, Platform, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Text from '../components/Text';
import { PrimaryButton, SecondaryButton } from '../components/Button.js';
import Fonts from '../constants/Fonts';
import JobDisplay from '../containers/JobDisplay';
import Colors from '../constants/Colors';
import { GET, POST, DELETE, PUT } from '../services/Api';
import Loader from '../components/Loader';
import { Customer, JobItem, User, TimeReport, Navigation, OngoingJobsStackList, CostType } from 'constants/types';
import TimeReportLines from '../components/TimeReportLines';
import { reportRequestError } from '../helpers/request-errors';
import { UserInterestStatus, WorkorderStatus } from '../constants/Statuses';
import { fetchCostTypes } from '../actions/time-reports';
import KeyboardViewContainer from '../components/KeyboardViewContainer';
import * as Linking from 'expo-linking';
import { RouteProp } from '@react-navigation/native';
import * as Icon from '@expo/vector-icons';
import { NavigationEventSubscription } from 'react-navigation';

type OngoingJobsScreenRouteProp = RouteProp<OngoingJobsStackList, 'OngoingJob'>;

type State = {
  customer: Customer | null;
  comment: string;
  timeReport: TimeReport | null;
  loading: boolean;
  workorderOpen: boolean;
  expenseRowOpen: null | number;
  initialLoadDone: boolean;
}

type ComponentProps = {
  navigation: Navigation;
  route: OngoingJobsScreenRouteProp,
}

type ConnectedProps = {
  selectedJob: JobItem;
  currentUser: User;
  costTypes: CostType[],
}

type DispatchedProps = {
  fetchCostTypes: typeof fetchCostTypes;
}

type Props = ComponentProps & ConnectedProps & DispatchedProps;

class OngoingJobScreen extends React.PureComponent<Props, State> {

  state: State = {
    customer: null,
    comment: '',
    timeReport: null,
    loading: false,
    workorderOpen: true,
    expenseRowOpen: null,
    initialLoadDone: false,
  }

  unsubscribe: any;


  async componentDidMount() {
    const { navigation, fetchCostTypes } = this.props;
    this.setState({ loading: true });
    await this.getCustomer();
    await fetchCostTypes();
    await this.initialTimeReportLoad();

    this.unsubscribe = navigation.addListener('focus', async () => {
      await this.initialTimeReportLoad();
    });
    this.setState({ loading: false });
    this.setState({ initialLoadDone: true });
  }

  initialTimeReportLoad = async () => {
    const { selectedJob } = this.props;

    if (selectedJob.status === WorkorderStatus.COMPLETED || selectedJob.workorder_lines[0].user_interest_status === UserInterestStatus.DONE) {
      this.setState({ workorderOpen: false });
      await this.getClosedTimeReport();
    } else {
      this.setState({ workorderOpen: true });
      await this.getTimeReport();
    }
  }

  componentWillUnmount() {
    this.unsubscribe?.();
  }

  openPhoneNumber = (phone: string) => {
    Linking.openURL(`tel:${ phone }`);
  }

  openEmail = (email: string) => {
    Linking.openURL(`mailto:${ email }`);
  }

  getCustomer = async () => {
    const { selectedJob, navigation } = this.props;

    try {
      const res = await GET(`/clients/${ selectedJob.client_id }`);

      if (!res.ok) {
        if (Platform.OS === 'web') {
          window.alert('Tekniskt fel - Kunde inte ladda uppgifter, var god försök igen');
        } else {
          Alert.alert('Tekniskt fel', 'Kunde inte ladda uppgifter, var god försök igen');
        }
        navigation.goBack();
      }

      this.setState({ customer: res.json.result });
    } catch (error) {
      reportRequestError(error);
    }
  }

  getTimeReport = async () => {
    const { selectedJob } = this.props;

    try {
      const fetchTimeReport = await POST('/timereport/create', { workorder_id: selectedJob.id });
      this.setState({ timeReport: fetchTimeReport.json.result });
    } catch (err) {
      reportRequestError(err);
    }
  }

  getClosedTimeReport = async () => {
    const { selectedJob } = this.props;

    try {
      const fetchTimeReport = await GET(`/timereport/summary/${ selectedJob.id }`);
      this.setState({ timeReport: fetchTimeReport.json.result });
    } catch (err) {
      reportRequestError(err);
    }
  }

  onInputChange = (input: string) => {
    if (input.length < 5001) {
      this.setState({ comment: input });
    }
  }

  onDeleteTimeReportLine = async (id: number) => {
    if (Platform.OS === 'web') {
      const result = window.confirm('Ta bort datum - Är du säker på att du vill ta bort det här sparade datumet?');
      if (result) {
        try {
          await DELETE(`/timereport/line/${ id }`);
          this.setState({ loading: true });
          await this.getTimeReport();
        } catch (error) {
          reportRequestError(error);
        } finally {
          this.setState({ loading: false });
        }
      }
    } else {
      Alert.alert('Ta bort datum', 'Är du säker på att du vill ta bort det här sparade datumet?', [
        { text: 'Avbryt', style: 'cancel' },
        {
          text: 'Radera',
          onPress: async () => {
            try {
              await DELETE(`/timereport/line/${ id }`);
              this.setState({ loading: true });
              await this.getTimeReport();
            } catch (error) {
              reportRequestError(error);
            } finally {
              this.setState({ loading: false });
            }
          }
        }
      ]);
    }
  }

  postTimeReport = async () => {
    const { timeReport, comment } = this.state;
    const report = {
      status: 2000,
      comment,
    };
    if (timeReport && timeReport.timereport_lines.length > 0) {
      if (Platform.OS === 'web') {
        const result = window.confirm('Skicka in tidrapport: Är du säker på att du vill skicka in månadens tidrapport?');
        if (result) {
          try {
            this.setState({ loading: true });
            await PUT(`/timereport/${ timeReport.id }/update`, report);
            window.alert('Tidrapport inskickad - Tack för din tidrapport. Om uppdraget är avklarat behöver du avsluta uppdrag.');
            await this.getTimeReport();
            this.setState({ loading: false });
          } catch (error) {
            reportRequestError(error);
            this.setState({ loading: false });
          }
        }
      } else {
        await Alert.alert('Skicka in tidrapport', 'Är du säker på att du vill skicka in månadens tidrapport?',
          [
            { text: 'Avbryt', style: 'cancel' },
            {
              text: 'Skicka',
              onPress: async () => {
                try {
                  this.setState({ loading: true });
                  await PUT(`/timereport/${ timeReport.id }/update`, report);
                  Alert.alert('Tidrapport inskickad', 'Tack för din tidrapport. Om uppdraget är avklarat behöver du avsluta uppdrag.');
                  await this.getTimeReport();
                  this.setState({ loading: false });
                } catch (error) {
                  reportRequestError(error);
                  this.setState({ loading: false });
                }
              }
            },
          ]
        );
      }
    }  else {
      if (Platform.OS === 'web') {
        window.alert('Skicka in tidrapport - Du kan inte skicka in en tidrapport för ett uppdrag som inte har någon tid inlagd.');
      } else {
        Alert.alert('Skicka in tidrapport', 'Du kan inte skicka in en tidrapport för ett uppdrag som inte har någon tid inlagd.');
      }
    }
  }

  finishWorkOrder = async () => {
    const { timeReport } = this.state;
    const { navigation, selectedJob } = this.props;

    if (timeReport && timeReport.timereport_lines.length < 1) {
      if (Platform.OS === 'web') {
        const result = window.confirm('Avsluta uppdrag - Är du säker på att du vill avsluta uppdraget?');
        if (result) {
          try {
            this.setState({ loading: true });
            await POST('/workorders/done/me', { id: selectedJob.id });

            window.alert('Uppdrag avslutat - Uppdraget ses nu som avslutat.');
            this.setState({ loading: false });
            navigation.goBack();
          } catch (error) {
            reportRequestError(error);
            this.setState({ loading: false });
          }
        }
      } else {
        await Alert.alert('Avsluta uppdrag', 'Är du säker på att du vill avsluta uppdraget?',
          [
            { text: 'Avbryt', style: 'cancel' },
            {
              text: 'Skicka',
              onPress: async () => {

                try {
                  this.setState({ loading: true });
                  await POST('/workorders/done/me', { id: selectedJob.id });

                  navigation.goBack();
                  this.setState({ loading: false });
                  Alert.alert('Uppdrag avslutat', 'Uppdraget ses nu som avslutat.');
                } catch (error) {
                  reportRequestError(error);
                  this.setState({ loading: false });
                }
              },
            },
          ]
        );
      }
    } else {
      if (Platform.OS === 'web') {
        window.alert('Avsluta uppdrag - Du har fortfarande sparad tid som inte är rapporterad, var vänlig skicka in tidrapporten för att kunna avsluta uppdraget');
      } else {
        Alert.alert('Avsluta uppdrag', 'Du har fortfarande sparad tid som inte är rapporterad, var vänlig skicka in tidrapporten för att kunna avsluta uppdraget');
      }
    }
  }

  render() {
    const { selectedJob, currentUser, navigation, costTypes } = this.props;
    const { customer, comment, timeReport, loading, workorderOpen, expenseRowOpen, initialLoadDone } = this.state;
    const amountOfCostAdditions = timeReport?.timereport_lines?.reduce((acc, curr) => acc + curr.timereport_line_costs.length, 0) ?? 0;

    if (initialLoadDone && selectedJob) {
      return(
        <View style={ styles.container }>
          <KeyboardViewContainer behaviour={ 'position' }>
            <ScrollView>
              <View style={ [styles.topContainer, (!workorderOpen || selectedJob.workorder_lines.length !== 1) && { paddingBottom: 0 }] }>
                <JobDisplay
                  id={ selectedJob.workorder_number }
                  title={ selectedJob.title }
                  subTitle={ selectedJob.office.name }
                />
                { (workorderOpen && selectedJob.workorder_lines.length === 1) && <PrimaryButton
                  title={ timeReport?.timereport_lines?.length > 0 ? 'Lägg till tid' : 'Rapportera tid' }
                  onPress={ () => navigation.navigate('ReportTime', { jobId: selectedJob.id, timeReportId: timeReport.id, workorderLineId: selectedJob.workorder_lines[0].id }) }
                /> }
              </View>
              <View style={ styles.topDivider }/>
              <View style={ styles.contentContainer }>
                {
                  selectedJob.workorder_lines.map(wo =>(
                    <View key={ wo.id }>
                      <Text style={ [styles.contactInfoHeading, {marginBottom: 0}] }>
                        {wo.service?.description ?? ''}
                      </Text>
                      <Text style={ styles.description }>
                        { wo.note || '' }
                      </Text>
                      <Text style={ styles.description }>
                        { wo.description || '' }
                      </Text>
                      { (workorderOpen && selectedJob.workorder_lines.length > 1) && <PrimaryButton
                        title={ timeReport.timereport_lines.filter(tl => tl.workorder_line_id === wo.id).length > 0 ? 'Lägg till tid' : 'Rapportera tid' }
                        style={ { marginBottom: 30 } }
                        onPress={ () => navigation.navigate('ReportTime', { jobId: selectedJob.id, timeReportId: timeReport.id, workorderLineId: wo.id }) }
                      /> }
                    </View>
                    
                  ))
                }

                <View style={ [styles.contactInfoContainer, { marginBottom: 32, paddingBottom: 32, borderBottomWidth: 1, borderColor: Colors.border }] }>
                  <Text style={ styles.contactInfoHeading }>
                    Veteran
                  </Text>
                  <Text style={ styles.contactInfoText }>
                    {`${ currentUser.first_name } ${ currentUser.last_name }`}
                  </Text>
                  <Text style={ styles.contactInfoText }>
                    { `Anställningsnummer: ${ currentUser.number }` }
                  </Text>
                </View>
                { selectedJob.workorder_lines[0].assigned_users.length > 0 && <View style={ styles.otherVeteransContainer }>
                  <Text style={ styles.otherVeteranHeading }>
                    Andra veteraner i detta uppdrag
                  </Text>
                  {
                    selectedJob.workorder_lines[0].assigned_users.map(user => (
                      <View key={ user.id } style={ styles.otherVeteran }>
                        <Text style={ styles.contactInfoText }>
                          {`${ user.first_name } ${ user.last_name }`}
                        </Text>
                        <Text style={ styles.contactInfoText }>
                          { `Anställningsnummer: ${ user.employee_number }` }
                        </Text>
                        <TouchableOpacity onPress={ () => this.openPhoneNumber(user.mobile) }>
                          <Text style={ styles.contactInfoPhoneLink }>
                            { user.mobile }
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ))
                  }

                </View>}
                { !!selectedJob?.client_contact && <>
                  <View style={ [styles.contactInfoContainer, { paddingBottom: 24 }]  }>
                    <Text style={ styles.contactInfoHeading }>
                      Kontakt
                    </Text>
                    <Text style={ styles.contactInfoText }>
                      { `${ selectedJob.client_contact.first_name } ${ selectedJob.client_contact.last_name }` }
                    </Text>
                    {
                      !!selectedJob.client_contact.mobile &&
                      <TouchableOpacity onPress={ () => this.openPhoneNumber(selectedJob.client_contact.mobile) }>
                        <Text style={ styles.contactInfoPhoneLink }>
                          { selectedJob.client_contact.mobile }
                        </Text>
                      </TouchableOpacity>
                    }
                    {
                      (!!selectedJob.client_contact.phone && selectedJob.client_contact.phone !== selectedJob.client_contact.mobile) &&
                      <TouchableOpacity onPress={ () => this.openPhoneNumber(selectedJob.client_contact.phone) }>
                        <Text style={ styles.contactInfoPhoneLink }>
                          { selectedJob.client_contact.phone }
                        </Text>
                      </TouchableOpacity>
                    }
                    {
                      !!selectedJob.client_contact.email &&
                      <TouchableOpacity onPress={ () => this.openEmail(selectedJob.client_contact.email) }>
                        <Text style={ styles.contactInfoEmailLink }>
                          { selectedJob.client_contact.email }
                        </Text>
                      </TouchableOpacity>
                    }
                  </View>
                </>
                }
                { !!customer && <>
                  <View style={ styles.contactInfoContainer }>
                    <Text style={ styles.contactInfoHeading }>
                      Kund
                    </Text>
                    <Text style={ styles.contactInfoText }>
                      { `Kundnummer: ${ customer.number }` }
                    </Text>
                    <Text style={ styles.contactInfoText }>
                      { `${ customer.first_name } ${ customer.last_name }` }
                    </Text>
                    <Text style={ styles.contactInfoText }>
                      { customer.addresses[0].address }
                    </Text>
                    <Text style={ styles.contactInfoText }>
                      { `${ customer.addresses[0].postal_code }, ${ customer.addresses[0].city }` }
                    </Text>
                    {
                      !!customer.mobile &&
                      <TouchableOpacity onPress={ () => this.openPhoneNumber(customer.mobile) }>
                        <Text style={ styles.contactInfoPhoneLink }>
                          { customer.mobile }
                        </Text>
                      </TouchableOpacity>
                    }
                    {
                      (!!customer.phone && customer.phone !== customer.mobile) &&
                      <TouchableOpacity onPress={ () => this.openPhoneNumber(customer.phone) }>
                        <Text style={ styles.contactInfoPhoneLink }>
                          { customer.phone }
                        </Text>
                      </TouchableOpacity>
                    }
                    {
                      !!customer.email &&
                      <TouchableOpacity onPress={ () => this.openEmail(customer.email) }>
                        <Text style={ styles.contactInfoEmailLink }>
                          { customer.email }
                        </Text>
                      </TouchableOpacity>
                    }
                  </View>
                </>
                }
                { !!timeReport && <>
                  <View style={ styles.timeRapportContainer }>
                    <View style={ [styles.timeRapportHeadingContainer, !workorderOpen && styles.timeReportClosedLabel] }>
                      <Text style={ styles.timeRapportHeadingText }>
                        Tidrapportering
                      </Text>
                      { !workorderOpen &&
                        <View style={ styles.finishedTag }>
                          <Text style={ styles.finishedTagText }>
                            Avslutad
                          </Text>
                        </View>
                      }
                    </View>
                    <View style={ styles.timeRapportList }>
                      {
                        timeReport.timereport_lines.length > 0 ?
                        //@ts-ignore
                        
                          timeReport.total_time_workorder_line.map((tt, i) => (
                            <View key={i + '-line-' + tt.workorder_line_id}>
                              <Text style={styles.timeRapportSubHeadingText}>
                                {tt.service_description}
                              </Text>
                              <TimeReportLines
                                lines={timeReport.timereport_lines.filter(t => t.workorder_line_id === tt.workorder_line_id && t.timereport_id === tt.timereport_id)}
                                jobId={selectedJob.id}
                                navigation={navigation}
                                timeReportId={timeReport.id}
                                onDelete={this.onDeleteTimeReportLine}
                                closed={!workorderOpen}
                              />
                            </View>
                          ))
                          :
                          <Text style={ styles.timeRapportEmptyText }>
                            {
                              selectedJob.workorder_lines.some(wl => wl.user_last_timerport) ?
                                `En sammanfattning på din senaste tidsrapport har skickats till din epost och du kan nu rapportera in mer tid!\n\nDin senaste tidrapport skickades in:\n ${ selectedJob.workorder_lines.filter(wl => wl.user_last_timerport)[0].user_last_timerport }` :
                                'Du har inte rapporterat någon tid för det här uppdraget än.'
                            }
                          </Text>
                      }
                    </View>
                    <View style={ styles.totalTimeContainer }>
                      <Text style={ styles.totalTimeText }>
                        Totalt
                      </Text>
                      <Text style={ styles.totalTimeText }>
                        { timeReport.total_time }
                      </Text>
                    </View>
                    {
                      amountOfCostAdditions > 0 &&
                      <View style={ [styles.totalTimeContainer, { marginTop: 16 }] }>
                        <Text style={ styles.totalTimeText }>
                          Totalt antal utlägg
                        </Text>
                        <Text style={ styles.totalTimeText }>
                          { `${ amountOfCostAdditions } st` }
                        </Text>
                      </View>
                    }
                    {
                      timeReport.total_time_workorder_line.map((tt, i) => {
                        const timereportLines = timeReport.timereport_lines.filter(t => t.workorder_line_id === tt.workorder_line_id && t.timereport_id === tt.timereport_id);
                        const totalKm = timereportLines
                          .map(tl =>
                            tl.timereport_line_costs
                              .filter(tlc => tlc.type_id === 4)
                              .reduce((acc, curr) => curr.cost + acc, 0)
                          ).reduce((acc, curr) => acc + curr, 0);

                        return (
                          <View key={ 'expense-' + i + tt.timereport_id }>
                            {
                              timereportLines.map(tl => {
                                return tl.timereport_line_costs.map(tlc => {
                                  const costType = costTypes.find(type => type.id === tlc.type_id);
                                  return (
                                    <View key={ tlc.id } style={ styles.expenseContainer }>
                                      <TouchableOpacity
                                        onPress={ () => this.setState({ expenseRowOpen: expenseRowOpen === tlc.id ? null : tlc.id }) }
                                      >
                                        <View style={ styles.expenseRow }>
                                          <View>
                                            <Text style={ [styles.totalTimeText, { fontFamily: Fonts.medium }] }>
                                              { `${ costType!.title }` }
                                            </Text>
                                            <Text style={ { fontSize: 14 } }>
                                              { tl.day }
                                            </Text>
                                          </View>
                                          <View style={ { alignItems: 'flex-end' } }>
                                            {
                                              costType!.unit &&
                                              <Text style={ [styles.totalTimeText, { fontFamily: Fonts.medium }] }>
                                                { `${ tlc.cost } ${ costType!.unit || 'kr' }` }
                                              </Text>
                                            }
                                            <View style={ { flexDirection: 'row', alignItems: 'center' } }>
                                              <Icon.FontAwesome
                                                name={ expenseRowOpen === tlc.id ? 'minus-circle' : 'plus-circle' }
                                                size={ 16 }
                                                color={ Colors.primaryBackground }
                                                style={ { marginRight: 4 } }
                                              />
                                              <Text style={ { fontSize: 14, color: Colors.primaryBackground, fontFamily: Fonts.emphasis } }>
                                                { expenseRowOpen === tlc.id ? 'Visa mindre' : 'Visa mer' }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </TouchableOpacity>
                                      { expenseRowOpen === tlc.id &&
                                        <View style={ { paddingBottom: 24 } }>
                                          <Text style={ { marginBottom: 24 } }>
                                            { tlc.description }
                                          </Text>
                                          { workorderOpen && <PrimaryButton
                                            title={ 'Redigera utlägg' }
                                            onPress={
                                              () => navigation.navigate(
                                                'ReportTime',
                                                {
                                                  jobId: selectedJob.id,
                                                  timeReportId: timeReport.id,
                                                  lineId: tl.id,
                                                  workorderLineId: tt.workorder_line_id,
                                                }
                                              )
                                            }
                                          /> }
                                        </View>
                                      }
                                    </View>
                                  );
                                });
                              })
                            }
                            {
                              totalKm > 0 &&
                              <View style={ [styles.expenseRow, { borderBottomWidth: 1, borderColor: Colors.border }] }>
                                <Text style={ { fontFamily: Fonts.bold } }>
                                  Total km-ersättning:
                                </Text>
                                <Text style={ { fontFamily: Fonts.bold } }>
                                  { `${ totalKm } km` }
                                </Text>
                              </View>
                            }
                          </View>
                        );
                      })
                    }
                  </View>
                </>
                }
                { !!workorderOpen && 
                  <>
                    <View style={ styles.divider }/>
                    <Text style={ styles.label }>
                      Kommentar
                    </Text>
                    <Text>
                      Är det något annat som kontoret behöver känna till?
                    </Text>
                    <View style={ styles.textInputContainer }>
                      <TextInput
                        style={ { flex: 1 } }
                        onChangeText={ this.onInputChange }
                        value={ comment }
                        multiline={ true }
                        placeholder={ 'Skriv här...' }
                        placeholderTextColor={ Colors.borderDark }
                      />
                    </View> 
                  </>
                }
              </View>
              { !!workorderOpen &&
                <>
                  <View style={ styles.sendReportContainer }>
                    <Text style={ styles.sendReportText }>
                      Kontrollera sammanställningen och skicka in din tidrapport till ditt lokala kontor.
                      Fortlöper uppdraget behöver du ej avsluta uppdrag.
                    </Text>
                    <PrimaryButton title={ 'Skicka in tidrapport' } onPress={ this.postTimeReport }/>
                  </View>
                  <View style={ styles.endContainer }>
                    <Text style={ styles.endText }>
                      Är jobbet klart? Kontrollera sammanställningen och skicka in din tidrapportering till ditt lokala kontor.
                    </Text>
                    <PrimaryButton title={ 'Avsluta uppdrag' } onPress={ this.finishWorkOrder }/>
                    <SecondaryButton style={ { marginTop: 32 } } title={ 'Tillbaka till mina uppdrag' }
                      onPress={ () => this.props.navigation.goBack() }
                    />
                  </View>
                </>
              }
            </ScrollView>
          </KeyboardViewContainer>
          { !!loading && <View style={ styles.inScreenLoader }>
            <ActivityIndicator size={ 'large' } color={ Colors.bodyText } />
          </View> }
        </View>
      );
    } else {
      return (
        <View style={ styles.loadingPage }>
          <Loader />
        </View>
      );
    }
  }

}

const mapStateToProps = (state, ownProps: Props) => {
  const { route } = ownProps;

  const getSelectedJobFrom = (jobsList: Array<JobItem>) => jobsList.find((job: JobItem) => job.id === route.params?.jobId);

  const selectedJob = getSelectedJobFrom(state.jobs.myJobs) || getSelectedJobFrom(state.jobs.myClosedJobs);

  return {
    selectedJob,
    currentUser: state.user.data,
    costTypes: state?.timeReports?.costTypes,
  };
};

export default connect<ConnectedProps, DispatchedProps, ComponentProps>(mapStateToProps, { fetchCostTypes })(OngoingJobScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.veryWhite,
  },
  loadingPage: {
    flex: 1,
    backgroundColor: Colors.appBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topContainer: {
    flexDirection: 'column',
    paddingVertical: 24,
    paddingHorizontal: 24,
    backgroundColor: Colors.veryWhite,
  },
  topDivider: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
    marginHorizontal: 24,
  },
  id: {
    fontFamily: Fonts.medium,
  },
  title: {

  },
  subTitle: {

  },
  contentContainer: {
    padding: 24,
    borderBottomWidth: 1,
    borderColor: Colors.border,
    backgroundColor: Colors.veryWhite,
  },
  description: {
    marginBottom: 12,
  },
  otherVeteransContainer: {
    marginBottom: 32,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  otherVeteranHeading: {
    fontSize: 18,
    fontFamily: Fonts.emphasis,
    marginBottom: 16,
  },
  otherVeteran: {
    marginBottom: 24,
  },
  contactInfoContainer: {
    // marginTop: 24,
  },
  contactInfoHeading: {
    fontSize: 20,
    fontFamily: Fonts.emphasis,
    marginBottom: 8,
  },
  contactInfoText: {
    marginBottom: 2,
  },
  contactInfoPhoneLink: {
    textDecorationLine: 'underline',
    color: Colors.linkText,
  },
  contactInfoEmailLink: {
    textDecorationLine: 'underline',
    color: Colors.linkText,
  },
  timeRapportContainer: {
    marginTop: 24,
  },
  timeReportClosedLabel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  finishedTag: {
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: Colors.primaryBackground,
  },
  finishedTagText: {
    fontFamily: Fonts.emphasis,
    fontSize: 15,
    color: Colors.veryWhite,
  },
  timeRapportHeadingContainer: {
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  timeRapportHeadingText: {
    fontSize: 20,
    fontFamily: Fonts.emphasis,
  },
  timeRapportSubHeadingText: {
    fontSize: 16,
    fontFamily: Fonts.emphasis,
    marginTop: 10,
    marginBottom: -10,
  },
  timeRapportList: {
  },
  timeRapportEmptyText: {
    color: Colors.secondaryText,
    marginVertical: 24,
  },
  totalTimeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  totalTimeText: {
    fontFamily: Fonts.bold,
  },
  expenseContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  expenseRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
  },
  sendReportContainer: {
    backgroundColor: Colors.appBackground,
    paddingVertical: 40,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  sendReportText: {
    marginBottom: 24,
  },
  endContainer: {
    backgroundColor: Colors.veryWhite,
    paddingVertical: 40,
    paddingHorizontal: 24,
  },
  endText: {
    marginBottom: 24,
  },
  divider: {
    height: 24,
  },
  label: {
    fontFamily: Fonts.medium,
    marginBottom: 12,
  },
  textInputContainer: {
    padding: 16,
    borderWidth: 1,
    borderColor: Colors.border,
    borderRadius: 8,
    marginTop: 8,
    height: 100,
  },
  inScreenLoader: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.overlay,
  },
});
