import React from 'react';

import ScreenView from '../components/ScreenView';
import TimeReportContainer from '../containers/TimeReportContainer';

export default class TimeReportScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <TimeReportContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
