import React from 'react';

import ScrollView from '../components/ScrollView';
import MessageContainer from '../containers/MessageContainer';

export default class MessageScreen extends React.Component {
  render() {
    const id = this.props.route.params?.id ?? '';

    return (
      <ScrollView>
        <MessageContainer id={id} navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}
