import { FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE } from "../constants/ActionTypes";
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';
import { Category } from "../constants/types";

const fetchServicesRequest = {
  type: FETCH_CATEGORIES_REQUEST,
};

const fetchCategoriesSuccess = (categories: Category[]) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories,
  };
}

const fetchServicesFailure = {
  type: FETCH_CATEGORIES_FAILURE,
};

export const fetchCategories = () => async dispatch => {
  dispatch(fetchServicesRequest);

  try {
    const fetchCategories = GET('/categories/withservices');

    const categoriesRes = await fetchCategories;

    if (!categoriesRes.ok) throw categoriesRes;

    dispatch(fetchCategoriesSuccess(categoriesRes.json.result));
  } catch (res) {
    dispatch(fetchServicesFailure);
    reportRequestError(res, { showAlert: false });
  }
}