import React from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import Fonts from '../constants/Fonts';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import Text from '../components/Text';
import { logOut } from '../actions/auth';

class LogOutContainer extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.header}>Logga ut från vappen</Text>
        <Text style={styles.text}>Är du säker på att du vill logga ut?</Text>
        <PrimaryButton style={styles.button} title="Logga ut" onPress={this._logOut} />
        <SecondaryButton style={styles.button} title="Avbryt" onPress={this._cancel} />
      </View>
    );
  }

  _logOut = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
  }

  _cancel = () => {
    this.props.navigation.pop();
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  header: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
  },
  text: {
    marginBottom: 24,
  },
  button: {
    marginTop: 8,
  },
});

export default connect()(LogOutContainer);
