import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  REMOVE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
} from '../constants/ActionTypes';
import { GET, PUT, POST } from '../services/Api';
import { setBadgeNumber } from './push-badge';
import { minutesPassed } from '../helpers/time';
import { reportRequestError } from '../helpers/request-errors';

const fetchNotificationsRequest = {
  type: FETCH_NOTIFICATIONS_REQUEST,
};

const fetchNotificationsSuccess = items => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: {
      items,
      fetchedAt: Date.now(),
    },
  };
}

const fetchNotificationsFailure = {
  type: FETCH_NOTIFICATIONS_FAILURE,
};

const removeNotification = id => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      id,
    },
  };
};

const removeNotifications = ids => {
  return {
    type: REMOVE_NOTIFICATIONS,
    payload: {
      ids,
    },
  };
};

const updateNotificationRequest = {
  type: UPDATE_NOTIFICATION_REQUEST,
};

const updateNotificationSuccess = {
  type: UPDATE_NOTIFICATION_SUCCESS,
};

const updateNotificationFailure = {
  type: UPDATE_NOTIFICATION_FAILURE,
};

const updateNotificationsRequest = {
  type: UPDATE_NOTIFICATIONS_REQUEST,
};

const updateNotificationsSuccess = {
  type: UPDATE_NOTIFICATIONS_SUCCESS,
};

const updateNotificationsFailure = {
  type: UPDATE_NOTIFICATIONS_FAILURE,
};

export const fetchNotifications = () => async (dispatch, getState) => {
  dispatch(fetchNotificationsRequest);

  try {
    const res = await GET('/employee/me/notifications/unread');
    if (!res.ok) throw res;

    dispatch(fetchNotificationsSuccess(res.json.result));
  } catch (res) {
    dispatch(fetchNotificationsFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchNotifications = state => {
  const notifications = state.notifications;

  if (!notifications) {
    return true;
  } else if (notifications.fetching) {
    return false;
  } else if (!notifications.fetchedAt) {
    return true;
  } else if (notifications.fetchError) {
    return true;
  } else {
    return minutesPassed(15, notifications.fetchedAt);
  }
}

export const fetchNotificationsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchNotifications(getState())) {
    return dispatch(fetchNotifications());
  }
}

const updateNotification = id => async dispatch => {
  dispatch(updateNotificationRequest);

  try {
    const res = await PUT(`/employee/me/notifications/${id}/markasread`);
    if (!res.ok) throw res;

    dispatch(updateNotificationSuccess);
  } catch (res) {
    dispatch(updateNotificationFailure);
    reportRequestError(res, { showAlert: false });
  }
};

const updateNotificationsByType = type => async dispatch => {
  dispatch(updateNotificationsRequest);

  try {
    const res = await POST(`/employee/me/notifications/markasread?resource_type=${type}`);
    if (!res.ok) throw res;

    dispatch(updateNotificationsSuccess);
  } catch (res) {
    dispatch(updateNotificationsFailure);
    reportRequestError(res, { showAlert: false });
  }
};

export const markNotificationRead = id => dispatch => {
  dispatch(removeNotification(id));
  dispatch(updateNotification(id));
  dispatch(setBadgeNumberToNotificationsCount());
};

export const markNotificationsReadByType = type => (dispatch, getState) => {
  const ids = getState().notifications.items.filter(i => i.data.resource_type === type).map(i => i.id);

  if (!ids.length) {
    return;
  }

  dispatch(removeNotifications(ids));
  dispatch(updateNotificationsByType(type));
  dispatch(setBadgeNumberToNotificationsCount());
};

export const setBadgeNumberToNotificationsCount = () => (dispatch, getState) => {
  const count = getState().notifications.items.length;
  dispatch(setBadgeNumber(count));
};
