import React from 'react';

import JobsContainer from '../containers/JobsContainer.tsx';
import ScreenView from '../components/ScreenView';

export default class JobsScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <JobsContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
