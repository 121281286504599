import { fetchBankidToken } from './bankid-token';
import { fetchBankidCollect } from './bankid-collect';
import { openBankidApp } from './bankid-app';
import { logIn } from './auth';
import { GET } from '../services/Api';

export const startBankidAuth = (personalNumber) => dispatch => {
  dispatch(fetchBankidToken(personalNumber)).then(() => {
    if (personalNumber) {
      dispatch(collectBankId());
    } else {
      dispatch(openBankidApp());
    }
  });
};

export const finishBankidAuth = () => (dispatch, getState) => {
  const orderRef = getState().bankidToken.orderRef;

  dispatch(fetchBankidCollect(orderRef)).then(() => {
    const bankidCollect = getState().bankidCollect;

    if (bankidCollect.accessToken) {
      dispatch(logIn({
        accessToken: bankidCollect.accessToken,
        accessTokenExpiresAt: bankidCollect.accessTokenExpiresAt,
      }));
    }
  });
};

const collectBankId = () => (dispatch, getState) => {
  const orderRef = getState().bankidToken.orderRef;
  dispatch(fetchBankidCollect(orderRef)).then(() => {
    const bankidCollect = getState().bankidCollect;

    if (bankidCollect.accessToken) {
      dispatch(logIn({
        accessToken: bankidCollect.accessToken,
        accessTokenExpiresAt: bankidCollect.accessTokenExpiresAt,
      }));
    }
  });
}

const fetchTestToken = async () => {
  try {
    const res = await GET('/auth/logintest');
    if (!res.ok) throw res;

    return res.json;
  } catch (res) {
    return {};
  }
};

export const doTestLogin = () => async dispatch => {
  const res = await fetchTestToken();

  dispatch(logIn({
    accessToken: res.result.access_token,
    accessTokenExpiresAt: res.result.access_token_expires_at,
  }));
};
