import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import * as Icon from '@expo/vector-icons';

import Colors from '../constants/Colors';

export default ({ name, size, focused, horizontal, style, badge, badgeCount }) => (
  <View style={styles.container}>
    <Icon.Ionicons
      name={name}
      size={size}
      style={[style, horizontal ? styles.horizontal : {}]}
      color={focused ? Colors.tabIconSelected : Colors.tabIconDefault}
    />
    {badge &&
      <View style={styles.badge}>
        {badgeCount &&
          <Text style={styles.badgeText}>{badgeCount}</Text>
        }
      </View>
    }
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  badge: {
    position: 'absolute',
    right: -4,
    top: 2,
    backgroundColor: Colors.attentionColor,
    borderRadius: 14/2,
    width: 14,
    height: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: '#fff',
    fontSize: 11,
  },
  horizontal: {
    marginLeft: -30,
  },
});
