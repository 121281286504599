import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  ADD_SERVICE,
  REMOVE_SERVICE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
} from '../constants/ActionTypes';
import { GET, POST, DELETE } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const fetchServicesRequest = {
  type: FETCH_SERVICES_REQUEST,
};

const fetchServicesSuccess = (items, selectedItems) => {
  return {
    type: FETCH_SERVICES_SUCCESS,
    payload: {
      items,
      selectedItems,
      fetchedAt: Date.now(),
    },
  };
}

const fetchServicesFailure = {
  type: FETCH_SERVICES_FAILURE,
};

const fetchServices = () => async dispatch => {
  dispatch(fetchServicesRequest);

  try {
    const fetchServices = GET('/services/unique');
    const fetchSelectedServices = GET('/employee/me/services');

    const servicesRes = await fetchServices;
    const selectedServicesRes = await fetchSelectedServices;

    if (!servicesRes.ok || !selectedServicesRes.ok) throw res;

    dispatch(fetchServicesSuccess(servicesRes.json.result, selectedServicesRes.json.result));
  } catch (res) {
    dispatch(fetchServicesFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchServices = state => {
  const services = state.services;

  if (!services) {
    return true;
  } else if (services.fetching) {
    return false;
  } else {
    return !services.fetchedAt;
  }
}

export const fetchServicesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchServices(getState())) {
    return dispatch(fetchServices());
  }
}

export const addService = ({ id }) => {
  return {
    type: ADD_SERVICE,
    payload: {
      id,
    },
  };
}

export const removeService = ({ id }) => {
  return {
    type: REMOVE_SERVICE,
    payload: {
      id,
    },
  };
}

const updateServiceRequest = {
  type: UPDATE_SERVICE_REQUEST,
};

const updateServiceSuccess = {
  type: UPDATE_SERVICE_SUCCESS,
};

const updateServiceFailure = {
  type: UPDATE_SERVICE_FAILURE,
};

export const updateService = ({ id, name }, value) => async dispatch => {
  const service = {
    id,
    name,
  };

  dispatch(updateServiceRequest);

  try {
    let res;

    if (value) {
      res = await POST('/employee/me/services', service);
    } else {
      res = await DELETE('/employee/me/services', service);
    }

    if (!res.ok) throw res;

    dispatch(updateServiceSuccess);
  } catch (res) {
    dispatch(updateServiceFailure);

    if (value) {
      dispatch(removeService(service));
    } else {
      dispatch(addService(service));
    }

    reportRequestError(res);
  }
};
