import React from 'react';
import Dialog from "react-native-dialog";

export default class TestLoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: '' };
  }

  render() {
    const {
      visible,
    } = this.props;

    return (
      <Dialog.Container visible={visible}>
        <Dialog.Title>Testinloggning</Dialog.Title>
        <Dialog.Description>
          Logga in som testanvändare genom att ange testkoden.
        </Dialog.Description>
        <Dialog.Input onChangeText={this._onChangeText} placeholder="Testkod" />
        <Dialog.Button onPress={this._onCancelPress} label="Avbryt" />
        <Dialog.Button onPress={this._onSubmitPress} label="Logga in" />
      </Dialog.Container>
    );
  }

  _onChangeText = value => {
    this.setState({ password: value });
  }

  _onSubmitPress = () => {
    const {
      onSubmit,
    } = this.props;
    const {
      password,
    } = this.state;

    onSubmit(password);
  }

  _onCancelPress = () => {
    const {
      onCancel,
    } = this.props;

    onCancel();
  }
};

