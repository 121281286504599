import { Platform } from 'react-native';
import * as Haptics from 'expo-haptics';

export const selection = () => {
  Platform.OS === 'ios' && Haptics.selectionAsync && Haptics.selectionAsync();
}

export const notification = (type) => {
  Platform.OS === 'ios' && Haptics.notificationAsync && Haptics.notificationAsync(type);
}
