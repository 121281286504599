import {
  FETCH_MANUAL_LOGIN_REQUEST,
  FETCH_MANUAL_LOGIN_SUCCESS,
  FETCH_MANUAL_LOGIN_FAILURE,
  RESET_MANUAL_LOGIN_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
}, action) => {
  switch (action.type) {
    case FETCH_MANUAL_LOGIN_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_MANUAL_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
      });
    case FETCH_MANUAL_LOGIN_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case RESET_MANUAL_LOGIN_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
      });
    default:
      return state;
  }
}
