import React from 'react';

import ScrollView from '../components/ScrollView';
import LogOutContainer from '../containers/LogOutContainer';

export default class LogOutScreen extends React.Component {
  render() {
    return (
      <ScrollView>
        <LogOutContainer navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}
