import React from 'react';

import MessagesContainer from '../containers/MessagesContainer';
import ScreenView from '../components/ScreenView';

export default class MessagesScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <MessagesContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
