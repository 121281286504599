import React from 'react';
import { StyleSheet } from 'react-native';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import Text from './Text';

export default ({ focused, title }) => (
  <Text adjustsFontSizeToFit numberOfLines={1} style={[styles.tabBarLabel, focused ? styles.tabBarLabelActive : {}]}>
    {title}
  </Text>
)

const styles = StyleSheet.create({
  tabBarLabel: {
    color: Colors.tabLabelDefault,
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 13,
    marginBottom: 4,
  },
  tabBarLabelActive: {
    color: Colors.tabLabelSelected,
  }
});
