import React from 'react';

import ScreenView from '../components/ScreenView';
import JobProfileContainer from '../containers/JobProfileContainer';

export default class JobProfileScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <JobProfileContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
