import React from 'react';
import { View, StyleSheet } from 'react-native';

import ActionFeedback from './ActionFeedback';
import { PrimaryButton } from './Button';

export default WrappedComponent => {
  return class Error extends React.PureComponent {
    render() {
      const { fetchError, fetch } = this.props;

      if (fetchError) {
        return (
          <View style={styles.container}>
            <ActionFeedback
              title="Ett fel uppstod"
              text="Vappen kunde inte hantera anropet. Kontrollera din anslutning till Internet."
              success={false}
            />
            {fetch &&
              <PrimaryButton
                title="Försök igen"
                onPress={fetch}
              />
            }
          </View>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
