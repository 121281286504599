import {
  PUSH_NOTIFICATION_RECEIVED,
  PUSH_NOTIFICATION_SELECTED,
  PUSH_NOTIFICATION_HANDLED,
} from '../constants/ActionTypes';
import SimpleConfirm from '../services/SimpleConfirm';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import NavigationService from '../services/NavigationService';
import { fetchJobs } from './jobs';
import { refreshFetchPayslips } from './payslips';
import { refreshFetchMessages } from './messages';
import { fetchNotifications, markNotificationRead } from './notifications';

let nextPushNotificationId = 1;

const pushNotificationReceived = (id, data) => ({ type: PUSH_NOTIFICATION_RECEIVED, payload: { id, data } });
const pushNotificationSelected = (id, data) => ({ type: PUSH_NOTIFICATION_SELECTED, payload: { id, data } });
const pushNotificationHandled = id => ({ type: PUSH_NOTIFICATION_HANDLED, payload: { id } });

export const handlePushNotification = ({ origin, data }) => async dispatch => {
  const pushNotificationId = nextPushNotificationId++;

  if (origin === 'selected') {
    dispatch(pushNotificationSelected(pushNotificationId, data));
  } else {
    dispatch(pushNotificationReceived(pushNotificationId, data));

    const confirmResponse = await SimpleConfirm({
      title: 'Notifikation',
      message: data.title,
      actionText: 'Visa',
      cancelText: 'Stäng',
    });

    if (!confirmResponse.result) {
      return;
    }
  }

  dispatch(visitPush(data));
  dispatch(pushNotificationHandled(pushNotificationId));

  // Fetch latest notifications and mark this one read
  dispatch(fetchNotifications()).then(() => {
    dispatch(markCorrespondingNotificationRead(data.resource_type, data.resource_id));
  });
}

const visitPush = data => dispatch => {
  const id = data.resource_id;

  switch (data.resource_type) {
    case NotificationResourceTypes.Message:
      dispatch(refreshFetchMessages()); // TODO: Not bulletproof, this may not be on page 1, also sets refreshing true
      return NavigationService.navigate('Message', { id });
    case NotificationResourceTypes.Job:
      dispatch(fetchJobs());
      return NavigationService.navigate('Job', { id });
    case NotificationResourceTypes.Payslip:
      dispatch(refreshFetchPayslips());
      return NavigationService.navigate('Payslips');
  }
};

const markCorrespondingNotificationRead = (type, id) => (dispatch, getState) => {
  const { notifications } = getState();
  const notification = notifications.items.find(i => i.data.resource_type === type && i.data.resource_id === id);

  if (!notification) {
    return;
  }

  dispatch(markNotificationRead(notification.id));
};
