import React from 'react';

import JobProfileServicesContainer from '../containers/JobProfileServicesContainer';
import ScreenView from '../components/ScreenView';

export default class JobProfileServicesScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <JobProfileServicesContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
