import React from 'react';
import {View, ScrollView, StyleSheet, ActivityIndicator, TouchableOpacity, FlatList} from 'react-native';
import Text from '../components/Text';
import Fonts from '../constants/Fonts';
import OngoingJobItem from '../components/OngoingJobItem';
import { connect } from 'react-redux';
import { Navigation, JobItem, Notification, User } from '../constants/types';
import {UserInterestStatus, WorkorderStatus} from '../constants/Statuses';
import Colors from '../constants/Colors';
import { fetchMyJobs, fetchMyClosedJobs, fetchMoreMyJobs, fetchMoreClosedJobs } from '../actions/jobs';
import { markNotificationRead } from '../actions/notifications';
import { reportRequestError } from '../helpers/request-errors';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import { NavigationEventSubscription } from 'react-navigation';

type Props = {
  navigation: Navigation;
  myJobs: JobItem[];
  myClosedJobs: JobItem[];
  currentUser: User;
  fetchMyJobs: typeof fetchMyJobs;
  fetchMoreMyJobs: typeof fetchMoreMyJobs;
  fetchMyClosedJobs: typeof fetchMyClosedJobs,
  fetchMoreClosedJobs: typeof fetchMoreClosedJobs,
  markNotificationRead: typeof markNotificationRead;
  notifications: Notification[];
}

class OngoingJobsContainer extends React.PureComponent<Props> {

  state = {
    loading: false,
    activeTab: 'open',
  };

  unsubscribe: any;

  async componentDidMount() {
    const { navigation, notifications, markNotificationRead } = this.props;
    this.unsubscribe = navigation.addListener('focus', async () => {
      await this.getMyJobs();

      notifications.forEach((notif: Notification) => {
        if (notif.data.resource_type === NotificationResourceTypes.Workorder) {
          markNotificationRead(notif.id);
        }
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe?.();
  }

  getMyJobs = async () => {
    const { fetchMyJobs, fetchMyClosedJobs } = this.props;
    
    try {
      this.setState({ loading: true });
      await fetchMyJobs();
      await fetchMyClosedJobs();
    } catch (error) {
      reportRequestError(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  onItemPress = async (id: number, title: string) => {
    const { navigation } = this.props;

    navigation.navigate('OngoingJob', { jobId: id, title });
  }

  activateTab = (tab: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  _listItem = ({ item }) => {
    const { notifications, currentUser } = this.props;
    const newWorkorderAssign = notifications.find(notif => (notif.data.resource_type === 4) && (notif.data.resource_id === item.id));
    const subTitle = item.client ? `${ item.client.address }, ${ item.client.postal_code } ${ item.client.city }` : item.office.name;
    const workorderLines = item.workorder_lines || [];
    const lastUpdatedDate = workorderLines[0]?.workorder_line_interests?.find(interest => interest.user.employee_id === currentUser.id)?.updated_at;
    return (
      <View key={ item.id }>
        { !!newWorkorderAssign &&
          <View style={ styles.alertBanner }>
            <Text style={ styles.alertText }>
              NYTT UPPDRAG
            </Text>
          </View>
        }
        <OngoingJobItem
          key={ item.id }
          id={ item.id }
          workorderId={ item.workorder_number }
          title={ item.title }
          subTitle={ subTitle }
          date={ lastUpdatedDate }
          closed={ item.status === WorkorderStatus.COMPLETED || item.workorder_lines[0].user_interest_status === UserInterestStatus.DONE }
          lastTimeReport={ item?.timereports?.latest }
          onPress={ this.onItemPress }
        />
      </View>
    );
  }

  _listEmptyComponent = () => {
    const { activeTab } = this.state;
    return (
      <View style={ styles.noJobs }>
        <Text style={ styles.noJobsText }>
          {`Du har inga ${ activeTab === 'open' ? 'pågående' : 'avslutade' } uppdrag i Vappen`}
        </Text>
      </View>
    );
  }
  
  render() {
    const { myJobs, myClosedJobs, fetchMoreMyJobs, fetchMoreClosedJobs } = this.props;
    const { loading, activeTab } = this.state;
    
    const jobs = activeTab == 'open' ? myJobs : myClosedJobs;
    return (
      <View style={ styles.container }>
        <View style={ styles.header }>
          <Text style={ styles.headerText }>
            Mina uppdrag
          </Text>
        </View>
        <View style={ styles.tabs }>
          <TouchableOpacity
            disabled={ activeTab === 'open' }
            onPress={ () => this.activateTab('open') }
            style={ [styles.tabButtonWrapper, styles.notLastTabButtonWrapper, (activeTab === 'open' ? styles.selectedTabButtonWrapper : undefined)] }>
            <View style={ styles.tabButton }>
              <Text style={ [styles.tabButtonText, (activeTab === 'open' ? styles.selectedTabButtonText : undefined)] }>
                Aktiva
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={ activeTab === 'closed' }
            onPress={ () => this.activateTab('closed') }
            style={ [styles.tabButtonWrapper, (activeTab === 'closed' ? styles.selectedTabButtonWrapper : undefined)] }>
            <View style={ styles.tabButton }>
              <Text style={ [styles.tabButtonText, (activeTab === 'closed' ? styles.selectedTabButtonText : undefined)]  }>
                Avslutade
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        { loading ?
          <View style={ styles.inScreenLoader }>
            <ActivityIndicator size={ 'large' } color={ Colors.bodyText } />
          </View> :
          <FlatList
            data={ jobs }
            renderItem={ this._listItem }
            ListEmptyComponent={ this._listEmptyComponent }
            onEndReached={ activeTab == 'open' ? fetchMoreMyJobs : fetchMoreClosedJobs }
          />
        }
      </View>
    );
  }

}

const mapStateToProps = state => {
  return {
    currentUser: state.user.data,
    myJobs: state.jobs.myJobs,
    myClosedJobs: state.jobs.myClosedJobs,
    notifications: state.notifications.items,
  };
};

export default connect(mapStateToProps, { fetchMyJobs, fetchMoreMyJobs, fetchMyClosedJobs, markNotificationRead, fetchMoreClosedJobs })(OngoingJobsContainer);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    padding: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 20,
    fontFamily: Fonts.emphasis,
  },
  inScreenLoader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.overlay,
  },
  alertBanner: {
    paddingVertical: 8,
    backgroundColor: Colors.tintColor,
    alignItems: 'center',
  },
  alertText: {
    fontFamily: Fonts.emphasis,
    fontSize: 12,
    color: Colors.veryWhite,
  },
  tabs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  tabButtonWrapper: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  notLastTabButtonWrapper: {
    borderRightWidth: 1,
  },
  selectedTabButtonWrapper: {
    backgroundColor: Colors.veryWhite,
    borderBottomWidth: 0,
  },
  tabButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  tabButtonText: {
    fontFamily: Fonts.regular,
    textAlign: 'center',
  },
  selectedTabButtonText: {
    fontFamily: Fonts.medium,
  },
  noJobs: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  noJobsText: {
    paddingVertical: 32,
    paddingHorizontal: 24,
    textAlign: 'center',
    color: Colors.secondaryText,
    backgroundColor: Colors.veryWhite,
  },
});
