import React from 'react';

import ScreenView from '../components/ScreenView';
import OngoingJobsContainer from '../containers/OngoingJobsContainer';
import { Navigation } from '../constants/types';

type Props = {
  navigation: Navigation;
}

export default class OngoingJobsScreen extends React.Component<Props> {
  render() {
    return (
      <ScreenView>
        <OngoingJobsContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
