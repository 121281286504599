import React from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Alert,
  Platform,
  ActivityIndicator,
} from 'react-native';
import { RouteProp } from '@react-navigation/native';

import Text from '../components/Text';
import InputButton from '../components/InputButton';
import TextButton from '../components/TextButton';
import ExpenseInput from '../components/ExpenseInput';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import AttachmentList from '../components/AttachmentList';
import uuid from 'uuid';
import Modal from '../components/Modal';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Picker } from '@react-native-picker/picker';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import KeyboardViewContainer from '../components/KeyboardViewContainer';
import WebDatePicker from '../components/WebDatePicker';
import { POST, GET, DELETE } from '../services/Api';
import moment from 'moment';
import { Navigation, CostType, TimeReportLine, TimeReportLineFile, TimeReportLineCost, TimeReport, JobItem, LocalAttachment, OngoingJobsStackList } from '../constants/types';
import AttachmentPicker from '../components/AttachmentPicker';
import { reportRequestError } from '../helpers/request-errors';
import TimePicker from 'react-time-picker';
import { getDuriationHumanReadable } from '../helpers/time';

type OngoingJobsScreenRouteProp = RouteProp<OngoingJobsStackList, 'ReportTime'>;

type Props = {
  navigation: Navigation;
  route: OngoingJobsScreenRouteProp;
  costTypes: CostType[];
  job: JobItem;
}

type State = {
  expenseCost: string;
  expenseDescription: string;
  expenseInputVisible: boolean;
  expenses: Array<{
    type: { title: string; id?: number; type?: number; unit?: string };
  } & TimeReportLineCost>;
  attachments: TimeReportLineFile[] | LocalAttachment[];
  attachmentModalVisible: boolean;
  timePickerOpenWeb: boolean;
  timePickerModalTitle: string;
  timePickerOpenAndroid: boolean;
  timePickerOpenIos: boolean;
  breakPickerOpen: boolean;
  timePickerTitleIos: string;
  start: Date;
  end: Date;
  date: Date;
  webTime: string;
  breakTime: number;
  timePickerMode: string;
  timePickerType: string;
  loading: boolean;
  webInputType: string;
}

const initialState: State = {
  expenseCost: '',
  expenseDescription: '',
  expenseInputVisible: false,
  expenses: [],
  attachments: [],
  attachmentModalVisible: false,
  timePickerOpenWeb: false,
  timePickerModalTitle: '',
  timePickerOpenAndroid: false,
  timePickerOpenIos: false,
  breakPickerOpen: false,
  timePickerTitleIos: '',
  start: new Date('2020-01-01T09:00'),
  webTime: '',
  end: new Date('2020-01-01T10:00'),
  date: new Date(),
  timePickerMode: '',
  timePickerType: '',
  breakTime: 0,
  loading: false,
  webInputType: '',
};

class ReportTimeScreen extends React.PureComponent<Props> {

  state = {
    expenseType: this.props.costTypes[0],
    ...initialState,
  }

  _scrollView = React.createRef<ScrollView>();

  async componentDidMount() {
    const { route, costTypes } = this.props;

    const timeReportLineId = route.params?.lineId;
    this.setState({ expenseType: costTypes[0] });

    if (timeReportLineId) {
      this.setState({ loading: true });

      try {
        await this.fetchSavedTimeReportLine(timeReportLineId);
      } catch (error) {
        reportRequestError(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  fetchSavedTimeReportLine = async (timeReportLineId: number) => {
    const { route, costTypes } = this.props;
    const timeReportId = route.params?.timeReportId;

    const timeReportLines = await GET(`/timereport/${ timeReportId }/lines`);
    const selectedTimeReportLine = timeReportLines.json.result.find((line: TimeReportLine) => line.id === timeReportLineId);

    this.setState({
      start: new Date(`${ selectedTimeReportLine.day }T${ selectedTimeReportLine.start_time }`),
      end: new Date(`${ selectedTimeReportLine.day }T${ selectedTimeReportLine.end_time }`),
      date: new Date(selectedTimeReportLine.day),
      //TODO fix title for file url
      attachments: selectedTimeReportLine.timereport_line_files.map((file: TimeReportLineFile) => {
        return {
          ...file,
          url: this.getTitle(file.url).title,
        };
      }),
      expenses: selectedTimeReportLine.timereport_line_costs.map((expense: TimeReportLineCost) => {
        const savedExpense = costTypes.find(type => type.id === expense.type_id);

        if (savedExpense) {
          return {
            ...expense,
            type: {
              type: expense.type_id,
              title: savedExpense.title,
              unit: savedExpense.unit,
            },
          };
        }
      }),
    });
  }

  onOpenExchangeInput = () => this.setState({ expenseInputVisible: true })
  onCloseExchangeInput = () => this.setState({ expenseInputVisible: false })

  onOpenAttachmentModal = () => this.setState({ attachmentModalVisible: true })
  onCloseAttachmentModal = () => this.setState({ attachmentModalVisible: false })

  onOpenTimePicker = (type: 'start' | 'end' | 'date') => {
    const isWeb = Platform.OS === 'web';
    if (isWeb) {
      this.setState({ timePickerOpenWeb: true })
    }

    let modalTitle = '';
    switch (type){
      case 'start':
        this.setState({ webTime: moment(this.state.start).format('HH:mm') })
        modalTitle = 'Välj starttid';
        break;
      case 'end':
        this.setState({ webTime: moment(this.state.end).format('HH:mm') })
        modalTitle = 'Välj sluttid';
        break;
      case 'date':
        modalTitle = 'Välj datum';
        break;
    }
    this.setState({ timePickerType: type, timePickerModalTitle: modalTitle, timePickerMode: type === 'date' ? 'date' : 'time', webInputType: type })
  }

  onCloseTimePicker = () => this.setState({ timePickerType: '', timePickerModalTitle: '' })
  onCloseTimePickerWeb = () => {
    if (this.state.timePickerMode === 'time' && this.state.webTime) {
      this.onChangeTime(null, new Date(`2020-01-01T${this.state.webTime}`))
    }
    this.setState({ timePickerOpenWeb: false, webTime: '' })
  }
  onCloseBreakPicker = () => this.setState({ breakPickerOpen: false })
  onOpenBreakPicker = () => this.setState({ breakPickerOpen: true })
  onChangeExpenseType = (input: CostType) => this.setState({ expenseType: input })
  onChangeExpenseDescription = (input: string) => this.setState({ expenseDescription: input });

  onChangeExpenseCost = (input: string) => {
    if (input.match(/^[0-9]{0,}$/)) this.setState({ expenseCost: input });
  }

  onRemoveBreak = () => {
    this.setState({ breakTime: 0 });
  }

  onChangeTime = (e: Event, input: Date) => {
    const { timePickerType, start, end } = this.state;

    if (input) {
      let modifiedInput = input;
      if (timePickerType === 'start' || timePickerType === 'end') {
        const roundedTime = moment(input);
        const minute = roundedTime.minute();
        const hour = roundedTime.hour();
        if(minute <= 7) roundedTime.set({ hour: hour, minute: 0 });
        else if ((minute >= 8 && minute < 15) || (minute >= 15 && minute < 23)) roundedTime.set({ hour: hour, minute: 15 });
        else if ((minute >= 23 && minute < 30) || (minute >= 30 && minute < 38)) roundedTime.set({ hour: hour, minute: 30 });
        else if ((minute >= 38 && minute < 45) || (minute >= 45 && minute < 53)) roundedTime.set({ hour: hour, minute: 45 });
        else if ((minute >= 53)) roundedTime.set({ hour: hour + 1, minute: 0 });

        modifiedInput = roundedTime.toDate();
      }
      if (timePickerType === 'start' && moment(modifiedInput).format() >= moment(end).format()) {
        const newEndTime = moment(modifiedInput).add(1, 'hour').format('HH:mm');
        this.setState({ end: moment(`2020-01-01T${ newEndTime }`).toDate() });
      } else if (timePickerType === 'end' && moment(modifiedInput).format() <= moment(start).format()) {
        const newStartTime = moment(modifiedInput).subtract(1, 'hour').format('HH:mm');
        this.setState({ start: moment(`2020-01-01T${ newStartTime }`).toDate() });
      }
      const timeOrDateToSet = timePickerType && timePickerType === 'date' ? moment(modifiedInput).toDate() : moment(`2020-01-01T${ moment(modifiedInput).format('HH:mm') }`).toDate();

      this.setState({ [timePickerType]: timeOrDateToSet });
    }
  }

  /**
   * Check that break time is not longer than worked time
   */
  checkValidBreakTime() {
    const { start, end, breakTime } = this.state;
    const workedTime = moment.duration(moment(end).diff(moment(start))).asMinutes();
    if (breakTime >= workedTime) {
      let newBreakTime = workedTime - 15;
      if (newBreakTime < 0) {
        newBreakTime = 0;
      }
      this.setState({ breakTime: newBreakTime });
    }
  }

  onWebChangeDate = (e: any) => {
    if (e.target.value) {
      this.setState({ date: new Date(e.target.value) });

      if (moment(e.target.value).isSameOrAfter(moment().add(1, 'days'), 'day')) {
        this.setState({ date: moment().toDate() });
      }
    }
  }

  onChangeWebTimeStart = (e) => {
    const time = moment(`2020-01-01T${e.target.value}`);


    this.setState({ webTime: time.format('HH:mm') })
  }

  onSaveExpense = (addNew = false) => {
    const {
      expenses,
      expenseType,
      expenseCost,
      expenseDescription,
    } = this.state;
    const { costTypes } = this.props;

    if (!expenseType.unit || expenseCost) {
      this.setState({
        expenses: [...expenses, {
          id: uuid.v4(),
          type: expenseType,
          cost: expenseCost,
          description: expenseDescription,
        }],
        expenseInputVisible: false,
        expenseType: costTypes[0],
        expenseCost: '',
        expenseDescription: '',
      });
      
    } else {
      window.alert('Utlägg - Du måste fylla i alla fält för utlägg för att kunna spara.');
      return false;
    }
    if(addNew) {
      this.setState({
        expenseInputVisible: true,
      });
    }
    return true;
  }

  onDeleteExpense = (id: string | number) => {
    const { expenses } = this.state;
    const filteredExpenses = expenses.filter(expense => expense.id !== id);
    this.setState({ expenses: filteredExpenses });
  }

  onDeleteAttachment = async (id: number) => {
    const { attachments } = this.state;
    const filteredAttachments = [...attachments].filter(attachment => attachment.id !== id);
    this.setState({ loading: true });

    const savedAttachment = [...attachments].find(attachment => attachment.id === id) as LocalAttachment;

    if (savedAttachment && savedAttachment.data) {
      this.setState({ attachments: filteredAttachments, loading: false });
    } else {
      try {
        await DELETE('/timereport/line/file', { id });
        this.setState({ attachments: filteredAttachments });
      } catch (error) {
        reportRequestError(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  getTitle = (string: string) => {
    if (Platform.OS === 'web') {
      const splitString = string.split('.')
      return { title: splitString[0], suffix: splitString[1] };
    } else {
      const fullFileName = string.split('/');
      const name = fullFileName[fullFileName.length - 1].split('.')[0];
      const suffix = fullFileName[fullFileName.length - 1].split('.')[1];
  
      let title = `${ name }.${ suffix.toUpperCase() }`
  
      if (name.length > 15) {
        title = `${ name.slice(0, 15) }...${ suffix }`
      }
      return { title, suffix };
    }
  }

  selectAttachmentItem = (item: any) => {
    const { attachments } = this.state;
    if (item) {
      const { title, suffix } = this.getTitle(Platform.OS === 'web' ? item.name : item.uri)
      this.setState({ attachments: [...attachments, { id: uuid.v4(), data: item, title, suffix }], attachmentModalVisible: false })      
    } else {
      this.setState({ attachmentModalVisible: false });
    }
  }

  getFormattedReportedTime() {
    const {
      date,
      start,
      end,
    } = this.state;

    return `${ moment(date).format().slice(0, 10) } mellan ${ moment(start).format().slice(11, 16) } - ${ moment(end).format().slice(11, 16) }`;
  }

  saveTimeReportLine = async (goToNewDay?: boolean) => {
    const {
      expenseInputVisible,
    } = this.state;
    if(expenseInputVisible) {
      if(!this.onSaveExpense()) {
        return;
      }
    }
    setTimeout(async() => {
      const {
        expenses,
      } = this.state;
      const expensesText = expenses.length ? 'samt utlägg' : '';
      const result = window.confirm(`Spara - Är du säker på att du vill spara? Rapporterad tid: ${this.getFormattedReportedTime()} ${expensesText}`)

      if (result) {
        await this.saveTimeReportLineAfterConfirm(goToNewDay);
      }
    }, 100);    
  }

  saveTimeReportLineAfterConfirm = async (goToNewDay?: boolean) => {
    const { route, navigation } = this.props;
    const timeReportId = route.params?.timeReportId;
    const timeReportLineId = route.params?.lineId;
    const workorderLineId = route.params?.workorderLineId;
    const timeReportRes = await GET(`/timereport/${ timeReportId }`);
    const timeReport = timeReportRes.json.result as TimeReport;

    const { date, start, end, breakTime, attachments } = this.state;

    const formatedDate = moment(date).format().slice(0, 10);
    const formatedStart = moment(start).format().slice(11, 19);
    const formatedEnd = moment(end).format().slice(11, 19);
    this.setState({ loading: true })
    if (!timeReportLineId && timeReport && timeReport.timereport_lines.filter(l => l.workorder_line_id === workorderLineId).filter(line => 
      line.day === formatedDate &&
      moment(`${ formatedDate }T${ formatedStart }`).add(1, 'minute').isBetween(moment(`${ line.day }T${ line.start_time }`), moment(`${ line.day }T${ line.end_time }`)) ||
      moment(`${ formatedDate }T${ formatedEnd }`).subtract(1, 'minute').isBetween(moment(`${ line.day }T${ line.start_time }`), moment(`${ line.day }T${ line.end_time }`))
    ).length > 0) {
      window.alert('Fel - Du har redan rapporterat tid för den här perioden')
      this.setState({ loading: false })
      return false;
    }

    const { expenses } = this.state;

    const report = {
      timereport_id: timeReportId,
      timereport_line: {
        id: timeReportLineId,
        day: formatedDate,
        start_time: formatedStart,
        end_time: formatedEnd,
        breaktime: breakTime,
        workorder_line_id: workorderLineId,
        timereport_line_costs: [
          ...expenses.map(expense => {

            const type_id = expense.type.id || expense.type.type;
            return {
              type_id,
              cost: expense.cost,
              description: expense.description,
            };
          }),
        ],
      },
    };

    const res = await POST('/timereport/line', report);

    const timeReportLine = res.json.result;
    if (attachments.length > 0) {

      await Promise.all([...attachments as LocalAttachment[]].map(async (attachment) => {
        if (attachment.data) {
          let formData = new FormData();

          formData.append('timereport_line_file', attachment.data.file)
          formData.append('timereport_line_id', timeReportLine.id)
  
          await POST('/timereport/line/file', formData, null, true);
        }
      }));

      this.setState({ loading: false });
      !goToNewDay && navigation.goBack();
      return true;
    } else {
      this.setState({ loading: false });
      !goToNewDay && navigation.goBack();
      return true;
    }
  }

  onScrollToTop = () => {
    const scrollView = this._scrollView.current;
    if (scrollView) {
      scrollView.scrollTo({ x: 0, y: 0, animated: true });
    }
  }

  onNewDay = async () => {
    try {
      const saved = await this.saveTimeReportLine(true);
      if (saved) {
        window.alert('Spara tid - Du har nu sparat den här dagen och formuläret är nollställt')
        this.onScrollToTop();
        this.setState({ ...initialState });
      }
    } catch (error) {
      reportRequestError(error);
    }
  }

  render() {
    const { job, navigation, costTypes } = this.props;
    const {
      timePickerType,
      timePickerModalTitle,
      breakPickerOpen,
      timePickerMode,
      attachmentModalVisible,
      attachments,
      expenses,
      expenseType,
      expenseCost,
      expenseDescription,
      expenseInputVisible,
      date,
      start,
      end,
      breakTime,
      loading,
      timePickerOpenWeb,
      webInputType,
      webTime,
    } = this.state;

    const exceptions: { [name: string]: number } = {
      'Km-ersättning': 1,
    };
  
    const sortedCostTypesList = costTypes.sort(function (a, b) {
      if (exceptions[a.title] && exceptions[b.title]) {
        return exceptions[a.title] - exceptions[b.title];
      } else if (exceptions[a.title]) {
        return -1;
      } else if (exceptions[b.title]) {
        return 1;
      } else {
        return a.id - b.id;
      }
    });

    const workedTime = moment.duration(moment(end).diff(moment(start)));
    const minusBreak = workedTime.clone().subtract(breakTime, 'minutes');
    const workedTimeReadable = getDuriationHumanReadable(minusBreak.as('minutes'));

    const breakValues: {label: string; value: number;}[] = [
      { label: 'Ingen', value: 0 },
    ];

    for (let bv = 15; bv < workedTime.asMinutes(); bv = bv + 15) {
      breakValues.push(
        { label: getDuriationHumanReadable(bv), value: bv }
      );
    }



    if (costTypes && expenseType) {
      return (
        <View style={ styles.container }>
          <KeyboardViewContainer behaviour={'height'}>
            <ScrollView ref={ this._scrollView }>
              <View style={ styles.contentContainer }>
                <Text style={ styles.headerText }>
                  { job.title }
                </Text>
                <InputButton label={ 'Datum' } value={ moment(date).format().slice(0, 10) }
                  onPress={ () => this.onOpenTimePicker('date') } iconName={ 'calendar-o' }
                />
                <View style={ styles.divider }/>
                <InputButton label={ 'Start' } value={ moment(start).format().slice(11, 16) }
                  onPress={ () => this.onOpenTimePicker('start') } iconName={ 'sort' }
                />
                <View style={ styles.divider }/>
                <InputButton label={ 'Slut' } value={ moment(end).format().slice(11, 16) }
                  onPress={ () => this.onOpenTimePicker('end') } iconName={ 'sort' }
                />

                {breakTime === 0 &&
                <View>
                  <View style={ styles.divider }/>
                  <View style={ styles.listEmpty }>
                    <View>
                      <TextButton iconName={ 'plus-circle' } label={ 'Lägg till rast' }
                        labelColor={ Colors.primaryBackground } onPress={ this.onOpenBreakPicker }
                      />
                    </View>
                  </View>
                </View>
                }

                {breakTime > 0 &&
                <View>
                  <View style={ styles.divider }/>
                  <InputButton
                    label={ 'Rast' }
                    value={ getDuriationHumanReadable(breakTime) }
                    onPress={ () => this.onOpenBreakPicker() }
                    iconName={ 'sort' }
                    cancelText={ 'Ta bort' }
                    onCancel={ () => this.onRemoveBreak() }
                  />
                  <View style={ styles.dividerSmall }/>
                </View>
                }

                <Text>
                  Arbetad tid:&nbsp;
                  {workedTimeReadable}
                </Text>

                <View style={ styles.divider }/>
                <Text style={ styles.label }>
                  Km-ersättning och utlägg
                </Text>
                
                { expenses.length > 0 &&
                <View style={ expenses.length < 1 ? styles.listEmpty : styles.listOccupied }>
                  <AttachmentList items={ expenses } onDelete={ this.onDeleteExpense }/>
                </View>
                }
                  
                { expenseInputVisible ?
                  <ExpenseInput
                    cost={ expenseCost }
                    description={ expenseDescription }
                    costType={ expenseType }
                    onChangeType={ this.onChangeExpenseType }
                    onChangeCost={ this.onChangeExpenseCost }
                    onChangeDescription={ this.onChangeExpenseDescription }
                    onClose={ this.onCloseExchangeInput }
                    onSave={ this.onSaveExpense }
                    costTypes={ sortedCostTypesList }
                  /> :
                  <View style={ styles.listEmpty }>
                    <View>
                      <TextButton iconName={ 'plus-circle' } label={ 'Lägg till nytt utlägg' }
                        labelColor={ Colors.primaryBackground } onPress={ this.onOpenExchangeInput }
                      />
                    </View>
                  </View>
                }
                

                <Text style={ styles.label }>
                  Bifoga fil(er)
                </Text>
                <View style={ attachments.length < 1 ? styles.listEmpty : styles.listOccupied }>
                  { attachments.length > 0 &&
                    <AttachmentList items={ attachments } onDelete={ this.onDeleteAttachment }/>
                  }
                  <View style={ attachments.length > 0 && styles.listButton }>
                    <TextButton iconName={ 'plus-circle' } label={ 'Lägg till fil/ta bild' }
                      labelColor={ Colors.primaryBackground } onPress={ this.onOpenAttachmentModal }
                    />
                  </View>
                </View>
                {/* <View style={ styles.newDayButton }>
                  <TextButton iconName={ 'plus-circle' } label={ 'Lägg till dag' } labelColor={ Colors.primaryBackground } onPress={ this.onNewDay }/>
                </View> */}
              </View>
              <View style={ styles.endContainer }>
                <PrimaryButton title={ 'Spara uppgifter' } onPress={ () => this.saveTimeReportLine() }/>
                <SecondaryButton style={ { marginTop: 24 } } title={ 'Avbryt' }
                  onPress={ () => navigation.goBack() }
                />
              </View>
          
            </ScrollView>

            <AttachmentPicker visible={ attachmentModalVisible } onClose={ this.onCloseAttachmentModal }
              selectItem={ this.selectAttachmentItem }
            />

            <Modal
              title={ timePickerModalTitle }
              visible={ timePickerOpenWeb }
              onClose={ this.onCloseTimePickerWeb }
              onSubmit={ this.onCloseTimePickerWeb }
              >
              <View style={ { paddingBottom: 32, paddingHorizontal: 32 } }>
                {
                  webInputType === 'date' &&
                  <WebDatePicker
                    value={ moment(date).format().slice(0, 10)}
                    maximumDate={ moment().toDate() }
                    onChange={this.onWebChangeDate}
                    />
                }
                {
                  (webInputType === 'start' || webInputType === 'end') &&
                  <>
                    <Text style={ { marginBottom: 24, fontSize: 18 } }>
                      Nedan kan du välja timme samt minuter. Minuterna blir automatiskt närmaste hel eller halvtimme oavsett vad du fyller i. Du kan antingen klicka på klock-ikonen och välja från en rullista eller klicka på timmarna och sedan skriva timmar och minuter med tangentbordet
                    </Text>
                    <input
                      style={ { fontSize: 32, maxWidth: 280 } }
                      onChange={this.onChangeWebTimeStart}
                      value={webTime}
                      type={'time'}
                      step={900}
                      required
                    />
                  </>
                }
              </View>
            </Modal>
            <Modal
              title={ 'Välj rasttid' }
              visible={ breakPickerOpen }
              onClose={ this.onCloseBreakPicker }
              onSubmit={ this.onCloseBreakPicker }
            >
              <View style={{ paddingHorizontal: 24, marginBottom: 16 }}>
                <Picker selectedValue={ breakTime } onValueChange={ (itemValue) => this.setState({ breakTime: itemValue }) }>
                  { breakValues.map(value => (
                    <Picker.Item label={ value.label } value={ value.value }
                      key={ value.value }
                    />
                  ))
                  }
                </Picker>
              </View>
            </Modal>
          </KeyboardViewContainer>
          { loading && <View style={ styles.inScreenLoader }>
            <ActivityIndicator size={ 'large' } color={ Colors.bodyText } />
          </View> }
        </View>
      );
    } else {
      return (
        <View style={ styles.loadingPage }>
          <Loader />
        </View>
      );
    }
  } 

}

const mapStateToProps = (state, ownProps: Props) => {
  const jobId = ownProps.route.params?.jobId;
  const job = state.jobs.myJobs.find((myJob: JobItem) => myJob.id === jobId);

  return {
    costTypes: state.timeReports.costTypes,
    job,
  };
};

export default connect(mapStateToProps, null)(ReportTimeScreen);

const styles = StyleSheet.create({
  loadingPage: {
    flex: 1,
    backgroundColor: Colors.appBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: Colors.appBackground,
    flex: 1,
  },
  contentContainer: {
    backgroundColor: Colors.veryWhite,
    padding: 24,
    paddingBottom: 32,
  },
  headerText: {
    fontSize: 20,
    fontFamily: Fonts.emphasis,
    marginBottom: 40,
  },
  divider: {
    height: 24,
  },
  dividerSmall: {
    height: 12,
  },
  label: {
    fontFamily: Fonts.medium,
    marginBottom: 12,
  },
  listEmpty: {
    marginBottom: 24,
  },
  listOccupied: {
    borderWidth: 1,
    borderColor: Colors.border,
    marginBottom: 24,
  },
  listButton: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  endContainer: {
    paddingVertical: 48,
    paddingHorizontal: 24,
  },
  buttonIcon: {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: Colors.primaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonIconText: {
    textAlign: 'center',
    textAlignVertical: 'center',
    color: Colors.veryWhite,
    margin: 0,
  },
  attachmentSelectList: {
  },
  attachmentSelect: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  attachmentSelectText: {
    fontFamily: Fonts.emphasis,
  },
  inScreenLoader: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.overlay,
  },
  newDayButton: {
    marginTop: 12,
    marginHorizontal: -12,
    padding: 12,
    borderWidth: 1,
    borderColor: Colors.border,
  },
});