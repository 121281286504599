import React from 'react';
import { View, StyleSheet } from 'react-native';

import Text from './Text';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

export default ({ title }) => (
  <View style={styles.container}>
    <Text style={styles.text}>
      {title.toUpperCase()}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingTop: 18,
    paddingBottom: 12,
    backgroundColor: Colors.appBackground,
  },
  text: {
    fontFamily: Fonts.emphasis,
    fontSize: 13,
    letterSpacing: 1,
  },
});

