export enum UserInterestStatus {
  'APPLIED' = 1000,
  'APPROVED' = 2000,
  'DONE' = 3000,
  'DENIED' = 4000,
  'DELETED' = 5000,
}

export enum WorkorderLineStatus {
  'REGISTERED' = 1000,
  'HAS_APPLICATIONS' = 2000,
  'ASSIGNED' = 3000,
  'DONE' = 4000,
  'DELETED' = 5000,
}

export enum WorkorderStatus {
  'COMPLETED' = 7000,
}
