import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Text from './Text';
import * as Icon from '@expo/vector-icons';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

type Props = {
  label: string;
  onPress: () => void;
  iconName?: string;
  iconSize?: number;
  labelColor?: string;
}

export default ({ label, iconName, iconSize, labelColor, onPress }: Props) => {
  return (
    <TouchableOpacity onPress={ onPress }>
      <View style={ styles.container }>
        <Text style={ [styles.label, { color: labelColor }] }>{ label }</Text>
        { !!iconName && <Icon.FontAwesome
          name={ iconName }
          size={ iconSize || 22 }
          color={ Colors.primaryBackground }
          style={ styles.icon }
        /> }
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  label: {
    fontFamily: Fonts.emphasis,
  },
  icon: {
    marginLeft: 16,
  },
})