import React from 'react';

import JobProfileTagGroupContainer from '../containers/JobProfileTagGroupContainer';
import ScreenView from '../components/ScreenView';

export default class JobProfileTagGroupScreen extends React.Component {
  render() {
    const id = this.props.route.params?.id ?? '';

    return (
      <ScreenView>
        <JobProfileTagGroupContainer navigation={this.props.navigation} id={id} />
      </ScreenView>
    );
  }
}
