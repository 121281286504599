import React from 'react';
import { View } from 'react-native';

import ScrollView from '../components/ScrollView';
import JobContainer from '../containers/JobContainer';

export default class JobScreen extends React.Component {
  render() {
    const id = this.props.route.params?.id ?? '';

    return (
      <ScrollView>
        <JobContainer id={id} navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}
