import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
} from '../constants/ActionTypes';
import { GET, POST, DELETE } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const fetchTagsRequest = {
  type: FETCH_TAGS_REQUEST,
};

const fetchTagsSuccess = (items, itemGroups, selectedItems) => {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: {
      items,
      itemGroups,
      selectedItems,
      fetchedAt: Date.now(),
    },
  };
}

const fetchTagsFailure = {
  type: FETCH_TAGS_FAILURE,
};

const fetchTags = () => async dispatch => {
  dispatch(fetchTagsRequest);

  try {
    const fetchTags = GET('/tags');
    const fetchTagGroups = GET('/tags/groups');
    const fetchSelectedTags = GET('/employee/me/tags');

    const tagsRes = await fetchTags;
    const tagGroupsRes = await fetchTagGroups;
    const selectedTagsRes = await fetchSelectedTags;

    if (!tagsRes.ok) throw tagsRes.json;
    if (!tagGroupsRes.ok) throw tagGroupsRes.json;
    if (!selectedTagsRes.ok) throw selectedTagsRes.json;

    dispatch(fetchTagsSuccess(tagsRes.json.result, tagGroupsRes.json.result, selectedTagsRes.json.result));
  } catch (res) {
    dispatch(fetchTagsFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchTags = state => {
  const tags = state.tags;

  if (!tags) {
    return true;
  } else if (tags.fetching) {
    return false;
  } else {
    return !tags.fetchedAt;
  }
}

export const fetchTagsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchTags(getState())) {
    return dispatch(fetchTags());
  }
}

export const addTag = ({ id }) => {
  return {
    type: ADD_TAG,
    payload: {
      id,
    },
  };
}

export const removeTag = ({ id }) => {
  return {
    type: REMOVE_TAG,
    payload: {
      id,
    },
  };
}

const updateTagRequest = {
  type: UPDATE_TAG_REQUEST,
};

const updateTagSuccess = {
  type: UPDATE_TAG_SUCCESS,
};

const updateTagFailure = {
  type: UPDATE_TAG_FAILURE,
};

export const updateTag = ({ id, name }, value) => async dispatch => {
  const tag = {
    id,
    name,
  };

  dispatch(updateTagRequest);

  try {
    let res;

    if (value) {
      res = await POST('/employee/me/tags', tag);
    } else {
      res = await DELETE('/employee/me/tags', tag);
    }

    if (!res.ok) throw res;

    dispatch(updateTagSuccess);
  } catch (res) {
    dispatch(updateTagFailure);

    if (value) {
      dispatch(removeTag(tag));
    } else {
      dispatch(addTag(tag));
    }

    reportRequestError(res);
  }
};
