export default {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  A1: 'A1',
  BE: 'BE',
  CE: 'CE',
  DE: 'DE',
  AM: 'AM',
};
