import React from 'react';
import { connect } from 'react-redux';

import ScreenView from '../components/ScreenView';
import RegisterContainer from '../containers/RegisterContainer';
import NavigationHeaderStyle from '../constants/NavigationHeaderStyle';

class RegisterScreen extends React.Component {
  static navigationOptions = {
    title: 'Ansök',
    ...NavigationHeaderStyle,
  };

  render() {
    return (
      <ScreenView>
        <RegisterContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}

export default connect()(RegisterScreen);
