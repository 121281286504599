import { logIn } from './auth';
import { POST } from '../services/Api';
import {
  FETCH_MANUAL_LOGIN_REQUEST,
  FETCH_MANUAL_LOGIN_SUCCESS,
  FETCH_MANUAL_LOGIN_FAILURE,
  RESET_MANUAL_LOGIN_FAILURE,
} from '../constants/ActionTypes';
import Constants from 'expo-constants';

const fetchUsernamePasswordToken = async ({ email, password }) => {
  const clientId = Constants.expoConfig.extra.CLIENT_ID;
  const clientSecret = Constants.expoConfig.extra.CLIENT_SECRET;

  try {
    const res = await POST('/oauth/token', {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password',
      username: email,
      password: password,
    }, true);
    if (!res.ok) return {};

    return res.json;
  } catch (res) {
    return {};
  }
};

const fetchManualTokenRequest = {
  type: FETCH_MANUAL_LOGIN_REQUEST,
};
const fetchManualTokenSuccess = {
  type: FETCH_MANUAL_LOGIN_SUCCESS,
};
const fetchManualTokenFailure = {
  type: FETCH_MANUAL_LOGIN_FAILURE,
};

const resetManualTokenFailure = {
  type: RESET_MANUAL_LOGIN_FAILURE,
};


export const doManualLogin = ({ email, password}) => async dispatch => {
  dispatch(fetchManualTokenRequest);
  const res = await fetchUsernamePasswordToken({ email, password });
  if(res && res.access_token) {
    dispatch(fetchManualTokenSuccess);
    dispatch(logIn({
      accessToken: res.access_token,
      accessTokenExpiresAt: res.access_token_expires_at,
    }));
  } else {
    dispatch(fetchManualTokenFailure);
  }
};

export const hideManualLoginError = () => dispatch => {
  dispatch(resetManualTokenFailure);
}