import {
  FETCH_COST_TYPES_REQUEST,
  FETCH_COST_TYPES_SUCCESS,
  FETCH_COST_TYPES_FAILURE,
} from "../constants/ActionTypes";
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const fetchCostTypesRequest = {
  type: FETCH_COST_TYPES_REQUEST,
};

const fetchCostTypesSuccess = (categories) => {
  return {
    type: FETCH_COST_TYPES_SUCCESS,
    payload: categories,
  };
}

const fetchCostTypesFailure = {
  type: FETCH_COST_TYPES_FAILURE,
};

export const fetchCostTypes = () => async dispatch => {
  dispatch(fetchCostTypesRequest);

  try {
    const costTypesRes = await GET('/timereport/costtypes');

    dispatch(fetchCostTypesSuccess(costTypesRes.json.result));
  } catch (error) {
    dispatch(fetchCostTypesFailure);
    reportRequestError(error, { showAlert: false });
  }
}