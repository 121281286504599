import React from 'react';
import { connect } from 'react-redux';

import TabBarIcon from '../components/TabBarIcon';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';

class TabBarContainer extends React.Component {
  render() {
    const { notificationCountByType } = this.props;
    const count = notificationCountByType(this.props.notificationResourceType);

    return (
      <TabBarIcon
        {...this.props}
        badge={count > 0}
        badgeCount={count}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  const { notifications, jobs } = state;

  return {
    notificationCountByType: type => notifications.items.filter(i => {
      if (i.data.resource_type === NotificationResourceTypes.Workorder && i.data.resource_type === type) {
        return jobs.myJobs.find(job => job.id === i.data.resource_id)
      } else if (i.data.resource_type === type) {
        return true
      } else {
        return false
      }
    }).length,
  };
};

export default connect(mapState)(TabBarContainer);
