import {
  SAVE_FILTERS,
} from '../constants/ActionTypes';
import SearchParameters from '../constants/SearchParameters';

export default (state = {
  service: [],
  office: SearchParameters.MY_DISTRICT,
  selectedOffices: [],
  scoped: false,
}, action) => {
  switch (action.type) {
    case SAVE_FILTERS:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default:
      return state;
  }
};
