import appReducer from './app';
import { ROOT_RESET } from '../constants/ActionTypes';

export default (state, action) => {
  if (action.type === ROOT_RESET) {
    state = undefined;
  }

  return appReducer(state, action);
};
