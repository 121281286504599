import React, { ReactElement } from 'react';
import {
  Modal as RNModal,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';
import Text from './Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import * as Icon from '@expo/vector-icons';

type Props = {
  visible: boolean;
  disableClose?: boolean;
  title: string;
  secondaryTitle?: string;
  secondaryTitleStyles?: StyleProp<TextStyle>;
  contentContainerStyles?: StyleProp<ViewStyle>;
  children: ReactElement
  onClose?: () => void;
  onSubmit?: () => void;
}

export default (props: Props) => {
  const {
    visible,
    title,
    children,
    onClose,
    onSubmit,
    disableClose,
    secondaryTitle,
    secondaryTitleStyles,
    contentContainerStyles,
  } = props;
  return (
    <RNModal
      visible={ visible }
      transparent={ true }
      animationType={ 'fade' }
    >
      <View style={ styles.container }>
        <TouchableWithoutFeedback disabled={ disableClose } onPress={ onClose }>
          <View style={ styles.overlay }>
          </View>
        </TouchableWithoutFeedback>
        <View style={ styles.modal }>
          <View style={ [styles.header, onSubmit && { justifyContent: 'space-between' }] }>
            { !!onSubmit && !disableClose && <TouchableOpacity onPress={ onClose }>
              <Icon.Ionicons
                name={ 'md-close' }
                size={ 32 }
                color={ Colors.veryWhite }
              />
            </TouchableOpacity> }
            { (!!onSubmit || !disableClose) && <TouchableOpacity onPress={ onSubmit || onClose }>
              {
                !onSubmit ?
                  <Icon.Ionicons
                    name={ 'md-close' }
                    size={ 32 }
                    color={ Colors.veryWhite }
                  /> :
                  <Text style={ styles.headerText }>
                    Använd
                  </Text>
              }
            </TouchableOpacity> }
          </View>
          <View style={ styles.titleContainer }>
            <Text style={ styles.titleText }>
              {title}
            </Text>
            <Text style={ [styles.secondaryText, secondaryTitleStyles] }>
              {secondaryTitle}
            </Text>
          </View>
          <View style={ [styles.content, contentContainerStyles] }>
            {children}
          </View>
          <View style={ styles.footer }/>
        </View>
      </View>
    </RNModal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.overlay,
    flex: 1,
    justifyContent: 'flex-end',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  modal: {
    backgroundColor: Colors.veryWhite,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
  },
  header: {
    height: 65,
    backgroundColor: Colors.headerBackground,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  headerText: {
    color: Colors.primaryText,
    fontSize: 20,
    fontFamily: Fonts.emphasis,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 32,
  },
  titleText: {
    fontSize: 24,
    fontFamily: Fonts.emphasis,
  },
  secondaryText: {
    fontSize: 14,
    fontFamily: Fonts.emphasis,
  },
  content: {
    maxHeight: 330,
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  footer: {
    height: 60,
  },
  message: {
    fontSize: 14,
    color: Colors.secondaryText,
  },
});
