import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import Text from './Text';
import * as Icon from '@expo/vector-icons';
import Colors from '../constants/Colors';
import { TimeReportLine, Navigation, CostType } from '../constants/types';
import AttachmentList from './AttachmentList';
import Fonts from '../constants/Fonts';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDuriationHumanReadable } from '../helpers/time';

type ComponentProps = {
  lines: TimeReportLine[];
  navigation: Navigation;
  jobId: number;
  timeReportId: number;
  onDelete: (id: number) => void;
  closed?: boolean;
}

type ConnectProps = {
  costTypes: CostType[];
}

type Props = ComponentProps & ConnectProps;

const DeviceWidth = Dimensions.get('window').width;

const TimeReportLines: React.FC<Props> = ({ lines, navigation, jobId, timeReportId, onDelete, closed, costTypes }) => {
  const [rowOpen, openRow] = useState<null | number>(null);

  const onOpenRow = (id: number) => {
    if (rowOpen === id) openRow(null);
    else openRow(id);
  };

  const changeCurrentLine = (id: number, workorderLineId: number) => {
    navigation.navigate('ReportTime', { jobId, timeReportId, lineId: id, workorderLineId });
  };

  const fixedLines = lines.map((i) => {
    const workedTime = moment.duration(moment(i.day + 'T' + i.end_time).diff(moment(i.day + 'T' + i.start_time)));
    const minusBreak = workedTime.clone().subtract(i.breaktime, 'minutes');
    const workedTimeReadable = getDuriationHumanReadable(minusBreak.as('minutes'));
    return {
      ...i,
      total_time: workedTimeReadable,
    };
  });

  return (
    <View style={styles.container}>
      {
        fixedLines.sort((a, b) => Date.parse(a.day) - Date.parse(b.day)).map(line =>
          <TouchableOpacity key={line.id} onPress={() => onOpenRow(line.id)}>
            <View style={styles.row}>
              <View style={styles.rowHeader}>
                <View style={[styles.rowHeaderTextContainer, DeviceWidth < 350 && styles.columnSmallDevice]}>
                  <Text style={styles.rowHeaderText}>
                    {line.day}
                  </Text>
                  <Text style={styles.rowHeaderText}>
                    {`${line.start_time.slice(0, 5)}-${line.end_time.slice(0, 5)}`}
                  </Text>
                  <Text style={styles.rowHeaderText}>
                    {line.total_time}
                  </Text>
                </View>
                <Icon.Ionicons
                  name={rowOpen === line.id ? 'ios-arrow-up' : 'ios-arrow-down'}
                  size={24}
                  style={styles.arrow}
                />
              </View>
              {rowOpen === line.id &&
                <View style={styles.rowContent}>
                  {line.breaktime > 0 &&
                    <Text style={styles.label}>
                      Rast:
                      {' '}
                      {getDuriationHumanReadable(line.breaktime)}
                    </Text>
                  }

                  <Text style={styles.label}>
                    Bifogade filer
                  </Text>
                  {line.timereport_line_files.length > 0 ?
                    <View style={styles.attachmentList}>
                      <AttachmentList items={line.timereport_line_files} />
                    </View> :
                    <View style={styles.emptyAttachmentLabel}>
                      <Text style={[styles.emptyLabel]}>
                        Inga bifogade filer
                      </Text>
                    </View>
                  }
                  {!closed && <View style={[styles.bottomButtonContainer, DeviceWidth < 350 && { flexDirection: 'column' }]}>
                    <TouchableOpacity onPress={() => changeCurrentLine(line.id, line.workorder_line_id)}>
                      <Text style={styles.bottomButton}>
                        Ändra tid
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => onDelete(line.id)}>
                      <Text style={styles.bottomButton}>
                        Radera tid
                      </Text>
                    </TouchableOpacity>
                  </View>}
                </View>
              }
            </View>
          </TouchableOpacity>
        )
      }
    </View>
  );
};

const mapStateToProps = (state): { costTypes: CostType[] } => {
  return {
    costTypes: state.timeReports.costTypes,
  };
};

export default connect<ConnectProps, null, ComponentProps>(mapStateToProps, null)(TimeReportLines);

const styles = StyleSheet.create({
  container: {

  },
  row: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
  },
  rowHeader: {
    paddingVertical: 24,
    flexDirection: 'row',
    flex: 1,
  },
  rowHeaderTextContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  columnSmallDevice: {
    flexDirection: 'column',
  },
  rowHeaderText: {
    flex: 1,
  },
  arrow: {
    marginLeft: 8,
  },
  rowContent: {
    borderWidth: 1,
    borderColor: Colors.border,
    backgroundColor: Colors.backgroundHighlight,
    paddingHorizontal: 12,
  },
  label: {
    paddingTop: 12,
    fontFamily: Fonts.medium,
  },
  expensesList: {
    borderTopWidth: 1,
    borderColor: Colors.border,
    marginTop: 16,
  },
  expenseRow: {
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderColor: Colors.border,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  attachmentList: {
    marginHorizontal: -12,
  },
  bottomButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 24,
  },
  bottomButton: {
    color: Colors.secondaryText,
    textDecorationLine: 'underline',

  },
  emptyLabel: {
    fontSize: 14,
    color: Colors.secondaryText,
  },
  emptyAttachmentLabel: {
    borderTopWidth: 1,
    borderColor: Colors.border,
    paddingTop: 8,
    marginVertical: 16,
  },
});