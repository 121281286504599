import {
  AUTH_CREATE,
  AUTH_DESTROY,
} from '../constants/ActionTypes';

export default (state = {
  accessToken: null,
  accessTokenExpiresAt: null,
}, action) => {
  switch (action.type) {
    case AUTH_CREATE:
      return Object.assign({}, state, {
        accessToken: action.payload.accessToken,
        accessTokenExpiresAt: action.payload.accessTokenExpiresAt,
      });
    case AUTH_DESTROY:
      return Object.assign({}, state, {
        accessToken: null,
        accessTokenExpiresAt: null,
      });
    default:
      return state;
  }
}
