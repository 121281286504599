import React from "react";
import ScreenView from "../components/ScreenView";
import InformationPagesContainer from "../containers/InformationPagesContainer";

export default class InformationPagesScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <InformationPagesContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
