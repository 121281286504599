import React from 'react';
import {
  StyleSheet,
  Text,
  StyleProp,
  TextStyle,
} from 'react-native';

import StandardTextStyle from '../constants/StandardTextStyle';

type Props = {
  style?: StyleProp<TextStyle>;
  children: string | number;
}

export default (props: Props) => {
  return (
    <Text
      { ...props }
      style={ [styles.container, props.style] }
      selectable={ true }
    >
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StandardTextStyle,
  },
});
