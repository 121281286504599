import React from 'react';
import { StyleSheet } from 'react-native';

import ScrollView from '../components/ScrollView';

export default class Modal extends React.Component {
  render() {
    const ViewComponent = this.props.route.params?.viewComponent ?? ScrollView;
    const ChildComponent = this.props.route.params?.childComponent;
    const childComponentProps = this.props.route.params?.childComponentProps ?? {};

    if (!ChildComponent) {
      console.error("No child component passed to modal");
      return null;
    }

    return (
      <ViewComponent style={styles.container}>
        <ChildComponent {...childComponentProps} navigation={this.props.navigation} />
      </ViewComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
