import React from 'react';

import PayslipsContainer from '../containers/PayslipsContainer';
import ScreenView from '../components/ScreenView';

export default class PayslipsScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <PayslipsContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
