import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

import Colors from '../constants/Colors';

export default (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <ActivityIndicator size="small" color={Colors.bodyText} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 250,
    backgroundColor: Colors.appBackground,
  }
});
