import React from 'react';
import { Image, View, StyleSheet } from 'react-native';

import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Text from './Text';
import HTMLView from './HTMLView';

export default ({ image, title, subtitle, text, html, children }) => {
  return (
    <View style={styles.container}>
      {image &&
        <Image
          style={styles.image}
          source={{ uri: image }}
        />
      }
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
        {text &&
          <Text>{text}</Text>
        }
        {html &&
          <HTMLView value={html} />
        }
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
  },
  image: {
    width: '100%',
    height: 160,
    resizeMode: 'cover',
  },
  content: {
    padding: 24,
    backgroundColor: '#fff',
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 18,
  },
  subtitle: {
    color: Colors.secondaryText,
    marginBottom: 16,
  },
});
