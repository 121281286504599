import React from 'react';

import LogInContainer from '../containers/LogInContainer';

export default class LogInScreen extends React.Component {
  render() {
    return (
      <LogInContainer navigation={this.props.navigation} />
    );
  }
}
