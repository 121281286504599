import React from 'react';
import ScreenView from '../components/ScreenView';
import InformationPage from '../containers/InformationPageContainer';

export default class InformationPageScreen extends React.Component {
  render() {
    const url = this.props.route.params?.url ?? '';
    const userData = this.props.route.params?.userData ?? {};
    return (
      <ScreenView>
        <InformationPage url={url} userData={userData} navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
