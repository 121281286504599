import {
  PUSH_NOTIFICATION_RECEIVED,
  PUSH_NOTIFICATION_SELECTED,
  PUSH_NOTIFICATION_HANDLED,
} from '../constants/ActionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case PUSH_NOTIFICATION_RECEIVED:
    case PUSH_NOTIFICATION_SELECTED:
      return [
        ...state,
        {
          id: action.payload.id,
          handled: false,
          data: action.payload.data
        }
      ];
    case PUSH_NOTIFICATION_HANDLED:
      return state.map(notification =>
        notification.id === action.payload.id ?
          { ...notification, handled: true } :
          notification
      );
    default:
      return state;
  }
}
