const tintColor = '#F29E2A';
const borderDark = '#D9DDDE';
const primaryBackground = '#50BF61';
const secondaryText = '#747E83';

export default {
  bodyText: '#1F2124',
  tintColor,
  tabIconDefault: '#aabac1',
  tabLabelDefault: secondaryText,
  tabIconSelected: tintColor,
  tabLabelSelected: tintColor,
  tabBar: '#fefefe',
  errorText: 'red',
  attentionColor: tintColor,
  successColor: primaryBackground,
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  primaryBackground,
  primaryText: '#fff',
  secondaryText,
  appBackground: '#F0F4F7',
  border: '#E1EAEB',
  borderDark,
  borderFocus: '#cccccc',
  headerBackground: '#008D4A',
  listItemSeparator: borderDark,
  overlay: '#55555555',
  veryWhite: '#fff',
  linkText: '#007AFF',
  iconInput: '#555555',
  backgroundHighlight: '#FAFAFA',
  bg: '#F9E3E2',
  rustRed: '#AB2E26',
};
