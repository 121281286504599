import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from './Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export default ({ text, style }) => (
  <View style={[styles.container, style]}>
    <Text style={styles.text}>
      {text}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.successColor,
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    color: '#fff',
  },
});
