import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  EDIT_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  updating: false,
  updateErrors: {},
  data: {},
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_USER_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        data: action.payload.data,
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case EDIT_USER:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          ...action.payload.changeset,
        },
      });
    case UPDATE_USER_REQUEST:
      return Object.assign({}, state, {
        updating: true,
        updateErrors: {},
      });
    case UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        updateErrors: {},
        data: action.payload.data,
      });
    case UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        updateErrors: { ...action.payload.errors },
      });
    default:
      return state;
  }
}
