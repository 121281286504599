import React from 'react';
import { View } from 'react-native';

import Colors from '../constants/Colors';

export default () => (
  <View
    style={{
      height: 1,
      backgroundColor: Colors.listItemSeparator,
      marginHorizontal: 24,
      marginTop: -1,
    }}
  />
)
