import {
  FETCH_OFFICES_REQUEST,
  FETCH_OFFICES_SUCCESS,
  FETCH_OFFICES_FAILURE,
} from '../constants/ActionTypes';
import { GET } from '../services/Api';
import { minutesPassed } from '../helpers/time';
import { reportRequestError } from '../helpers/request-errors';

const fetchOfficesRequest = {
  type: FETCH_OFFICES_REQUEST,
};

const fetchOfficesSuccess = items => {
  return {
    type: FETCH_OFFICES_SUCCESS,
    payload: {
      items,
      fetchedAt: Date.now(),
    },
  };
}

const fetchOfficesFailure = {
  type: FETCH_OFFICES_FAILURE,
};

const fetchOffices = () => async dispatch => {
  dispatch(fetchOfficesRequest);

  try {
    const res = await GET('/offices/me');
    if (!res.ok) throw res;

    dispatch(fetchOfficesSuccess(res.json.result));
  } catch (res) {
    dispatch(fetchOfficesFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchOffices = state => {
  const offices = state.offices;

  if (!offices) {
    return true;
  } else if (offices.fetching) {
    return false;
  } else if (!offices.fetchedAt) {
    return true;
  } else if (offices.fetchError) {
    return true;
  } else {
    return minutesPassed(15, offices.fetchedAt);
  }
}

export const fetchOfficesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchOffices(getState())) {
    return dispatch(fetchOffices());
  }
}
