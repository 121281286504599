import React from 'react';
import { FlatList, StyleSheet, Switch, View } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';

import NotificationSettingTypes from '../constants/NotificationSettingTypes';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import ListItemSeparator from '../components/ListItemSeparator';
import Text from '../components/Text';
import withLoader from '../components/withLoader';
import {
  fetchPushSettingsIfNeeded,
  editNotificationSetting,
  updateNotificationSetting
} from '../actions/push-settings';
import withFetchError from '../components/withFetchError';
import withFetch from '../components/withFetch';
import { selection as hapticSelection } from '../helpers/haptic';

const typeToTitle = (type) => {
  switch (type) {
    case NotificationSettingTypes.Messages:
      return 'Nytt meddelande';
    case NotificationSettingTypes.Jobs:
      return 'Nytt uppdrag';
    case NotificationSettingTypes.Salaries:
      return 'Ny lönespecifikation';
    case NotificationSettingTypes.Workorders:
      return 'Nytt tilldelat uppdrag';
    default:
      return '';
  }
};

class NotificationsContainer extends React.Component {
  render() {
    const { settings } = this.props;
    const listData = settings.map(s => ({ type: s.type, title: typeToTitle(s.type), value: !!s.enabled }));

    return (
      <FlatList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => `${item.type}`}
        data={listData}
        renderItem={this._renderItem}
        renderSectionHeader={this._renderSectionHeader}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderItem = ({ item, index, section }) => {
    return (
      <View style={styles.listItem}>
        <View style={styles.listItemContent}>
          <Text style={styles.listItemContentText}>
            {item.title}
          </Text>
        </View>
        <View style={styles.listItemContentRight}>
          <Switch
            value={item.value}
            trackColor={{ true: Colors.primaryBackground, false: null }}
            onValueChange={this._onChange(item.type)}
          />
        </View>
      </View>
    );
  }

  _onChange = type => value => {
    const { editNotificationSetting, updateNotificationSetting } = this.props;

    hapticSelection();
    editNotificationSetting(type, value ? 1 : 0);
    updateNotificationSetting(type);
  }
}

const styles = StyleSheet.create({
  listItem: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemContent: {
    flex: 1,
  },
  listItemContentText: {
    fontFamily: Fonts.medium,
  },
  listItemContentRight: {
    alignItems: 'flex-end',
  },
});

const mapState = state => {
  const { pushSettings } = state;

  return {
    loading: pushSettings.fetching,
    fetchError: pushSettings.fetchError,
    settings: pushSettings.items,
  };
};

const mapDispatch = dispatch => {
  return {
    fetch: () => dispatch(fetchPushSettingsIfNeeded()),
    editNotificationSetting: (type, value) => dispatch(editNotificationSetting(type, value)),
    updateNotificationSetting: (type) => dispatch(updateNotificationSetting(type)),
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withLoader,
  withFetchError,
  withFetch
)(NotificationsContainer)
