import {
  FETCH_PUSH_SETTINGS_REQUEST,
  FETCH_PUSH_SETTINGS_SUCCESS,
  FETCH_PUSH_SETTINGS_FAILURE,
  EDIT_PUSH_SETTING,
  UPDATE_PUSH_SETTING_REQUEST,
  UPDATE_PUSH_SETTING_SUCCESS,
  UPDATE_PUSH_SETTING_FAILURE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  updating: false,
  items: [],
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_PUSH_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_PUSH_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        items: action.payload.items,
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_PUSH_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case EDIT_PUSH_SETTING:
      return Object.assign({}, state, {
        items: state.items.map(setting =>
          setting.type === action.payload.type ?
            { ...setting, enabled: action.payload.value } :
            setting
        )
      });
    case UPDATE_PUSH_SETTING_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      });
    case UPDATE_PUSH_SETTING_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
      });
    case UPDATE_PUSH_SETTING_FAILURE:
      return Object.assign({}, state, {
        updating: false,
      });
    default:
      return state;
  }
}
