import React from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';

import Colors from '../constants/Colors';

export default (props) => {
  return (
    <View {...props} style={[styles.container, props.style]}>
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.appBackground,
  },
});
