import React from 'react';
import { StyleSheet, View } from 'react-native';
import * as Icon from '@expo/vector-icons';

import Text from './Text';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export default ({ text, style }) => (
  <View style={[styles.container, style]}>
    <Text style={styles.text}>
        <Icon.FontAwesome
        name='exclamation-triangle'
        size={20}
        style={styles.icon}
        /> {!!text ? text : 'Saknar anslutning till Internet'}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.tintColor,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 4,
  },
  text: {
    color: '#fff',
    fontFamily: Fonts.medium,
    textAlign: 'center',
  },
  icon: {
    color: '#fff',
  },
});
