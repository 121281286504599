import {
  CONNECTION_CHANGE,
} from '../constants/ActionTypes';

export const connectionChange = isConnected => {
  return {
    type: CONNECTION_CHANGE,
    payload: { isConnected }
  };
}
