import React from 'react';
import { View, StyleSheet } from 'react-native';

import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Text from './Text';
import moment from 'moment';

type Props = {
  title: string;
  subtitle: string;
  workorderId: string;
  createdAt: Date;
  applied: boolean;
  approved: boolean;
  note: string | null;
  clientAddress: string;
}

export default ({ title, subtitle, workorderId, createdAt, applied, note, clientAddress, approved }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.workorderId}>{ workorderId }</Text>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{`Kontor: ${subtitle}`}</Text>
        {!!clientAddress && <Text style={styles.address}>{`Kundadress: ${clientAddress}`}</Text>}
        {!!note &&
          <View>
            <Text style={styles.noteHeader}>Anteckningar:</Text>
            <Text style={styles.note}>{note}</Text>
          </View>
        }
        <Text style={ styles.createdAt }>{ moment(createdAt).fromNow().toUpperCase() }</Text>
      </View>
      { !!applied &&
        <View style={ styles.appliedTag }>
          <Text style={ styles.appliedTagText }>Ansökt</Text>
        </View>
      }
      { !!approved &&
        <View style={ styles.appliedTag }>
          <Text style={ styles.appliedTagText }>Tilldelad</Text>
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
  },
  workorderId: {
    fontFamily: Fonts.medium,
  },
  content: {
    padding: 24,
    backgroundColor: '#fff',
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
  },
  subtitle: {
    color: Colors.secondaryText,
  },
  address: {
    color: Colors.secondaryText,
  },
  description: {
    marginTop: 16,
  },
  noteHeader: {
    marginTop: 32,
    fontFamily: Fonts.bold,
  },
  note: {
  },
  createdAt: {
    fontFamily: Fonts.regular,
    fontSize: 12,
    color: Colors.secondaryText,
    marginTop: 32,
  },
  appliedTag: {
    position: 'absolute',
    right: 24,
    top: 24,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: Colors.tintColor,
  },
  appliedTagText: {
    fontFamily: Fonts.emphasis,
    fontSize: 12,
    color: Colors.veryWhite,
  },
});
