import {
  CONNECTION_CHANGE,
} from '../constants/ActionTypes';

export default (state = {
  isConnected: true,
}, action) => {
  switch (action.type) {
    case CONNECTION_CHANGE:
      return Object.assign({}, state, {
        isConnected: action.payload.isConnected,
      });
    default:
      return state;
  }
}
