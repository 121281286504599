import React from 'react';
import { View, StyleSheet } from 'react-native';

import ActionFeedback from './ActionFeedback';

export default ({ title, text } = {}) => WrappedComponent => {
  return class NoItems extends React.PureComponent {
    render() {
      const { noItems } = this.props;

      if (noItems) {
        return (
          <View style={styles.container}>
            <ActionFeedback
              title={title || "Inget innehåll"}
              text={text || "Vappen hittade inget innehåll att visa"}
            />
          </View>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
