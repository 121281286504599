import moment from 'moment';

export const minutesPassed = (minutes, then) => (Date.now() - then > 1000 * 60 * minutes);
export const monthString = month => moment(month, 'M').format('MMMM').replace(/\w/, c => c.toUpperCase());

/**
 * Converts minutes to human readable ie "1 hour 15min"
 */
export function getDuriationHumanReadable(minutes: number): string {
  const time = moment.utc(moment.duration(minutes, 'minutes').as('milliseconds')).toObject();
  let output = '';
  if (time.hours > 0) {
    output += `${ time.hours } tim `;
  }
  if (time.minutes > 0) {
    output += `${ time.minutes } min`;
  }
  if (time.hours === 0 && time.minutes === 0) {
    output += '0 tim';
  }
  return output;
}
