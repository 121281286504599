import React from 'react';

import ScreenView from '../components/ScreenView';
import OfficesContainer from '../containers/OfficesContainer';

export default class OfficesScreen extends React.Component {
  render() {
    return (
      <ScreenView>
        <OfficesContainer navigation={this.props.navigation} />
      </ScreenView>
    );
  }
}
