import queryString from 'query-string';
import Constants from 'expo-constants';

let _token;

export const setToken = token => {
  _token = token;
}

export const GET = async (endpoint, data, useBaseUrl) => {
  let queryParams = '';

  if (data) {
    queryParams = `?${queryString.stringify(data)}`;
  }

  return request(`${endpoint}${queryParams}`, 'GET', null, false, useBaseUrl);
}

export const POST = async (endpoint, data, useBaseApiUrl = false, isFormData) => {
  return request(endpoint, 'POST', data, useBaseApiUrl, null, isFormData);
}

export const PUT = async (endpoint, data) => {
  return request(endpoint, 'PUT', data);
}

export const DELETE = async (endpoint, data) => {
  return request(endpoint, 'DELETE', data);
}

const request = async (endpoint, method = 'GET', data, useBaseApiUrl = false, useBaseUrl = false, isFormData = false) => {
  let baseUrl;
  const apiBaseUrl = Constants.expoConfig.extra.API_BASE_URL;
  const apiUrl = Constants.expoConfig.extra.API_URL;

  if (useBaseUrl) {
    baseUrl = '';
  } else if (useBaseApiUrl){
    baseUrl = apiBaseUrl;
  } else {
    baseUrl = apiUrl;
  }

  const url = `${baseUrl}${endpoint}`;
  const body = isFormData ? data : data ? JSON.stringify(data) : undefined;
  const options = {
    method,
    body,
    headers: {
      'Accept': 'application/json',
    },
  };

  if (!isFormData) {
    options.headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  if (_token) {
    options.headers['Authorization'] = `Bearer ${_token}`;
  }

  const response = await fetch(url, options);
  const json = await response.json();

  return {
    json,
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    _request: {
      url,
      method,
      body,
    },
  };
}
