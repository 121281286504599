import { Alert } from 'react-native';
import { Platform } from "react-native";

export default async ({ title, message, actionText, cancelText }) => {
  return new Promise((resolve, reject) => {
    if (Platform.OS === 'web') {
      const result = window.confirm(`${title}: ${message}`)
      resolve({ result })
    } else {
      Alert.alert(title, message,
        [
          {
            text: cancelText,
            style: 'cancel',
            onPress: () => {
              resolve({ result: false });
            }
          },
          {
            text: actionText,
            onPress: () => {
              resolve({ result: true });
            }
          },
        ]
      );
    }
  });
};
