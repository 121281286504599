import React from 'react';
import { RefreshControl, FlatList } from 'react-native';
import { withNavigationFocus } from '@react-navigation/compat';
import { useScrollToTop } from '@react-navigation/native';

import { connect } from 'react-redux';
import { compose } from 'redux';

import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import { fetchMessagesIfNeeded, refreshFetchMessages, fetchMoreMessages } from '../actions/messages';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import withNoItems from '../components/withNoItems';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import withFetch from '../components/withFetch';
import withNotificationClearing from '../components/withNotificationClearing';

const notificationType = NotificationResourceTypes.Message;

function MessagesContainer(props) {
  const ref = React.useRef(null);

  useScrollToTop(ref);
  const _onEndReached = () => {
    const { fetchMoreMessages, isFocused } = props;

    if (isFocused) {
      fetchMoreMessages();
    }
  }

  const _onRefresh = () => {
    const { refreshFetchMessages } = props;

    refreshFetchMessages();
  }

  const _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={item.title}
        subtitle={item.subtitle}
        image={item.image}
        date={item.date}
        attention={item.attention}
        onPress={_onPress(item.id, item.title)}
      />
    );
  }

  const _onPress = (id, title) => () => {
    props.navigation.push('Message', { id, title });
  }
  const { messages, notifications } = props;

  if (!messages.items.length) {
    return null;
  }

  const listData = messages.items.map(item => ({
    id: item.id,
    title: item.title ? item.title.rendered : '',
    subtitle: `Veteranpoolen ${!item.office ? 'Sverige' : item.office.join(', ')}`,
    image: item.thumbnail,
    date: item.date,
    attention: !!notifications.items.find(i => i.data.resource_type === notificationType && i.data.resource_id === item.id),
  }));

  return (
    <FlatList
      ref={ref}
      contentContainerStyle={{ paddingBottom: 24 }}
      keyExtractor={item => `${item.id}`}
      data={listData}
      renderItem={_renderItem}
      ItemSeparatorComponent={ListItemSeparator}
      onEndReached={_onEndReached}
      onEndReachedThreshold={0.75}
      refreshControl={
        <RefreshControl
          refreshing={messages.refreshing}
          onRefresh={_onRefresh}
        />
      }
    />
  );
}

const mapState = state => {
  const { messages, notifications } = state;

  return {
    loading: messages.items.length === 0 && messages.fetching && !messages.refreshing,
    noItems: messages.items.length === 0 && !messages.fetching,
    fetchError: messages.fetchError,
    messages,
    notifications,
  };
};

const mapDispatch = dispatch => {
  return {
    fetchMoreMessages: () => dispatch(fetchMoreMessages()),
    refreshFetchMessages: () => dispatch(refreshFetchMessages()),
    fetch: () => dispatch(fetchMessagesIfNeeded()),
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withNavigationFocus,
  withNotificationClearing(notificationType),
  withNoItems({
    text: "Du har inte fått några meddelanden ännu"
  }),
  withLoader,
  withFetchError,
  withFetch
)(MessagesContainer);
