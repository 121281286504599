import React from 'react';
import { FlatList } from 'react-native';
import { connect } from 'react-redux';

import ListItemCheckbox from '../components/ListItemCheckbox';
import ListItemSeparator from '../components/ListItemSeparator';
import { addTag, removeTag, updateTag } from '../actions/tags';
import { groupBy } from '../helpers/array';

class JobProfileTagGroupContainer extends React.Component {
  render() {
    const { items, selectedItems } = this.props;

    const listData = items.map(item => ({
      id: item.id,
      name: item.name,
      selected: selectedItems.includes(item.id),
    }));

    return (
      <FlatList
        contentContainerStyle={{ paddingBottom: 24 }}
        keyExtractor={item => `${item.id}`}
        data={listData}
        renderItem={this._renderItem}
        ItemSeparatorComponent={ListItemSeparator}
      />
    );
  }

  _renderItem = ({ item, index, section }) => {
    const { userData } = this.props;

    return (
      <ListItemCheckbox
        label={item.name}
        checked={item.selected}
        onChange={this._onChange(item)}
      />
    );
  }

  _onChange = ({ id, name }) => ({ checked }) => {
    const { addTag, removeTag, updateTag } = this.props;
    const tag = {
      id,
      name,
    };

    if (checked) {
      addTag(tag);
    } else {
      removeTag(tag);
    }

    updateTag(tag, checked);
  }
}

const mapState = (state, ownProps) => {
  const { tags } = state;
  const items = groupBy(tags.items, 'taggroup_id')[ownProps.id] || [];

  return {
    tagGroup: tags.itemGroups.find(tg => tg.id === ownProps.id),
    items,
    selectedItems: tags.selectedItems,
  };
};

export default connect(mapState, {
  addTag,
  removeTag,
  updateTag,
})(JobProfileTagGroupContainer);
