import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { 
  StyleSheet, 
  ActivityIndicator, 
  View,
  TextInput,
  Text, 
} from 'react-native';
import Article from '../components/Article';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import withNoItems from '../components/withNoItems';
import { PrimaryButton } from '../components/Button';
import { updateMessageAttend } from '../actions/messages';
import SectionHeader from '../components/SectionHeader';

class MessageContainer extends React.Component {
  state = { 
    changeset: {} 
  };

  render() {
    const { message, updating } = this.props;
    const { changeset } = this.state;
    const value = changeset['employee_message'] !== undefined ? changeset['employee_message'] : message['employee_message'];
    
    return (
      <View>
        <Article
          image={message.image}
          title={message.title ? message.title.rendered : ''}
          subtitle={`Veteranpoolen ${!message.office ? 'Sverige' : message.office.join(', ')}`}
          html={message.content.rendered}
        />

        {message.hasEvent &&
          <>
            <SectionHeader title="Anmälan" />
            <View style={styles.container}>
              

              {!message.isAttendingEvent &&
                <>
                  <Text>Meddelande</Text>
                  <TextInput onChangeText={this._onChangeText('employee_message')} style={styles.textarea} editable maxLength={400} multiline numberOfLines={4} value={value}/>
                </>
              }

              <PrimaryButton
                title={updating ? 'Sparar...' : (!message.isAttendingEvent ? 'Jag anmäler mig' : 'Avanmäl mig')}
                onPress={this._submitAttend}
                icon={updating && <ActivityIndicator color="#fff" />}
              />
            </View>
          </>
        }
      </View>
    );
  }

  _onChangeText = prop => value => {
    this.setState({
      changeset: {
        ...this.state.changeset,
        [prop]: value,
      }
    });
  }

  _submitAttend = () => {
    const { changeset } = this.state;
    const { updateMessageAttend, message } = this.props;
    const attendingMessage = changeset['employee_message'] !== undefined ? changeset['employee_message'] : message['employee_message'];
    
    updateMessageAttend(message.id, !message.isAttendingEvent, attendingMessage );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: '#fff',
  },
  textarea: {
    color: '#000',
    borderColor: '#eee',
    paddingHorizontal: 10,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    borderWidth: 1,
    height: 65,
  }
});

const mapState = (state, ownProps) => {
  const { messages } = state;
  const message = messages.items.find(m => m.id === ownProps.id);

  return {
    loading: messages.fetching,
    updating: messages.updating,
    fetchError: messages.fetchError,
    noItems: !message && !messages.fetching,
    message,
  };
};

const mapDispatch = {
  updateMessageAttend
};

export default compose(
  connect(mapState, mapDispatch),
  withNoItems({ text: 'Vappen kunde inte hitta meddelandet' }),
  withLoader,
  withFetchError
)(MessageContainer);
