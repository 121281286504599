import React from 'react';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

export default (props) => {
  const { width } = useWindowDimensions();
  const { value } = props;
  const tagsStyles = {
    body: {
      fontSize: 16,
      fontFamily: Fonts.regular,
      lineHeight: 24,
      color: Colors.bodyText,
    },
    strong: {
      fontWeight: '400',
      fontFamily: Fonts.medium,
    },
    p: {
      marginBottom: 14,
    },
  };

  const source = {
    html: value,
  }

  return (
    <>
      <RenderHtml
        contentWidth={width}
        source={source}
        tagsStyles={tagsStyles}
      />
    </>
  );
}