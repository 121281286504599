import React from 'react';

import ScrollView from '../components/ScrollView';
import ContactDetailsContainer from '../containers/ContactDetailsContainer';

export default class ContactDetailsScreen extends React.Component {
  render() {
    return (
      <ScrollView>
        <ContactDetailsContainer navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}
