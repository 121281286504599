import {
  FETCH_PAYSLIPS_REQUEST,
  FETCH_PAYSLIPS_SUCCESS,
  FETCH_PAYSLIPS_FAILURE,
  FETCH_PAYSLIP_REQUEST,
  FETCH_PAYSLIP_SUCCESS,
  FETCH_PAYSLIP_FAILURE,
  REFRESH_PAYSLIPS,
  PAYSLIPS_CURRENT_PAGE,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  refreshing: false,
  pagination: {},
  items: [],
  itemsFetchedAt: {},
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_PAYSLIPS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_PAYSLIPS_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        refreshing: false,
        pagination: action.payload.pagination,
        items: action.payload.pagination.current_page === 1 ?
          [...action.payload.items] :
          [...state.items, ...action.payload.items],
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_PAYSLIPS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
        refreshing: false,
      });
    case FETCH_PAYSLIP_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
      });
    case FETCH_PAYSLIP_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        items: state.items.find(i => i.id === action.payload.item.id) ?
          state.items.map(i => (i.id === action.payload.item.id ? { ...action.payload.item } : i)) :
          [...state.items, { ...action.payload.item }],
        itemsFetchedAt: { ...state.itemsFetchedAt, [action.payload.item.id]: action.payload.fetchedAt },
      });
    case FETCH_PAYSLIP_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
      });
    case REFRESH_PAYSLIPS:
      return Object.assign({}, state, {
        refreshing: true,
      });
    case PAYSLIPS_CURRENT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          current_page: action.payload.current_page,
        },
      });
    default:
      return state;
  }
}
