import * as React from 'react';
import { WebView } from 'react-native-webview';
import queryString from 'query-string';
import { Platform } from 'react-native';

class InformationPageContainer extends React.Component {
  render() {
    const uri = this.getIframeUrl();
    return (
        Platform.OS === 'web' ?
        <iframe src={uri} height="100%" width="100%"/> :
        <WebView
          source={{ uri }}
        />
    );
  }

  getIframeUrl() {
    const { url, userData } = this.props;    
    const data = {
      iframe: true,
      employee_number: userData.number,
    };
    const queryParams = `?${queryString.stringify(data)}`;

    return `${url}${queryParams}`;
  }
}

export default InformationPageContainer;

