import {
  FETCH_PUSH_SETTINGS_REQUEST,
  FETCH_PUSH_SETTINGS_SUCCESS,
  FETCH_PUSH_SETTINGS_FAILURE,
  EDIT_PUSH_SETTING,
  UPDATE_PUSH_SETTING_REQUEST,
  UPDATE_PUSH_SETTING_SUCCESS,
  UPDATE_PUSH_SETTING_FAILURE,
} from '../constants/ActionTypes';
import { GET, PUT } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const fetchPushSettingsRequest = {
  type: FETCH_PUSH_SETTINGS_REQUEST,
};

const fetchPushSettingsSuccess = items => {
  return {
    type: FETCH_PUSH_SETTINGS_SUCCESS,
    payload: {
      items,
      fetchedAt: Date.now(),
    },
  };
}

const fetchPushSettingsFailure = {
  type: FETCH_PUSH_SETTINGS_FAILURE,
};

const fetchPushSettings = () => async dispatch => {
  dispatch(fetchPushSettingsRequest);

  try {
    const res = await GET('/employee/me/notificationsettings');
    if (!res.ok) throw res;

    dispatch(fetchPushSettingsSuccess(res.json.result));
  } catch (res) {
    dispatch(fetchPushSettingsFailure);
    reportRequestError(res, { showAlert: false });
  }
}

const shouldFetchPushSettings = state => {
  const pushSettings = state.pushSettings;

  if (!pushSettings) {
    return true;
  } else if (pushSettings.fetching) {
    return false;
  } else {
    return !pushSettings.fetchedAt;
  }
}

export const fetchPushSettingsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchPushSettings(getState())) {
    return dispatch(fetchPushSettings());
  }
}

export const editNotificationSetting = (type, value) => {
  return {
    type: EDIT_PUSH_SETTING,
    payload: {
      type,
      value,
    },
  };
}

const updateNotificationSettingRequest = {
  type: UPDATE_PUSH_SETTING_REQUEST,
};

const updatePushSettingSuccess = {
  type: UPDATE_PUSH_SETTING_SUCCESS,
};

const updateNotificationSettingFailure = {
  type: UPDATE_PUSH_SETTING_FAILURE,
};

export const updateNotificationSetting = type => async (dispatch, getState) => {
  const setting = getState().pushSettings.items.find(s => s.type === type);

  dispatch(updateNotificationSettingRequest);

  try {
    const res = await PUT(`/employee/me/notificationsettings/${setting.type}`, { enabled: setting.enabled });
    if (!res.ok) throw res;

    dispatch(updatePushSettingSuccess);
  } catch (res) {
    dispatch(updateNotificationSettingFailure);
    dispatch(editNotificationSetting(type, !setting.enabled));
    reportRequestError(res);
  }
}
